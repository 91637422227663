import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import check from '../../shared/img/check.svg';


class NuevoAlumnoExitoso extends React.PureComponent {
  render() {
    const { t } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div style={{ textAlign: 'center' }}>
                  <img alt="Registro exitoso" src={check} style={{ maxWidth: '15vw' }} />
                  <h1 style={{ margin: '20px 0px' }}>{t('¡Gracias!')}</h1>
                  <h3 style={{ margin: '20px 0px' }}>{t('Hemos registrado al nuevo alumno en el sistema exitosamente. Los padres han sido notificados.')}</h3>
                  <Link to="/matricula">
                    <Button color="primary" className="previous">{t('Volver')}</Button>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

NuevoAlumnoExitoso.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(NuevoAlumnoExitoso);
