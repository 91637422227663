/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  LOAD_CATALOGO_CARGO,
  LOAD_CATALOGO_CARGO_SUCCESS,
  LOAD_CATALOGO_CARGO_ERROR,
  LOAD_CARGO,
  LOAD_CARGO_SUCCESS,
  LOAD_CARGO_ERROR,
  LOAD_DATA_NUCLEO_CARGO,
  LOAD_DATA_NUCLEO_CARGO_SUCCESS,
  LOAD_DATA_NUCLEO_CARGO_ERROR,
  SET_CARGO_ATTRIBUTE,
  DESTROY_MATRICULAR_CARGO_FORM,
  PROCESAR_CARGO,
  PROCESAR_CARGO_ERROR,
  PROCESAR_CARGO_SUCCESS,
} from '../actions/cargoCrearActions';

const initialState = {
  dataLoaded: false,
  nucleo: {
    id: '',
    telefono: [],
    direccion: [],
    medico: [],
    encargado: [],
    alumno: [],
  },
  catalogo: {
    nivelEscolar: [],
    nacionalidad: [],
    sexo: [],
    tipoSangre: [],
    tipoTelefono: [],
    tipoIdentificacion: [],
    tipoDireccion: [],
    estadoCivil: [],
    rolEncargado: [],
    alergias: [],
    intolerancias: [],
    problemasGastrointestinales: [],
    tipoMedicamento: [],
    antecedentes: [],
    parentesco: [],
  },
  cargo: {
    usuario: '',
    nucleo: '',
    fecha: '',
    suscrito: '',
    identificacionSuscrito: '',
    firmaTarjetahabiente: '',
    habilitado: true,
    formulario: '',
    tarjeta: '',
    mesVencimiento: null,
    anioVencimiento: null,
    banco: '',
    nombreTarjeta: '',
  },
  alumno: [],
  error: false,
  cargoDirty: false,
  loading: false,
  matriculaExitosa: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_CATALOGO_CARGO:
      draft.loading = true;
      draft.dataLoaded = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_CARGO_SUCCESS:
      draft.catalogo = action.catalogo;
      draft.dataLoaded = true;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_CARGO_ERROR:
      draft.loading = false;
      draft.dataLoaded = false;
      draft.error = action.error;
      break;
    case LOAD_CARGO:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_CARGO_SUCCESS:
      draft.alumno = action.alumno;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_CARGO_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case LOAD_DATA_NUCLEO_CARGO:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_DATA_NUCLEO_CARGO_SUCCESS:
      draft.nucleo = action.nucleoData;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_DATA_NUCLEO_CARGO_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case PROCESAR_CARGO:
      draft.loading = true;
      draft.error = false;
      break;
    case PROCESAR_CARGO_SUCCESS:
      draft.matriculaExitosa = true;
      draft.loading = false;
      draft.error = false;
      break;
    case PROCESAR_CARGO_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case SET_CARGO_ATTRIBUTE:
      draft[action.data.attribute] = action.data.value;
      draft.cargoDirty = true;
      break;
    case DESTROY_MATRICULAR_CARGO_FORM:
      Object.keys(initialState).forEach(k => draft[k] = initialState[k]);
      draft.cargoDirty = false;
      break;
    default:
      return state;
  }
});
