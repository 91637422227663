/* eslint-disable react/no-array-index-key,react/no-typos */
import React from 'react';
import {
  Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Panel from '../../../shared/components/Panel';

const TablaMatricula = ({ t, estadisticas }) => (
  <Panel title={t('Cantidades por Programa')} lg={12}>
    <Table responsive className="table--bordered dashboard__table-crypto">
      <thead>
        <tr>
          <th>{t('Programa')}</th>
          <th>{t('Hombres')}</th>
          <th>{t('Mujeres')}</th>
          <th>{t('% Hombres Programa')}</th>
          <th>{t('% Mujeres Programa')}</th>
          <th>{t('Total')}</th>
          <th>{t('% del Total')}</th>
          <th>{t('Contratos Pendientes')}</th>
        </tr>
      </thead>
      <tbody>
        {estadisticas.programas.map((n, index) => (
          <tr key={index}>
            <td>{n.programa}</td>
            <td>{n.cantidadHombres}</td>
            <td>{n.cantidadMujeres}</td>
            <td>{n.cantidadHombresPorcentaje === 'NaN' ? '-' : `${n.cantidadHombresPorcentaje}%`}</td>
            <td>{n.cantidadMujeresPorcentaje === 'NaN' ? '-' : `${n.cantidadMujeresPorcentaje}%`}</td>
            <td className="bold-text">{n.cantidad}</td>
            <td className="bold-text">{n.cantidadPorcentaje}%</td>
            <td>{n.cantidadContratosPendientes}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Panel>
);

TablaMatricula.propTypes = {
  t: PropTypes.func.isRequired,
  estadisticas: PropTypes.shape({}).isRequired,
};

export default withTranslation('common')(TablaMatricula);
