/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  LOAD_CATALOGO_NUEVA_MATRICULA,
  LOAD_CATALOGO_NUEVA_MATRICULA_SUCCESS,
  LOAD_CATALOGO_NUEVA_MATRICULA_ERROR,
  DESTROY_NUEVA_MATRICULA_FORM,
  PROCESAR_NUEVA_MATRICULA,
  PROCESAR_NUEVA_MATRICULA_ERROR,
  PROCESAR_NUEVA_MATRICULA_SUCCESS,
} from '../actions/nuevaMatriculaActions';

const initialState = {
  dataLoaded: false,
  catalogo: {
    nivelEscolar: [],
    nacionalidad: [],
    sexo: [],
    tipoSangre: [],
    tipoTelefono: [],
    tipoIdentificacion: [],
    tipoDireccion: [],
    estadoCivil: [],
    rolEncargado: [],
    alergias: [],
    intolerancias: [],
    problemasGastrointestinales: [],
    tipoMedicamento: [],
    antecedentes: [],
    parentesco: [],
  },
  alumnoDirty: false,
  error: false,
  loading: false,
  matriculaExitosa: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_CATALOGO_NUEVA_MATRICULA:
      draft.loading = true;
      draft.dataLoaded = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_NUEVA_MATRICULA_SUCCESS:
      draft.catalogo = action.catalogo;
      draft.dataLoaded = true;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_NUEVA_MATRICULA_ERROR:
      draft.loading = false;
      draft.dataLoaded = false;
      draft.error = action.error;
      break;
    case DESTROY_NUEVA_MATRICULA_FORM:
      draft.alumnoDirty = false;
      draft.notNewAnymore = false;
      Object.keys(initialState).forEach(k => draft[k] = initialState[k]);
      break;
    case PROCESAR_NUEVA_MATRICULA:
      draft.loading = true;
      draft.error = false;
      break;
    case PROCESAR_NUEVA_MATRICULA_SUCCESS:
      draft.matriculaExitosa = true;
      draft.loading = false;
      draft.error = false;
      break;
    case PROCESAR_NUEVA_MATRICULA_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return state;
  }
});
