import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Field, reduxForm, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';

class EmergencyContactForm extends PureComponent {
  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, onCancel, encargados,
    } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Seleccione el encargado a vincular')}</span>
          <div className="form__form-group-field">
            <Field
              name="contactoEmergencia"
              id="contactoEmergencia"
              component={renderSelectField}
              options={encargados.map(encargado => (
                {
                  label: encargado.nombre,
                  value: encargado.id,
                }
              ))
              }
              validate={this.required}
            />
          </div>
        </div>
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('emergencyContact'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

EmergencyContactForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  encargados: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect()(reduxForm({
  form: 'emergencyContact',
})(withTranslation('common')(EmergencyContactForm)));
