import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderSimpleInput from '../../../shared/components/form/Simple';
import renderTextArea from '../../../shared/components/form/TextArea';
import renderSelectField from '../../../shared/components/form/Select';
import Generic from '../../../shared/components/Generic';
import Medicine from '../../../shared/components/Medicine';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';

const repetido = (valor, key, valores) => {
  let resultado;

  if (valor) {
    const valorFinal = valor.value;
    const padres = valores.prioridadEmergenciaPadres ? valores.prioridadEmergenciaPadres.value : undefined;
    const emergencias = valores.prioridadEmergenciaEmergencias ? valores.prioridadEmergenciaEmergencias.value : undefined;
    const medico = valores.prioridadEmergenciaMedico ? valores.prioridadEmergenciaMedico.value : undefined;

    switch (key) {
      case 'prioridadEmergenciaPadres':
        if (emergencias === valorFinal || medico === valorFinal) {
          resultado = true;
        }
        break;
      case 'prioridadEmergenciaEmergencias':
        if (padres === valorFinal || medico === valorFinal) {
          resultado = true;
        }
        break;
      case 'prioridadEmergenciaMedico':
        if (emergencias === valorFinal || padres === valorFinal) {
          resultado = true;
        }
        break;
      default:
        break;
    }
  }
  return resultado;
};

class Salud extends PureComponent {
  static propTypes = {
    formValues: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    language: PropTypes.string.isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    enableDraftButton: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      openedModal: null,
      itemId: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openModal = (modalName, itemId) => {
    this.setState({
      openedModal: modalName,
      itemId,
    });
  }

  closeModal = (modalName) => {
    const { openedModal } = this.state;
    if (modalName === openedModal) {
      this.setState({
        openedModal: null,
      });
    }
  }

  render() {
    const {
      handleSubmit, t, previousPage, formValues, data, language, onSetStudentAttribute, enableDraftButton, onSaveDraft,
    } = this.props;

    const { openedModal, itemId } = this.state;

    const {
      padecimientos, alergiasMedicamentos, limitacionesFisicas, nucleo, medicoCabecera, antecedentes, medicamentos,
    } = data;
    const medico = nucleo.medico.find(m => m.id === medicoCabecera);

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">{t('Formulario de Salud')} {CICLO_LECTIVO_A_MATRICULAR}</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Peso (Kg)')}</span>
          <div className="form__form-group-field">
            <Field
              id="peso"
              name="peso"
              component={renderSimpleInput}
              type="number"
              min="0"
              step="0.1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <div className="wizard__cards-first-container">
          <div>
            <h4 className="wizard__section-title">{t('Médico de Cabecera')}</h4>
            <Card className="wizard__form-card">
              <CardBody style={{ paddingBottom: '20px' }} className="wizard__form-card-body">
                <div className="card__title">
                  <h4 className="bold-text">{medico ? medico.medico : t('No ha indicado un médico de cabecera en datos generales')}</h4>
                </div>
                {
                  medico && (
                    <React.Fragment>
                      {medico.telefonoOficina && <p>{medico.telefonoOficina}</p>}
                      {medico.telefonoCelular && <p>{medico.telefonoCelular}</p>}
                      {medico.telefonoOtro && <p>{medico.telefonoOtro}</p>}
                    </React.Fragment>
                  )
                }
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Padecimientos')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('diseases'); }}
              open={openedModal === 'foodIntolerances'}
            >
              <DialogTitle onClose={() => { this.closeModal('foodIntolerances'); }}>{t('Padecimientos')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('foodIntolerances');
                  }}
                  currentValue={padecimientos}
                  attribute="padecimientos"
                  labelOtroValor={t('Indique el padecimiento')}
                  catalogo={[]}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('foodIntolerances'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              padecimientos.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              padecimientos.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a}</h4>
                    </div>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('padecimientos', padecimientos.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Eliminar')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>

        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Medicamentos')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('medicine'); }}
              open={openedModal === 'medicine'}
            >
              <DialogTitle onClose={() => { this.closeModal('medicine'); }}>{t('Medicamentos del Estudiante')}</DialogTitle>
              <DialogContent dividers>
                <Medicine
                  onCancel={() => {
                    this.closeModal('medicine');
                  }}
                  itemId={itemId}
                  currentValue={medicamentos}
                  tipoMedicamento={data.catalogo.tipoMedicamento}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('medicine'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              medicamentos.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              medicamentos.map(medicamento => (
                <Card key={`tel_${medicamento.medicamento}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{medicamento.medicamento}</h4>
                    </div>
                    <p>{medicamento.tipo}</p>
                    <p>{medicamento.horario}</p>
                    {medicamento.seAplicaEnHoraLectiva && <p style={{ color: '#ff4861' }} className="bold-text">{t('Este medicamento se debe aplicar en horario lectivo')}</p>}
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { this.openModal('medicine', medicamento.medicamento); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="primary"
                      >
                        {t('Editar')}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('medicamentos', medicamentos.filter(aTemp => aTemp.medicamento !== medicamento.medicamento)); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Eliminar')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Antecedentes')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('medicalHistory'); }}
              open={openedModal === 'medicalHistory'}
            >
              <DialogTitle onClose={() => { this.closeModal('medicalHistory'); }}>{t('Antecedentes')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('medicalHistory');
                  }}
                  currentValue={antecedentes}
                  attribute="antecedentes"
                  incluirDetalle
                  etiquetaValor="antecedente"
                  etiquetaDetalle="observacion"
                  labelOtroValor={t('Indique el antecedente')}
                  catalogo={[]}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('medicalHistory'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              antecedentes.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              antecedentes.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a.antecedente}</h4>
                    </div>
                    <p>{a.observacion}</p>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('antecedentes', antecedentes.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Eliminar')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Alergias a Medicamentos')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('medicineAlergies'); }}
              open={openedModal === 'medicineAlergies'}
            >
              <DialogTitle onClose={() => { this.closeModal('medicineAlergies'); }}>{t('Alergias a Medicamentos')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('medicineAlergies');
                  }}
                  currentValue={alergiasMedicamentos}
                  attribute="alergiasMedicamentos"
                  labelOtroValor={t('Indique la alergia')}
                  catalogo={[]}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('medicineAlergies'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              alergiasMedicamentos.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              alergiasMedicamentos.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a}</h4>
                    </div>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('alergiasMedicamentos', alergiasMedicamentos.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Eliminar')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Limitaciones Físicas')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('physicalLimitations'); }}
              open={openedModal === 'physicalLimitations'}
            >
              <DialogTitle onClose={() => { this.closeModal('physicalLimitations'); }}>{t('Limitaciones Físicas')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('physicalLimitations');
                  }}
                  currentValue={limitacionesFisicas}
                  attribute="limitacionesFisicas"
                  labelOtroValor={t('Indique la limitación física')}
                  catalogo={[]}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('physicalLimitations'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              limitacionesFisicas.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              limitacionesFisicas.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a}</h4>
                    </div>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('limitacionesFisicas', limitacionesFisicas.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Eliminar')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>
        <div className="wizard__cards-container">
          <h4 className="wizard__section-title">{t('Su hijo (a) puede asistir a')}:</h4>
        </div>
        <p className="wizard__contract-text">{t('Educación Física')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="puedeAsistirAFisica"
              name="puedeAsistirAFisica"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="puedeAsistirAFisica"
              name="puedeAsistirAFisica"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        {
          formValues.puedeAsistirAFisica === '0'
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Causa / Observaciones')}</span>
                <div className="form__form-group-field">
                  <Field
                    id="causasObervacionesFisica"
                    name="causasObervacionesFisica"
                    component={renderTextArea}
                    type="textarea"
                    validate={value => (value ? undefined : t('Obligatorio'))}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <p className="wizard__contract-text">{t('Giras')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="puedeAsistirAGiras"
              name="puedeAsistirAGiras"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="puedeAsistirAGiras"
              name="puedeAsistirAGiras"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        {
          formValues.puedeAsistirAGiras === '0'
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Causa / Observaciones')}</span>
                <div className="form__form-group-field">
                  <Field
                    id="causasObervacionesGiras"
                    name="causasObervacionesGiras"
                    component={renderTextArea}
                    type="textarea"
                    validate={value => (value ? undefined : t('Obligatorio'))}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <p className="wizard__contract-text">{t('Expediciones')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="puedeAsistirAExpediciones"
              name="puedeAsistirAExpediciones"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="puedeAsistirAExpediciones"
              name="puedeAsistirAExpediciones"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        {
          formValues.puedeAsistirAExpediciones === '0'
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Causa / Observaciones')}</span>
                <div className="form__form-group-field">
                  <Field
                    id="causasObervacionesExpediciones"
                    name="causasObervacionesExpediciones"
                    component={renderTextArea}
                    type="textarea"
                    validate={value => (value ? undefined : t('Obligatorio'))}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <div className="wizard__cards-container">
          <p style={{ marginBottom: '20px' }} className="wizard__contract-text">{t('En caso de que su hijo requiera atención médica inmediata, en qué orden desea que se llame')}:</p>
          <div>
            {[
              {
                label: t('Padres de Familia o Encargados'),
                valor: 'prioridadEmergenciaPadres',
              },
              {
                label: t('Emergencias Médicas'),
                valor: 'prioridadEmergenciaEmergencias',
              },
              {
                label: t('Médico Encargado'),
                valor: 'prioridadEmergenciaMedico',
              },
            ].map(contacto => (
              <Card key={`contactoEmergencia_${contacto.valor}`} className="wizard__form-card">
                <CardBody style={{ paddingBottom: '20px' }} className="wizard__form-card-body">
                  <div className="card__title">
                    <h4 className="bold-text">{contacto.label}</h4>
                  </div>
                  <Field
                    id={contacto.valor}
                    name={contacto.valor}
                    component={renderSelectField}
                    options={['1', '2', '3'].map(tipo => (
                      { value: tipo.toString(), label: tipo }
                    ))}
                    validate={
                      (value) => {
                        if (!value) {
                          return t('Obligatorio');
                        }
                        return repetido(value, contacto.valor, formValues) ? t('Ya utilizó este valor en otra opción') : undefined;
                      }
                    }
                  />
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
        <p className="wizard__contract-text">{t('Es responsabilidad del padre o encargado mantener la información actualizada en el Portal Weizman, y en caso de algún cambio en esta información es necesario notificar al Instituto')}. {t('Deben notificar los cambios de información a')} <a href="mailto:asistente.direccion@weizmancr.net">asistente.direccion@weizmancr.net</a>.</p>
        <p className="wizard__contract-text">{t('Nota')}. {t('Se deja claramente establecido que EL INSTITUTO tomará las decisiones sobre los procedimientos médicos a seguir y/o medicamentos a suministrar en caso que se presente algún problema de salud del menor, basado en la información suministrada por el Padre o el Encargado')}; {t('por lo cual el Padre o Encargado libera de toda responsabilidad a EL INSTITUTO por las consecuencias que puedan presentarse por información proporcionada en forma incompleta, imprecisa, incorrecta, omisa o no suministrada o no actualizada')}.</p>
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>{t('Atrás')}</Button>
          {
            enableDraftButton
            && <Button onClick={onSaveDraft} color="primary" type="button" className="next">{t('Guardar Borrador')}</Button>
          }
          <Button color="primary" type="submit" className="next">{t('Guardar y Seguir')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

const selector = formValueSelector('matricula');

function mapStateToProps(state) {
  const formValues = selector(state,
    'puedeAsistirAFisica',
    'puedeAsistirAGiras',
    'puedeAsistirAExpediciones',
    'prioridadEmergenciaPadres',
    'prioridadEmergenciaEmergencias',
    'prioridadEmergenciaMedico');
  return { formValues };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(Salud)));
