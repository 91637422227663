import React, { Fragment, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { CircularProgress } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';

i18next.init(i18nextConfig);

const App = () => (
  <BrowserRouter basename="/">
    <Suspense
      fallback={
        (
          <CircularProgress
            style={{
              color: '#131457 !important',
              position: 'absolute !important',
              top: '45% !important',
              zIndex: '9999',
              left: '49% !important',
            }}
          />
        )
      }
    >
      <I18nextProvider i18n={i18next}>
        <ScrollToTop>
          <Fragment>
            <div>
              <Router />
            </div>
          </Fragment>
        </ScrollToTop>
      </I18nextProvider>
    </Suspense>
  </BrowserRouter>
);

export default hot(module)(App);
