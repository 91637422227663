import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';

const Ava = `${process.env.PUBLIC_URL}/img/ava.svg`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;
    const { t, onLogout, username } = this.props;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{username}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <div
              tabIndex={0}
              role="button"
              onKeyDown={(evt) => {
                if (evt.keyCode === 13) {
                  onLogout();
                }
              }}
              type="button"
              onClick={onLogout}
              className="topbar__link"
            >
              <span className="topbar__link-icon lnr lnr-exit" />
              <p className="topbar__link-title">{t('Salir')}</p>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  username: PropTypes.string,
};

TopbarProfile.defaultProps = {
  username: '',
};

export default withTranslation('common')(TopbarProfile);
