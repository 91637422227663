import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { Button, ButtonToolbar, Card, CardBody } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import renderSelectField from "../../../shared/components/form/Select";
import renderDatePickerField from "../../../shared/components/form/DatePicker";
import renderSimpleInput from "../../../shared/components/form/Simple";
import Address from "../../../shared/components/Address";
import Phone from "../../../shared/components/Phone";
import Encargado from "../../../shared/components/Encargado";
import EmergencyContact from "../../../shared/components/EmergencyContact";
import Doctor from "../../../shared/components/Doctor";
import { concatenarNombreEncargado } from "../../../utils/stringUtils";
import renderRadioButtonField from "../../../shared/components/form/RadioButton";

// eslint-disable-next-line
const Moment = require("moment");

const getDireccion = (nucleo, id) => nucleo.direccion.find((d) => d.id === id);

const getContactoDeEmergencia = (nucleo, id) =>
  nucleo.encargado.find((d) => d.id === id);

const getMedicoCabecera = (nucleo, id) =>
  nucleo.medico.find((d) => d.id === id);

const getTelefonos = (nucleo, ids) =>
  nucleo.telefono.filter((t) => ids.indexOf(t.id) > -1);

const getEncargados = (nucleo, ids) =>
  nucleo.encargado.filter((t) => ids.indexOf(t.id) > -1);

class DatosGenerales extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    language: PropTypes.string.isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    puedeActualizarDG: PropTypes.bool.isRequired,
    tieneSiguiente: PropTypes.bool.isRequired,
    tieneAnterior: PropTypes.bool.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      openedModal: null,
      itemId: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openModal = (modalName, itemId) => {
    this.setState({
      openedModal: modalName,
      itemId,
    });
  };

  closeModal = (modalName) => {
    const { openedModal } = this.state;
    if (modalName === openedModal) {
      this.setState({
        openedModal: null,
      });
    }
  };

  render() {
    const {
      handleSubmit,
      t,
      language,
      data,
      onSetStudentAttribute,
      puedeActualizarDG,
      tieneSiguiente,
      tieneAnterior,
      previousPage,
    } = this.props;

    const { openedModal, itemId } = this.state;
    const direccion = getDireccion(data.nucleo, data.direccion);
    const medicoCabecera = getMedicoCabecera(data.nucleo, data.medicoCabecera);
    const contactoEmergencia = getContactoDeEmergencia(
      data.nucleo,
      data.contactoEmergencia
    );
    const telefonos = getTelefonos(data.nucleo, data.telefono);
    const encargados = getEncargados(data.nucleo, data.encargado);

    return (
      <form
        className="form form--horizontal wizard__form"
        onSubmit={handleSubmit}
      >
        <h3 className="wizard__title">{t("Datos Generales")}</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("Grado matriculado")}
          </span>
          <div className="form__form-group-field">
            <p className="bold-text">{t(data.alumno.nivel)}</p>
          </div>
        </div>
        {puedeActualizarDG ? (
          <React.Fragment>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Nombre del Alumno")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="nombreAlumno"
                  id="nombreAlumno"
                  component={renderSimpleInput}
                  type="text"
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                  help={t(
                    "Por favor ingrese solamente el nombre en este campo, sin apellidos"
                  )}
                  forceHelp
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Primer Apellido")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="apellido1"
                  id="apellido1"
                  component={renderSimpleInput}
                  type="text"
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Segundo Apellido")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="apellido2"
                  id="apellido2"
                  component={renderSimpleInput}
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Nombre en Hebreo")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="nombreAlumnoHebreo"
                  id="nombreAlumnoHebreo"
                  component={renderSimpleInput}
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Nacionalidad")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="nacionalidad"
                  id="nacionalidad"
                  component={renderSelectField}
                  options={_.orderBy(data.catalogo.nacionalidad, [
                    language,
                  ]).map((nacionalidad) => ({
                    label: nacionalidad[language],
                    value: nacionalidad.es,
                  }))}
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t("Sexo")}</span>
              <div className="form__form-group-field">
                <Field
                  name="sexo"
                  id="sexo"
                  component={renderSelectField}
                  options={data.catalogo.sexo.map((sexo) => ({
                    label: sexo[language],
                    value: sexo.es,
                  }))}
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Tipo Identificación")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="tipoIdentificacion"
                  id="tipoIdentificacion"
                  component={renderSelectField}
                  options={data.catalogo.tipoIdentificacion.map((tipo) => ({
                    label: tipo[language],
                    value: tipo.es,
                  }))}
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Identificación")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="identificacion"
                  id="identificacion"
                  component={renderSimpleInput}
                  type="text"
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Fecha de Nacimiento")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="fechaNacimiento"
                  id="fechaNacimiento"
                  component={renderDatePickerField}
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Tipo de Sangre")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="tipoSangre"
                  id="tipoSangre"
                  component={renderSelectField}
                  options={data.catalogo.tipoSangre.map((tipoSangre) => ({
                    label: tipoSangre[language],
                    value: tipoSangre.es,
                  }))}
                  validate={(value) => (value ? undefined : t("Obligatorio"))}
                />
              </div>
            </div>
            <div className="form__form-group">
              <p className="wizard__contract-text">
                {t(
                  "¿Autoriza usted a la institución a tomar fotografías de su hijo/a durante actividades escolares y eventos?"
                )}
                :
              </p>
              <div style={{ marginTop: "10px" }}>
                <div className="form__form-group-field">
                  <Field
                    name="autorizacionFotos"
                    id="autorizacionFotos"
                    component={renderRadioButtonField}
                    label={t("Sí")}
                    radioValue="1"
                    validate={(value) => (value ? undefined : t("Obligatorio"))}
                    defaultChecked={data.alumno.autorizoFotos}
                  />
                  <Field
                    name="autorizacionFotos"
                    id="autorizacionFotos"
                    component={renderRadioButtonField}
                    label="No"
                    radioValue="0"
                    validate={(value) => (value ? undefined : t("Obligatorio"))}
                    defaultChecked={!data.alumno.autorizoFotos}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <p className="wizard__contract-text">
                {t(
                  "¿Autoriza usted a la institución a utilizar las fotografías de su hijo/a en materiales promocionales, publicaciones en redes sociales, y otros medios de comunicación oficiales del colegio?"
                )}
              </p>
              <div style={{ marginTop: "10px" }}>
                <div className="form__form-group-field">
                  <Field
                    name="autorizacionFotosMedios"
                    id="autorizacionFotosMedios"
                    component={renderRadioButtonField}
                    label={t("Sí")}
                    radioValue="1"
                    validate={(value) => (value ? undefined : t("Obligatorio"))}
                    defaultChecked={data.alumno.autorizacionFotosMedios}
                  />
                  <Field
                    name="autorizacionFotosMedios"
                    id="autorizacionFotosMedios"
                    component={renderRadioButtonField}
                    label="No"
                    radioValue="0"
                    validate={(value) => (value ? undefined : t("Obligatorio"))}
                    defaultChecked={!data.alumno.autorizacionFotosMedios}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Nombre del Alumno")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.nombreAlumno}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Primer Apellido")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.apellido1}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Segundo Apellido")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.apellido2}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Nombre en Hebreo")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.nombreAlumnoHebreo}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Nacionalidad")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.nacionalidad}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t("Sexo")}</span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.sexo}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Tipo Identificación")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.tipoIdentificacion}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Identificación")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.identificacion}</p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Fecha de Nacimiento")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">
                  {data.alumno.fechaNacimiento
                    ? new Moment(data.alumno.fechaNacimiento).format("l")
                    : ""}
                </p>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("Tipo de Sangre")}
              </span>
              <div className="form__form-group-field">
                <p className="bold-text">{data.alumno.tipoSangre}</p>
              </div>
            </div>
          </React.Fragment>
        )}
        <div style={{ marginTop: "20px" }} className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t("Dirección")}</h4>
            <Dialog
              onBackdropClick={() => {
                this.closeModal("address");
              }}
              open={openedModal === "address"}
            >
              <DialogTitle
                onClose={() => {
                  this.closeModal("address");
                }}
              >
                {t("Dirección del Estudiante")}
              </DialogTitle>
              <DialogContent dividers>
                <Address
                  onCancel={() => {
                    this.closeModal("address");
                  }}
                  itemId={itemId}
                  tipoDireccion={data.catalogo.tipoDireccion}
                  language={language}
                  nucleoId={data.nucleo.id}
                  nucleoDirecciones={data.nucleo.direccion}
                />
              </DialogContent>
            </Dialog>
            {!direccion && (
              <h5 className="bold-text">{t("No se han agregado datos")}</h5>
            )}
            {!direccion && puedeActualizarDG ? (
              <React.Fragment>
                <Button
                  type="button"
                  onClick={() => {
                    this.openModal("address");
                  }}
                  size="sm"
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "relative",
                    top: "7px",
                  }}
                  color="primary"
                >
                  + {t("Agregar")}
                </Button>
                <h5 className="bold-text">{t("No se han agregado datos")}</h5>
              </React.Fragment>
            ) : (
              direccion && (
                <Card className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{`${direccion.provincia}, ${direccion.canton}, ${direccion.distrito}`}</h4>
                    </div>
                    <p
                      style={{ marginBottom: !puedeActualizarDG ? "20px" : "" }}
                    >
                      {direccion.senias}
                    </p>
                    {puedeActualizarDG && (
                      <div className="wizard__card-buttons">
                        <Button
                          type="button"
                          onClick={() => {
                            this.openModal("address", direccion.id);
                          }}
                          size="sm"
                          style={{
                            display: "inline",
                          }}
                          color="primary"
                        >
                          {t("Editar")}
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            onSetStudentAttribute("direccion", "");
                          }}
                          size="sm"
                          style={{
                            display: "inline",
                          }}
                          color="danger"
                        >
                          {t("Desvincular")}
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )
            )}
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t("Teléfono")}</h4>
            <Dialog
              onBackdropClick={() => {
                this.closeModal("phone");
              }}
              open={openedModal === "phone"}
            >
              <DialogTitle
                onClose={() => {
                  this.closeModal("phone");
                }}
              >
                {t("Teléfono del Estudiante")}
              </DialogTitle>
              <DialogContent dividers>
                <Phone
                  onCancel={() => {
                    this.closeModal("phone");
                  }}
                  itemId={itemId}
                  tipoTelefono={data.catalogo.tipoTelefono}
                  language={language}
                  nucleoId={data.nucleo.id}
                  nucleoTelefonos={data.nucleo.telefono}
                />
              </DialogContent>
            </Dialog>
            {puedeActualizarDG && (
              <Button
                type="button"
                onClick={() => {
                  this.openModal("phone");
                }}
                size="sm"
                style={{
                  display: "inline",
                  marginLeft: "10px",
                  position: "relative",
                  top: "7px",
                }}
                color="primary"
              >
                + {t("Agregar")}
              </Button>
            )}
            {telefonos.length === 0 && (
              <h5 className="bold-text">{t("No se han agregado datos")}</h5>
            )}
            {telefonos.map((tel) => (
              <Card key={`tel_${tel.id}`} className="wizard__form-card">
                <CardBody className="wizard__form-card-body">
                  <div className="card__title">
                    <h4 className="bold-text">{tel.telefono}</h4>
                  </div>
                  <p style={{ marginBottom: !puedeActualizarDG ? "20px" : "" }}>
                    {t(tel.tipoTelefono)}
                  </p>
                  {puedeActualizarDG && (
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => {
                          this.openModal("phone", tel.id);
                        }}
                        size="sm"
                        style={{
                          display: "inline",
                        }}
                        color="primary"
                      >
                        {t("Editar")}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          onSetStudentAttribute(
                            "telefono",
                            _.map(
                              telefonos.filter(
                                (telTemp) => telTemp.id !== tel.id
                              ),
                              "id"
                            )
                          );
                        }}
                        size="sm"
                        style={{
                          display: "inline",
                        }}
                        color="danger"
                      >
                        {t("Desvincular")}
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t("Encargados")}</h4>
            <Dialog
              onBackdropClick={() => {
                this.closeModal("tutor");
              }}
              open={openedModal === "tutor"}
            >
              <DialogTitle
                onClose={() => {
                  this.closeModal("tutor");
                }}
              >
                {t("Encargado del Estudiante")}
              </DialogTitle>
              <DialogContent dividers>
                <Encargado
                  onCancel={() => {
                    this.closeModal("tutor");
                  }}
                  itemId={itemId}
                  catalogo={data.catalogo}
                  language={language}
                  nucleoId={data.nucleo.id}
                  nucleoEncargados={data.nucleo.encargado}
                  nucleoTelefonos={data.nucleo.telefono}
                  puedeActualizarDG={puedeActualizarDG}
                />
              </DialogContent>
            </Dialog>
            {puedeActualizarDG && (
              <Button
                type="button"
                onClick={() => {
                  this.openModal("tutor");
                }}
                size="sm"
                style={{
                  display: "inline",
                  marginLeft: "10px",
                  position: "relative",
                  top: "7px",
                }}
                color="primary"
              >
                + {t("Agregar")}
              </Button>
            )}
            {encargados.length === 0 && (
              <h5 className="bold-text">{t("No se han agregado datos")}</h5>
            )}
            {encargados.map((e) => (
              <Card key={`tel_${e.id}`} className="wizard__form-card">
                <CardBody className="wizard__form-card-body">
                  <div className="card__title">
                    <h4 className="bold-text">
                      {concatenarNombreEncargado(e)}
                    </h4>
                  </div>
                  <p>{t(e.rolEncargado)}</p>
                  {puedeActualizarDG ? (
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => {
                          this.openModal("tutor", e.id);
                        }}
                        size="sm"
                        style={{
                          display: "inline",
                        }}
                        color="primary"
                      >
                        {t("Editar")}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          onSetStudentAttribute(
                            "encargado",
                            _.map(
                              encargados.filter((enc) => enc.id !== e.id),
                              "id"
                            )
                          );
                        }}
                        size="sm"
                        style={{
                          display: "inline",
                        }}
                        color="danger"
                      >
                        {t("Desvincular")}
                      </Button>
                    </div>
                  ) : (
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => {
                          this.openModal("tutor", e.id);
                        }}
                        size="sm"
                        style={{
                          display: "inline",
                        }}
                        color="primary"
                      >
                        {t("Ver")}
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">
              {t("Contacto de Emergencia")}
            </h4>
            <Dialog
              onBackdropClick={() => {
                this.closeModal("emergencyContact");
              }}
              open={openedModal === "emergencyContact"}
            >
              <DialogTitle
                onClose={() => {
                  this.closeModal("emergencyContact");
                }}
              >
                {t("Contacto de Emergencia")}
              </DialogTitle>
              <DialogContent dividers>
                <EmergencyContact
                  onCancel={() => {
                    this.closeModal("emergencyContact");
                  }}
                  encargados={data.nucleo.encargado}
                />
              </DialogContent>
            </Dialog>
            {!contactoEmergencia && (
              <h5 className="bold-text">{t("No se han agregado datos")}</h5>
            )}
            {!contactoEmergencia && puedeActualizarDG ? (
              <React.Fragment>
                <Button
                  type="button"
                  onClick={() => {
                    this.openModal("emergencyContact");
                  }}
                  size="sm"
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "relative",
                    top: "7px",
                  }}
                  color="primary"
                >
                  + {t("Agregar")}
                </Button>
                <h5 className="bold-text">{t("No se han agregado datos")}</h5>
              </React.Fragment>
            ) : (
              contactoEmergencia && (
                <Card className="wizard__form-card">
                  <CardBody
                    style={{ paddingBottom: !puedeActualizarDG ? "20px" : "" }}
                    className="wizard__form-card-body"
                  >
                    <div className="card__title">
                      <h4 className="bold-text">{contactoEmergencia.nombre}</h4>
                    </div>
                    {data.nucleo.telefono
                      .filter(
                        (nt) => contactoEmergencia.telefono.indexOf(nt.id) > -1
                      )
                      .map((nt) => (
                        <p key={`tel_${nt.id}`}>{nt.telefono}</p>
                      ))}
                    {puedeActualizarDG && (
                      <div className="wizard__card-buttons">
                        <Button
                          type="button"
                          onClick={() => {
                            onSetStudentAttribute("contactoEmergencia", "");
                          }}
                          size="sm"
                          style={{
                            display: "inline",
                          }}
                          color="danger"
                        >
                          {t("Desvincular")}
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )
            )}
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t("Médico de Cabecera")}</h4>
            <Dialog
              onBackdropClick={() => {
                this.closeModal("doctor");
              }}
              open={openedModal === "doctor"}
            >
              <DialogTitle
                onClose={() => {
                  this.closeModal("doctor");
                }}
              >
                {t("Médico de Cabecera")}
              </DialogTitle>
              <DialogContent dividers>
                <Doctor
                  onCancel={() => {
                    this.closeModal("doctor");
                  }}
                  itemId={itemId}
                  nucleoId={data.nucleo.id}
                  nucleoMedicos={data.nucleo.medico}
                />
              </DialogContent>
            </Dialog>
            {!medicoCabecera && (
              <h5 className="bold-text">{t("No se han agregado datos")}</h5>
            )}
            {!medicoCabecera && puedeActualizarDG ? (
              <React.Fragment>
                <Button
                  type="button"
                  onClick={() => {
                    this.openModal("doctor");
                  }}
                  size="sm"
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "relative",
                    top: "7px",
                  }}
                  color="primary"
                >
                  + {t("Agregar")}
                </Button>
                <h5 className="bold-text">{t("No se han agregado datos")}</h5>
              </React.Fragment>
            ) : (
              medicoCabecera && (
                <Card className="wizard__form-card">
                  <CardBody
                    style={{ paddingBottom: !puedeActualizarDG ? "20px" : "" }}
                    className="wizard__form-card-body"
                  >
                    <div className="card__title">
                      <h4 className="bold-text">{medicoCabecera.medico}</h4>
                    </div>
                    {medicoCabecera.telefonoOficina && (
                      <p>{medicoCabecera.telefonoOficina}</p>
                    )}
                    {medicoCabecera.telefonoCelular && (
                      <p>{medicoCabecera.telefonoCelular}</p>
                    )}
                    {medicoCabecera.telefonoOtro && (
                      <p>{medicoCabecera.telefonoOtro}</p>
                    )}
                    {puedeActualizarDG && (
                      <div className="wizard__card-buttons">
                        <Button
                          type="button"
                          onClick={() => {
                            this.openModal("doctor", medicoCabecera.id);
                          }}
                          size="sm"
                          style={{
                            display: "inline",
                          }}
                          color="primary"
                        >
                          {t("Editar")}
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            onSetStudentAttribute("medicoCabecera", "");
                          }}
                          size="sm"
                          style={{
                            display: "inline",
                          }}
                          color="danger"
                        >
                          {t("Desvincular")}
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )
            )}
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          {tieneAnterior || tieneSiguiente ? (
            <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
              {tieneAnterior ? (
                <Button
                  color="primary"
                  type="button"
                  className="previous"
                  onClick={previousPage}
                >
                  {t("Atrás")}
                </Button>
              ) : null}
              {tieneSiguiente ? (
                <Button color="primary" type="submit" className="next">
                  {puedeActualizarDG ? t("Guardar y Seguir") : t("Siguiente")}
                </Button>
              ) : null}
            </ButtonToolbar>
          ) : (
            <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
              <Link to="/matricula">
                <Button color="primary" className="previous">
                  {t("Volver")}
                </Button>
              </Link>
            </ButtonToolbar>
          )}
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "alumnoActualizar", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation("common")(DatosGenerales));
