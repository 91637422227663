import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import guid from 'guid';
import { Prompt, Redirect } from 'react-router';
import WizardForm from './components/WizardForm';
import injectSaga from '../../utils/injectSaga';
import { concatenarNombreAlumno } from '../../utils/stringUtils';
import saga from './saga';
import {
  destroyForm, loadAlumno, loadCatalogo, saveDraft, setStudentAttribute,
} from '../../redux/actions/matricularActions';

const transformarAlumno = (esNuevo, alumno, nucleo) => {
  if (esNuevo) {
    return {};
  }
  const newAlumno = _.cloneDeep(alumno);
  const atributosConListado = [
    'nivel',
    'nacionalidad',
    'sexo',
    'tipoIdentificacion',
    'tipoSangre',
    'prioridadEmergenciaPadres',
    'prioridadEmergenciaEmergencias',
    'prioridadEmergenciaMedico',
  ];
  atributosConListado.forEach((a) => {
    newAlumno[a] = {
      value: alumno[a],
      label: alumno[a],
    };
  });
  if (alumno.diabetesHiperinsulinemia !== undefined) {
    if (alumno.diabetesHiperinsulinemia !== null && Object.keys(alumno.diabetesHiperinsulinemia).length > 0) {
      newAlumno.diabatesOHiperinsulinemia = '1';
      newAlumno.tipoMedicamentoDiabetesHiperinsulinemia = alumno.diabetesHiperinsulinemia.medicamento;
      newAlumno.horarioMedicamentoDiabetesHiperinsulinemia = alumno.diabetesHiperinsulinemia.horario;
    } else {
      newAlumno.diabatesOHiperinsulinemia = '0';
      newAlumno.tipoMedicamentoDiabetesHiperinsulinemia = null;
      newAlumno.horarioMedicamentoDiabetesHiperinsulinemia = null;
    }
  }
  if (alumno.problemaHepatico !== undefined) {
    if (alumno.problemaHepatico) {
      newAlumno.problemaHepatico = '1';
      newAlumno.detalleProblemaHepatico = alumno.problemaHepatico;
    } else {
      newAlumno.problemaHepatico = '0';
      newAlumno.detalleProblemaHepatico = null;
    }
  }
  if (alumno.dayCare) {
    newAlumno.requieroDayCare = '1';
    newAlumno.frecuenciaDayCare = alumno.dayCare;
  } else {
    newAlumno.requieroDayCare = '0';
    newAlumno.frecuenciaDayCare = null;
  }
  if (alumno.puedeAsistirAFisica !== undefined && alumno.puedeAsistirAFisica !== null) {
    if (!alumno.puedeAsistirAFisica) {
      newAlumno.puedeAsistirAFisica = '0';
      newAlumno.causasObervacionesFisica = alumno.causasObervacionesFisica;
    } else {
      newAlumno.puedeAsistirAFisica = '1';
      newAlumno.causasObervacionesFisica = null;
    }
  }
  if (alumno.puedeAsistirAGiras !== undefined && alumno.puedeAsistirAGiras !== null) {
    if (!alumno.puedeAsistirAGiras) {
      newAlumno.puedeAsistirAGiras = '0';
      newAlumno.causasObervacionesGiras = alumno.causasObervacionesGiras;
    } else {
      newAlumno.puedeAsistirAGiras = '1';
      newAlumno.causasObervacionesGiras = null;
    }
  }
  if (alumno.puedeAsistirAExpediciones !== undefined && alumno.puedeAsistirAExpediciones !== null) {
    if (!alumno.puedeAsistirAExpediciones) {
      newAlumno.puedeAsistirAExpediciones = '0';
      newAlumno.causasObervacionesExpediciones = alumno.causasObervacionesExpediciones;
    } else {
      newAlumno.puedeAsistirAExpediciones = '1';
      newAlumno.causasObervacionesExpediciones = null;
    }
  }

  if (alumno.nuevoIngreso && nucleo.miembroCISCR) {
    newAlumno.miembroCISCR = true;
  }

  if (alumno.nuevoIngreso && nucleo.alDiaCISCR) {
    newAlumno.alDiaCISCR = true;
  }

  delete newAlumno.diabetesHiperinsulinemia;
  delete newAlumno.dayCare;
  return newAlumno;
};

// eslint-disable-next-line
const Moment = require("moment");

class Matricular extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    matricular: PropTypes.shape({}).isRequired,
    dirty: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      calledLoad: false,
      esNuevo: match.params.id === 'nuevo',
      isAdmin: false,
      puedeActualizar: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroyForm('alumnoActualizar'));
  }

  loadData = () => {
    const { app, dispatch, match } = this.props;
    const { esNuevo, calledLoad } = this.state;
    if (!calledLoad && app.session) {
      const { nucleo } = app.session.user;
      const isAdmin = app.session.user.roles.length > 1 || (app.session.user.roles.indexOf('Encargado') === -1);
      let puedeActualizar = true;
      if (isAdmin) {
        puedeActualizar = _.intersection(app.accesos.find(a => a.opcion === 'actualizarDatos').roles, app.session.user.roles).length > 0;
      }
      dispatch(loadCatalogo(app.session.id, nucleo, isAdmin, match.params.id));
      if (!esNuevo) {
        dispatch(loadAlumno({
          token: app.session.id,
          id: match.params.id,
        }));
      }
      this.setState({
        calledLoad: true,
        isAdmin,
        puedeActualizar,
      });
    }
  }

  handleSumbit = (values) => {
    const { esNuevo } = this.state;
    const { app, matricular, dispatch } = this.props;

    let dietaEspecialForm = _.cloneDeep(values.dietaEspecial);
    const newAlumno = _.cloneDeep(matricular.alumno);

    const atributosConListado = [
      'nivel',
      'nacionalidad',
      'sexo',
      'tipoIdentificacion',
      'tipoSangre',
      'prioridadEmergenciaPadres',
      'prioridadEmergenciaEmergencias',
      'prioridadEmergenciaMedico',
    ];

    const atributosIgnorados = [
      'diabatesOHiperinsulinemia',
      'tipoMedicamentoDiabetesHiperinsulinemia',
      'horarioMedicamentoDiabetesHiperinsulinemia',
      'problemaHepatico',
      'detalleProblemaHepatico',
      'dietaEspecial',
      'requieroDayCare',
      'frecuenciaDayCare',
      'diabatesOHiperinsulinemia',
      'autorizacionFotos',
      'autorizacionExcursiones',
      'autorizacionFotosMedios',
      'causasNoAsistirGiras',
      'causasNoAsistirExpediciones',
    ];

    Object.keys(values).forEach((nombre) => {
      if (atributosConListado.indexOf(nombre) > -1) {
        newAlumno[nombre] = values[nombre].value;
      } else if (atributosIgnorados.indexOf(nombre) === -1 && nombre.indexOf('firmaPadre') === -1) {
        newAlumno[nombre] = values[nombre];
      }
    });

    if (values.puedeAsistirAFisica === '1') {
      newAlumno.causasObervacionesFisica = null;
    }

    if (values.puedeAsistirAGiras === '1') {
      newAlumno.causasObervacionesGiras = null;
    }

    if (values.puedeAsistirAExpediciones === '1') {
      newAlumno.causasObervacionesExpediciones = null;
    }

    if (values.diabatesOHiperinsulinemia !== undefined && values.diabatesOHiperinsulinemia !== null) {
      if (values.diabatesOHiperinsulinemia === '1') {
        newAlumno.diabetesHiperinsulinemia = {
          medicamento: values.tipoMedicamentoDiabetesHiperinsulinemia,
          horario: values.horarioMedicamentoDiabetesHiperinsulinemia,
        };
      } else {
        newAlumno.diabetesHiperinsulinemia = {};
      }
    }

    if (values.problemaHepatico !== undefined && values.problemaHepatico !== null) {
      if (values.problemaHepatico === '1') {
        newAlumno.problemaHepatico = values.detalleProblemaHepatico;
      } else {
        newAlumno.problemaHepatico = '';
      }
    }

    if (dietaEspecialForm && dietaEspecialForm.name) {
      const fileName = dietaEspecialForm.name;
      const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
      const nombreArchivo = `${guid.create().value}.${extension}`;
      dietaEspecialForm.name = nombreArchivo;
      newAlumno.dietaEspecial = nombreArchivo;
    } else {
      newAlumno.dietaEspecial = null;
      dietaEspecialForm = null;
    }

    const {
      direccion, contactoEmergencia, medicoCabecera, telefono, encargado, alergiasAlimenticias,
      intoleranciasAlimenticias, problemasGastrointestinales, padecimientos, medicamentos, antecedentes, alergiasMedicamentos,
      limitacionesFisicas,
    } = matricular;

    if (!Array.isArray(newAlumno.bitacora)) {
      newAlumno.bitacora = [];
    }

    let patchNucleo = false;
    const nucleoPatch = {};

    if (newAlumno.miembroCISCR === '1' || newAlumno.miembroCISCR === '0') {
      nucleoPatch.miembroCISCR = newAlumno.miembroCISCR !== '0';
      patchNucleo = true;
    }

    if (newAlumno.alDiaCISCR === '1' || newAlumno.alDiaCISCR === '0') {
      nucleoPatch.alDiaCISCR = newAlumno.alDiaCISCR !== '0';
      patchNucleo = true;
    }

    const filesForms = [];
    ['notasExpediente', 'actaNacimiento', 'fotografia', 'libroVacunacion'].forEach((a) => {
      const tempForm = _.cloneDeep(values[a]);
      if (tempForm && tempForm.name) {
        const fileName = tempForm.name;
        const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
        const nombreArchivo = `${guid.create().value}.${extension}`;
        tempForm.name = nombreArchivo;
        newAlumno[a] = nombreArchivo;
        filesForms.push({
          type: a,
          form: tempForm,
        });
      } else {
        newAlumno[a] = matricular.alumno[a] ? matricular.alumno[a] : null;
      }
    });

    newAlumno.bitacora.push({
      fecha: new Date(),
      accion: 'Guarda Borrador',
      idUsuario: app.session.user.id,
      usuario: app.session.user.nombre,
    });

    const payload = {
      isNew: esNuevo,
      token: app.session.id,
      alumno: {
        ...newAlumno,
        direccion,
        contactoEmergencia,
        medicoCabecera,
        telefono,
        encargado,
        alergiasAlimenticias,
        intoleranciasAlimenticias,
        problemasGastrointestinales,
        padecimientos,
        medicamentos,
        antecedentes,
        alergiasMedicamentos,
        limitacionesFisicas,
        autorizoFotos: values.autorizacionFotos === '1',
        autorizacionFotosMedios: values.autorizacionFotosMedios === '1',
      },
      dietaEspecialForm,
      filesForms,
      nucleo: patchNucleo && nucleoPatch,
    };

    dispatch(saveDraft(payload));
  }

  onSetStudentAttribute = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setStudentAttribute({
      attribute,
      value,
    }));
  }

  render() {
    const {
      esNuevo, isAdmin, puedeActualizar,
    } = this.state;

    const {
      t, matricular, app, dirty,
    } = this.props;

    if (matricular.matriculaExitosa) {
      return <Redirect to="/matricula/exito" />;
    }

    return (
      ((!esNuevo && !matricular.nucleo.id) || !app.session)
        ? <CircularProgress className="circular-progress" />
        : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{t('Expediente de')} {esNuevo ? t('nuevo alumno') : concatenarNombreAlumno(matricular.alumno)}</h3>
              </Col>
            </Row>
            {
              matricular.loading && <CircularProgress className="circular-progress" />
            }
            <WizardForm
              esNuevo={esNuevo}
              onSetStudentAttribute={this.onSetStudentAttribute}
              initialValues={transformarAlumno(esNuevo, matricular.alumno, matricular.nucleo)}
              data={matricular}
              token={app.session.id}
              enableDraftButton={dirty || matricular.alumnoDirty}
              onSubmit={this.handleSumbit}
              isAdmin={isAdmin}
              puedeActualizar={puedeActualizar}
              app={app}
            />
            <Prompt
              when={dirty || matricular.alumnoDirty}
              message="¿Desea abandonar la actualización de datos?. Cualquier dato no guardado se perderá."
            />
          </Container>
        )
    );
  }
}

const withSaga = injectSaga({ key: 'alumnoActualizar', saga });

const mapStateToProps = state => ({
  app: state.app,
  matricular: state.matricular,
  dirty: isDirty('alumnoActualizar')(state),
});

export default withSaga(connect(mapStateToProps)(withTranslation('common')(Matricular)));
