/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  GET_CARGOS_USUARIO,
  GET_CARGOS_USUARIO_SUCCESS,
  GET_CARGOS_USUARIO_ERROR,
  BORRAR_CARGO,
  BORRAR_CARGO_SUCCESS,
  BORRAR_CARGO_ERROR,
} from '../actions/cargoActions';

const initialState = {
  loading: false,
  error: false,
  cargosLoaded: false,
  cargos: [],
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case GET_CARGOS_USUARIO:
      draft.loading = true;
      draft.cargosLoaded = false;
      draft.error = false;
      break;
    case GET_CARGOS_USUARIO_SUCCESS:
      draft.cargos = action.cargos;
      draft.cargosLoaded = true;
      draft.loading = false;
      draft.error = null;
      break;
    case GET_CARGOS_USUARIO_ERROR:
      draft.loading = false;
      draft.cargosLoaded = false;
      draft.error = action.error;
      break;
    case BORRAR_CARGO:
      draft.loading = true;
      draft.error = false;
      break;
    case BORRAR_CARGO_SUCCESS:
      draft.loading = false;
      draft.error = null;
      break;
    case BORRAR_CARGO_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return state;
  }
});
