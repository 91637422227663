export const LOAD_MATRICULA = 'LOAD_MATRICULA';
export const LOAD_MATRICULA_SUCCESS = 'LOAD_MATRICULA_SUCCESS';
export const LOAD_MATRICULA_ERROR = 'LOAD_MATRICULA_ERROR';

export function loadMatricula(data) {
  return {
    type: LOAD_MATRICULA,
    apiCall: true,
    data,
  };
}

export function loadMatriculaSuccess(matricula) {
  return {
    type: LOAD_MATRICULA_SUCCESS,
    matricula,
  };
}

export function loadMatriculaError(error) {
  return {
    type: LOAD_MATRICULA_ERROR,
    error,
  };
}
