import React, { PureComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import TablaListado from './components/TablaListado';
import { loadCatalagoReportes, loadListado } from '../../redux/actions/reporteActions';
import saga from './saga';
import injectSaga from '../../utils/injectSaga';
import Panel from '../../shared/components/Panel';
import { CICLO_LECTIVO_A_MATRICULAR_SOLO_ANIO } from '../../utils/constants';

class ReporteListado extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    reporte: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
  };

  constructor() {
    super();
    this.state = {
      nivel: '',
    };
  }

  componentDidMount() {
    const { dispatch, app } = this.props;
    dispatch(loadCatalagoReportes(app.session.id));
  }

  onNivelChange = (nivel) => {
    const { dispatch, app } = this.props;
    this.setState({
      nivel: nivel.value,
    });
    dispatch(loadListado(app.session.id, nivel.value, CICLO_LECTIVO_A_MATRICULAR_SOLO_ANIO));
  }

  render() {
    const {
      t, app, reporte, i18n,
    } = this.props;

    const { nivel } = this.state;

    return (
      (!app.session || Object.keys(reporte.catalogo).length === 0)
        ? <CircularProgress className="circular-progress" />
        : (
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <h3 style={{ display: 'inline' }} className="page-title">{t('Listado de estudiantes matriculados por nivel')}</h3>
              </Col>
            </Row>
            <Row>
              { reporte.loading && <CircularProgress className="circular-progress" />}
              <Panel title={t('Listados por Grado')} lg={12}>
                <Select
                  name="nivel"
                  value={nivel}
                  onChange={this.onNivelChange}
                  options={reporte.catalogo ? [{
                    value: 'todos',
                    label: t('Todos los grados'),
                  }].concat(reporte.catalogo.nivelEscolar.filter(ne => ne.habilitado).map(ne => ({
                    value: ne.es,
                    label: ne[i18n.language],
                  }))) : []}
                  clearable={false}
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder={t('Seleccione un grado...')}
                />
                {
                  nivel
                  && (
                    <h5 style={{ margin: '40px 0px' }}>
                      {t('Listado de')} <span className="bold-text">{reporte.catalogo.nivelEscolar.find(ne => ne.es === nivel) ? reporte.catalogo.nivelEscolar.find(ne => ne.es === nivel)[i18n.language] : t('todos los grados')}</span>
                    </h5>
                  )
                }
                {
                  nivel
                  && <TablaListado language={i18n.language} niveles={reporte.catalogo.nivelEscolar} listado={reporte.listado} />
                }
              </Panel>
            </Row>
          </Container>
        )
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  reporte: state.reporte,
});

const withSaga = injectSaga({ key: 'listado', saga });

export default withSaga(connect(mapStateToProps)(withTranslation('common')(ReporteListado)));
