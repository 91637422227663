import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import MedicineForm from './components/MedicineForm';
import { setStudentAttribute } from '../../../redux/actions/matricularActions';

class Medicine extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, currentValue, onCancel,
    } = this.props;

    const newValues = _.cloneDeep(currentValue);

    const oldValue = _.map(newValues, 'medicamento').indexOf(values.medicamento);
    const newValue = {
      medicamento: values.medicamento,
      tipo: values.tipo.value,
      horario: values.horario,
      seAplicaEnHoraLectiva: values.seAplicaEnHoraLectiva === '1',
    };

    if (oldValue === -1) {
      newValues.push(newValue);
    } else {
      newValues[oldValue] = newValue;
    }
    dispatch(setStudentAttribute({
      attribute: 'medicamentos',
      value: newValues,
    }));

    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      tipoMedicamento,
      language,
      itemId,
      currentValue,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <MedicineForm
              currentValue={currentValue}
              itemId={itemId}
              language={language}
              tipoMedicamento={tipoMedicamento}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

Medicine.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  tipoMedicamento: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  currentValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Medicine.defaultProps = {
  itemId: '',
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(Medicine);
