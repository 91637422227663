import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import AddressForm from './components/AddressForm';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import { saveAddress } from '../../../redux/actions/matricularActions';

class Address extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, nucleoId, app, onCancel,
    } = this.props;

    const newAddress = _.cloneDeep(values);
    newAddress.nucleo = nucleoId;

    const atributosConListado = [
      'direccion',
      'tipoDireccion',
      'provincia',
      'canton',
      'distrito',
    ];

    Object.keys(values).forEach((nombre) => {
      if (atributosConListado.indexOf(nombre) > -1) {
        newAddress[nombre] = values[nombre].value;
      } else {
        newAddress[nombre] = values[nombre];
      }
    });

    const isNew = newAddress.direccion === 'new';
    let addressId = null;
    if (!isNew) {
      addressId = newAddress.direccion;
    }
    delete newAddress.id;
    delete newAddress.direccion;

    dispatch(saveAddress({
      token: app.session.id,
      isNew,
      itemId: addressId,
      address: newAddress,
    }));
    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      tipoDireccion,
      language,
      nucleoDirecciones,
      itemId,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <AddressForm
              itemId={itemId}
              nucleoDirecciones={nucleoDirecciones}
              language={language}
              tipoDireccion={tipoDireccion}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

Address.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  tipoDireccion: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  nucleoId: PropTypes.string.isRequired,
  nucleoDirecciones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
};

Address.defaultProps = {
  itemId: '',
};

const withSaga = injectSaga({ key: 'address', saga });

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(connect(mapStateToProps)(Address));
