import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, ButtonToolbar } from "reactstrap";
import { formValueSelector, reduxForm } from "redux-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {
  ANIO_ACTUAL,
  ANIO_SIGUIENTE,
  CICLO_LECTIVO_A_MATRICULAR,
  ROUTE_NUCLEO,
  ROUTE_STORAGE,
} from "../../../utils/constants";
import createReport from "docx-templates";
import toAPIPath from "../../../utils/locationResolver";
import {
  concatenarNombreAlumno,
  obtenerMesHoyEnEspaniol,
  removeLineBreaks,
} from "../../../utils/stringUtils";
import { NumerosALetras } from "../../../utils/numeroALetras";
import Alert from "../../../shared/components/Alert";

class ComunidadWeizman extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    formValues: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
  };

  constructor() {
    super();
    this.state = {
      loading: false,
      missingData: false,
      missingFields: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  generateContract = async (alumno, token, data) => {
    this.setState({ loading: true });

    try {
      //download the template from https://portal-weizman.s3.amazonaws.com/contrato.docx
      const template = await fetch(
        "https://portal-weizman.s3.amazonaws.com/contrato.docx"
      );
      const templateBuffer = await template.arrayBuffer();

      const nucleoGetUrl = toAPIPath([
        ROUTE_NUCLEO,
        `${alumno.nucleo}?filter=${encodeURIComponent(
          '{"include": [{"relation":"direccion"},{"relation":"encargado"}]}'
        )}&access_token=${token}`,
      ]);

      const nucleoResponse = await fetch(nucleoGetUrl);

      const nucleoData = await nucleoResponse.json();

      const nucleoDireccion = nucleoData.direccion.find(
        (d) => d.id === alumno.direccion
      );

      const nombresPadres = [];
      const emailsPadres = [];
      const rolesPadres = [];
      const cedulasPadres = [];
      const encargado1 = nucleoData.encargado[0]
        ? nucleoData.encargado[0]
        : null;
      const encargado2 = nucleoData.encargado[1]
        ? nucleoData.encargado[1]
        : null;

      const nivelDelEstudiante =
        data.catalogo.nivelEscolar && alumno.nivel
          ? data.catalogo.nivelEscolar.find((n) => n.es === alumno.nivel.value)
          : null;

      if (encargado1) {
        nombresPadres.push(encargado1.nombre);
        emailsPadres.push(encargado1.email);
        rolesPadres.push(encargado1.rol);
        cedulasPadres.push(encargado1.identificacion);
      }
      if (encargado2) {
        nombresPadres.push(encargado2.nombre);
        emailsPadres.push(encargado2.email);
        rolesPadres.push(encargado2.rol);
        cedulasPadres.push(encargado2.identificacion);
      }

      //Check if all the data is available, consider there are falsey values
      if (
        !nucleoDireccion ||
        !nucleoDireccion.senias ||
        !nucleoDireccion.distrito ||
        !nucleoDireccion.canton ||
        !nucleoDireccion.provincia ||
        !nucleoData.encargado ||
        !nucleoData.encargado[0] ||
        !nucleoData.encargado[0].nombre ||
        !nucleoData.encargado[0].rolEncargado ||
        !nucleoData.encargado[0].identificacion ||
        !nucleoData.encargado[0].email ||
        alumno.autorizacionFotosMedios === undefined ||
        alumno.autorizoFotos === undefined
      ) {
        this.setState({
          loading: false,
          missingData: true,
          missingFields: [
            !nucleoDireccion ? "Dirección" : null,
            !nucleoDireccion.senias ? "Señas de la dirección" : null,
            !nucleoDireccion.distrito ? "Distrito de la dirección" : null,
            !nucleoDireccion.canton ? "Cantón de la dirección" : null,
            !nucleoDireccion.provincia ? "Provincia de la dirección" : null,
            !nucleoData.encargado ? "Encargado" : null,
            !nucleoData.encargado[0] ? "Encargado" : null,
            !nucleoData.encargado[0].nombre ? "Nombre del encargado" : null,
            !nucleoData.encargado[0].rolEncargado ? "Rol del encargado" : null,
            !nucleoData.encargado[0].identificacion
              ? "Identificación del encargado"
              : null,
            !nucleoData.encargado[0].email ? "Email del encargado" : null,
            alumno.autorizacionFotosMedios === undefined ? "Autorización de fotos en medios" : null,
            alumno.autorizoFotos === undefined ? "Autorización de fotos" : null,
          ].filter(Boolean),
        });
        return;
      } else {
        this.setState({ missingData: false, missingFields: [] });
      }

      const buffer = await createReport({
        template: templateBuffer,
        data: {
          CICLO_LECTIVO_A_MATRICULAR,
          NOMBRE_PADRES: nombresPadres.join(" y "), //"Juan Pérez y María Gómez",
          CALIDADES_CEDULAS_PADRES: `cédula ${cedulasPadres.join(" y ")}${
            cedulasPadres.length > 1 ? " respectivamente" : ""
          }, con dirección de residencia ${nucleoDireccion.senias} - ${
            nucleoDireccion.distrito
          }, ${nucleoDireccion.canton}, ${nucleoDireccion.provincia}.`,
          NOMBRE_ALUMNO: concatenarNombreAlumno(alumno),
          NIVEL_ALUMNO: alumno.nivel.value,
          MONTO_MATRICULA_LETRAS: `${NumerosALetras(
            nivelDelEstudiante.matricula
          )
            .trim()
            .toLowerCase()}`,
          MONTO_MATRICULA_TOTAL_LETRAS: `${NumerosALetras(
            nivelDelEstudiante.mensualidad * 12
          )
            .trim()
            .toLowerCase()}`,
          MONTO_MATRICULA_NUMEROS: new Intl.NumberFormat("es-CR", {
            style: "currency",
            currency: "CRC",
          })
            .format(nivelDelEstudiante.matricula)
            .replace("₡", ""),
          MONTO_MATRICULA_TOTAL_NUMEROS: new Intl.NumberFormat("es-CR", {
            style: "currency",
            currency: "CRC",
          })
            .format(nivelDelEstudiante.mensualidad * 12)
            .replace("₡", ""),
          MONTO_MENSUALIDAD_NUMEROS: new Intl.NumberFormat("es-CR", {
            style: "currency",
            currency: "CRC",
          })
            .format(nivelDelEstudiante.mensualidad)
            .replace("₡", ""),
          MONTO_MENSUALIDAD_LETRAS: `${NumerosALetras(
            nivelDelEstudiante.mensualidad
          )
            .trim()
            .toLowerCase()}`,
          ANIO_ACTUAL,
          ANIO_SIGUIENTE,
          EMAILS_PADRES: emailsPadres.join(", "), //"
          NOMBRE_ENCARGADO_1: encargado1
            ? removeLineBreaks(encargado1.nombre)
            : "",
          ROL_ENCARGADO_1: encargado1
            ? removeLineBreaks(encargado1.rolEncargado)
            : "",
          NOMBRE_ENCARGADO_2: encargado2
            ? removeLineBreaks(encargado2.nombre)
            : "",
          ROL_ENCARGADO_2: encargado2
            ? removeLineBreaks(encargado2.rolEncargado)
            : "",
          DIA_HOY: new Date().getDate(),
          MES_HOY: obtenerMesHoyEnEspaniol(),
          ANIO_HOY: new Date().getFullYear(),
          AUTORIZO_FOTOS_SI: alumno.autorizoFotos ? "X" : undefined,
          AUTORIZO_FOTOS_MEDIOS_SI: alumno.autorizacionFotosMedios
            ? "X"
            : undefined,
          AUTORIZO_FOTOS_NO: !alumno.autorizoFotos ? "X" : undefined,
          AUTORIZO_FOTOS_MEDIOS_NO: !alumno.autorizacionFotosMedios
            ? "X"
            : undefined,
        },
        cmdDelimiter: ["{{", "}}"],
      });
      const docxBlob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const formData = new FormData();
      formData.append("file", docxBlob, "contrato.docx");

      const storagePath = toAPIPath([
        ROUTE_STORAGE,
        `convertToPDF?access_token=${token}`,
      ]);

      const response = await fetch(storagePath, {
        method: "POST",
        body: formData,
      });

      const pdfUrl = URL.createObjectURL(await response.blob());

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Contrato_firmado.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setState({ loading: false });
    } catch (error) {
      console.error("Error uploading and converting the document:", error);
    }
    this.setState({ loading: false });
  };

  render() {
    const { handleSubmit, t, previousPage, formValues, token, data } =
      this.props;

    return (
      <form
        className="form form--horizontal wizard__form"
        onSubmit={handleSubmit}
      >
        <h3 className="wizard__title">
          {t("CONTRATO SERVICIOS EDUCATIVOS")} {CICLO_LECTIVO_A_MATRICULAR}
        </h3>
        {this.state.missingData && (
          <div style={{width: "100%"}}>
            <Alert visible color="danger">
              <span>
                {t("Faltan datos para generar el contrato")}:{" "}
                {this.state.missingFields.join(", ")}
              </span>
            </Alert>
          </div>
        )}
        {/* eslint-disable-next-line max-len */}
        <p className="wizard__contract-text">
          {t(
            "Para finalizar el proceso de matrícula es necesario descargar y firmar el contrato de servicios educativos. El contrato firmado debe ser entregado de manera física en las oficinas del Instituto Dr. Jaim Weizman."
          )}
          .
        </p>
        <p className="wizard__contract-text">
          {t(
            "Una vez que el contrato sea descargado la matrícula pasará al estado de pendiente y será finalizada en el momento que se reciba el contrato firmado."
          )}
        </p>
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={previousPage}
          >
            {t("Atrás")}
          </Button>
          {formValues.estadoMatricula ===
          "Pendiente Contrato Servicios Educativos" ? (
            <Button
              onClick={() => {
                this.generateContract(formValues, token, data);
              }}
              color="success"
              type="button"
              className="next"
              disabled={this.state.loading}
            >
              {t("Descargar Contrato")}
            </Button>
          ) : (
            <Button
              onClick={() => {
                this.generateContract(formValues, token, data);
                handleSubmit();
              }}
              color="success"
              type="button"
              className="next"
              disabled={this.state.loading}
            >
              {t("Confirmar y Descargar Contrato")}
            </Button>
          )}
          {this.state.loading && (
            <CircularProgress className="circular-progress" />
          )}
        </ButtonToolbar>
      </form>
    );
  }
}

const selector = formValueSelector("matricula");

function mapStateToProps(state) {
  const formValues = selector(
    state,
    "id",
    "nivel",
    "nucleo",
    "nombreAlumno",
    "apellido1",
    "apellido2",
    "estadoMatricula",
    "direccion",
    "autorizoFotos",
    "autorizacionFotosMedios"
  );
  return { formValues };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "matricula", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(withTranslation("common")(ComunidadWeizman))
);
