/* eslint-disable react/no-array-index-key,react/no-typos */
import React from 'react';
import {
  Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Panel from '../../../shared/components/Panel';

const TablaMatricula = ({ t, estadisticas }) => (
  <Panel title={t('Cantidades por Grado')} lg={12}>
    <Table responsive className="table--bordered dashboard__table-crypto">
      <thead>
        <tr>
          <th>{t('Grado')}</th>
          <th>Hombres</th>
          <th>Mujeres</th>
          <th>% Hombres Grado</th>
          <th>% Mujeres Grado</th>
          <th>Total</th>
          <th>% del Total</th>
        </tr>
      </thead>
      <tbody>
        {estadisticas.niveles.map((n, index) => (
          <tr key={index}>
            <td>{n.nivel}</td>
            <td>{n.cantidadHombres}</td>
            <td>{n.cantidadMujeres}</td>
            <td>{n.cantidadHombresPorcentaje}%</td>
            <td>{n.cantidadMujeresPorcentaje}%</td>
            <td className="bold-text">{n.cantidad}</td>
            <td className="bold-text">{n.cantidadPorcentaje}%</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Panel>
);

TablaMatricula.propTypes = {
  t: PropTypes.func.isRequired,
  estadisticas: PropTypes.shape({}).isRequired,
};

export default withTranslation('common')(TablaMatricula);
