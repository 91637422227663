import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { getNucleoData } from '../../../containers/Matricular/saga';
import {
  saveAddressSuccess, SAVE_ADDRESS, setStudentAttribute,
} from '../../../redux/actions/matricularActions';
import { ROUTE_NUCLEO_DIRECCION } from '../../../utils/constants';
import toAPIPath from '../../../utils/locationResolver';

export function* postPutAddress(payload) {
  const { api, data } = payload;
  let addressId;
  try {
    if (data.isNew) {
      const newAddress = yield call(api.post, toAPIPath([`${ROUTE_NUCLEO_DIRECCION}?access_token=${data.token}`]), data.address);
      addressId = newAddress.id;
    } else {
      yield call(api.put, toAPIPath([ROUTE_NUCLEO_DIRECCION, `${data.itemId}?access_token=${data.token}`]), data.address);
      addressId = data.itemId;
    }
    yield call(getNucleoData, {
      api,
      data: {
        token: data.token,
        nucleo: data.address.nucleo,
      },
    });
    yield put(setStudentAttribute({
      attribute: 'direccion',
      value: addressId,
    }));
    yield put(saveAddressSuccess());
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(SAVE_ADDRESS, postPutAddress);
}
