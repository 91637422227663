import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
// import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import LogInForm from './components/LogInForm';
import logoPortal from '../../shared/img/logo/logo_light.svg';
import { startSession, startSessionSuccess } from '../../redux/actions/appActions';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import checkSession from '../../utils/checkSession';
import Alert from '../../shared/components/Alert';
import TopbarLanguage from '../Layout/topbar/TopbarLanguage';

class LogIn extends PureComponent {
  componentWillMount() {
    const { dispatch } = this.props;
    const session = checkSession();
    if (session) {
      dispatch(startSessionSuccess(session));
    }
  }

  handleSumbit = (values) => {
    const { dispatch } = this.props;
    dispatch(startSession(values));
  }

  render() {
    const {
      t,
      app,
    } = this.props;

    return (
      app.session && !app.session.expired
        ? <Redirect to="/matricula" />
        : (
          <div className="account">
            { app.loading && <CircularProgress className="circular-progress" /> }
            {<TopbarLanguage style={{ position: 'fixed', top: '10px', right: '10px' }} />}
            <div className="account__wrapper">
              <div className="account__card">
                {
                  app.session && app.session.expired
                  && (
                    <Alert visible color="danger">
                      <p>
                        <span className="bold-text">{t('Sesión Expirada!')}</span> {t('Debido a la inactividad su sesión ha expirado')}. {t('Ingrese nuevamente')}.
                      </p>
                    </Alert>
                  )
                }
                <img style={{ marginBottom: '20px' }} alt="Logo Portal Weizman" src={logoPortal} />
                <div className="account__head">
                  <h3 className="account__title">{t('Bienvenido a')}
                    <span className="account__logo"> {t('Portal')}
                      <span className="account__logo-accent"> {t('Weizman')}</span>
                    </span>
                  </h3>
                </div>
                <LogInForm
                  loginError={app.error && true}
                  onSubmit={this.handleSumbit}
                />
              </div>
            </div>
          </div>
        )
    );
  }
}

LogIn.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
};

const withSaga = injectSaga({ key: 'login', saga });

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(withTranslation('common')(connect(mapStateToProps)(LogIn)));
