import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { concatenarNombreAlumno } from '../../../utils/stringUtils';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';
// import renderCheckBoxField from '../../../shared/components/form/CheckBox';

class DayCare extends PureComponent {
  static propTypes = {
    formValues: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    enableDraftButton: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    formValues: {
      requieroDayCare: '0',
      nivel: {
        label: '',
        value: '',
      },
    },
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      handleSubmit, t, previousPage, formValues, data, enableDraftButton, onSaveDraft,
    } = this.props;

    const nivelDelEstudiante = data.catalogo.nivelEscolar && formValues.nivel ? data.catalogo.nivelEscolar.find(n => n.es === formValues.nivel.value) : [];
    const mostrarDayCare = Array.isArray(nivelDelEstudiante) || (nivelDelEstudiante && nivelDelEstudiante.orden < 5);

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">{t('Day Care')} {CICLO_LECTIVO_A_MATRICULAR}</h3>
        {
          mostrarDayCare
            ? (
              <React.Fragment>
                <p
                  style={{ marginBottom: '20px', width: '100%' }}
                  className="wizard__contract-text"
                >
                  {t('¿Requiere o no el servicio de Day Care para el alumno')} <span className="bold-text">{concatenarNombreAlumno({ nombreAlumno: formValues.nombreAlumno, apellido1: formValues.apellido1, apellido2: formValues.apellido2 })}</span>?:
                </p>
                <div style={{ width: '100%' }}>
                  <Field
                    label={t('Si requiero el servicio')}
                    name="requieroDayCare"
                    id="requieroDayCare"
                    radioValue="1"
                    component={renderRadioButtonField}
                  />
                  <Field
                    label={t('No requiero el servicio')}
                    name="requieroDayCare"
                    id="requieroDayCare"
                    radioValue="0"
                    component={renderRadioButtonField}
                  />
                </div>
                {
                  formValues.requieroDayCare === '1'
                  && (
                    <div style={{ marginTop: '20px' }}>
                      <p style={{ marginBottom: '20px' }} className="wizard__contract-text">
                        {t('Por este medio confirmo mi interés en el servicio Day care para mi hijo(a)')} <span className="bold-text">{concatenarNombreAlumno({ nombreAlumno: formValues.nombreAlumno, apellido1: formValues.apellido1, apellido2: formValues.apellido2 })}</span> {t('con la siguiente frecuencia')}:
                      </p>
                      <Field
                        id="frecuenciaDayCare"
                        name="frecuenciaDayCare"
                        component={renderRadioButtonField}
                        label={t('MENSUAL ₡100.000')}
                        radioValue="mensual"
                        validate={value => (value ? undefined : t('Obligatorio'))}
                      />
                      <Field
                        id="frecuenciaDayCare"
                        name="frecuenciaDayCare"
                        component={renderRadioButtonField}
                        label={t('DOS-TRES DIAS POR SEMANA ₡50.000')}
                        radioValue="dosTresDias"
                        validate={value => (value ? undefined : t('Obligatorio'))}
                      />
                      <Field
                        id="frecuenciaDayCare"
                        name="frecuenciaDayCare"
                        component={renderRadioButtonField}
                        label={t('UN DIA POR SEMANA ₡25.000')}
                        radioValue="unDia"
                        validate={value => (value ? undefined : t('Obligatorio'))}
                      />
                      <p className="wizard__contract-text">{t('Este servicio cuenta con almuerzo, una merienda en la tarde y variadas propuestas pedagógicas acompañados de la supervisión de una docente de Preescolar')}.</p>
                    </div>
                  )
                }
              </React.Fragment>
            )
            : (
              <p
                style={{ marginBottom: '20px', width: '100%' }}
                className="wizard__contract-text"
              >
                {t('El Day Care no aplica para el grado seleccionado.')}
              </p>
            )
        }
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>{t('Atrás')}</Button>
          {
            enableDraftButton
            && <Button onClick={onSaveDraft} color="primary" type="button" className="next">{t('Guardar Borrador')}</Button>
          }
          <Button color="primary" type="submit" className="next">{t('Guardar y Seguir')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

const selector = formValueSelector('matricula');

function mapStateToProps(state) {
  const formValues = selector(
    state,
    'nombreAlumno',
    'apellido1',
    'apellido2',
    'requieroDayCare',
    'nivel',
  );
  return { formValues };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(DayCare)));
