/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  GET_ALUMNOS_USUARIO,
  GET_ALUMNOS_USUARIO_SUCCESS,
  GET_ALUMNOS_USUARIO_ERROR,
} from '../actions/matriculaActions';

const initialState = {
  loading: false,
  error: false,
  alumnosLoaded: false,
  alumnos: [],
  total: 0,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case GET_ALUMNOS_USUARIO:
      draft.loading = true;
      draft.alumnosLoaded = false;
      draft.error = false;
      break;
    case GET_ALUMNOS_USUARIO_SUCCESS:
      draft.alumnos = action.alumnos;
      draft.total = action.total;
      draft.alumnosLoaded = true;
      draft.loading = false;
      draft.error = null;
      break;
    case GET_ALUMNOS_USUARIO_ERROR:
      draft.loading = false;
      draft.alumnosLoaded = false;
      draft.error = action.error;
      break;
    default:
      return state;
  }
});
