import React from 'react';
import PropTypes from 'prop-types';

const renderSimpleInput = (props) => {
  const {
    input, step, min, max, placeholder, type, meta, containerStyle, help, forceHelp,
  } = props;
  return (
    <div style={{ ...containerStyle }} className="form__form-group-input-wrap">
      <input min={min} max={max} step={step} {...input} placeholder={placeholder} type={type} />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
      {(meta.touched || forceHelp) && help && <span className="form__form-group-help">{help}</span>}
    </div>
  );
};

renderSimpleInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  help: PropTypes.string,
  forceHelp: PropTypes.bool,
  containerStyle: PropTypes.shape({}),
};

renderSimpleInput.defaultProps = {
  meta: null,
  type: 'text',
  placeholder: '',
  step: null,
  min: null,
  max: null,
  containerStyle: {},
  help: '',
  forceHelp: false,
};

export default renderSimpleInput;
