export function concatenarNombreAlumno(alumno) {
  return `${alumno.nombreAlumno ? alumno.nombreAlumno : ''}${alumno.apellido1 ? ` ${alumno.apellido1}` : ''}${alumno.apellido2 ? ` ${alumno.apellido2}` : ''}`;
}

export function concatenarNombreEncargado(encargado) {
  return `${encargado.nombre}${encargado.apellido1 ? ` ${encargado.apellido1}` : ''}${encargado.apellido2 ? ` ${encargado.apellido2}` : ''}`;
}

export function findLabelByLanguage(value, catalogo) {
  return catalogo.find(c => c.es === value);
}

export function obtenerMesHoyEnEspaniol() {
  const meses = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'
  ];
  return meses[new Date().getMonth()];
}

export function removeLineBreaks(text) {
  return text.replace(/(\r\n|\n|\r)/gm, '');
}