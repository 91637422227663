import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, formValueSelector, reduxForm, change, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import renderSelectField from '../../form/Select';
import renderSimpleInput from '../../form/Simple';
import renderTextArea from '../../form/TextArea';

class GenericForm extends PureComponent {
  handleGenericChange = (id) => {
    /* eslint-disable-next-line no-shadow */
    const { change } = this.props;
    if (id !== 'Otra' || id !== 'Otro') {
      change('other', null);
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, formValues, onCancel, catalogo, language, currentValue, incluirDetalle, labelOtroValor,
    } = this.props;

    const newCatalogo = _.cloneDeep(catalogo).filter(c => currentValue.indexOf(c.es) === -1);

    return (
      <form className="form" onSubmit={handleSubmit}>
        {
          newCatalogo.length > 0
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Seleccione un valor')}</span>
              <div className="form__form-group-field">
                <Field
                  name="generic"
                  id="generic"
                  component={renderSelectField}
                  options={newCatalogo.sort((a, b) => {
                    if (a[language] < b[language]) return -1;
                    if (a[language] > b[language]) return 1;
                    return 0;
                  }).map(c => (
                    {
                      label: c[language],
                      value: c.es,
                    }
                  ))
                  }
                  onChange={(value) => { this.handleGenericChange(value.value); }}
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          ((catalogo.length === 0) || (formValues && formValues.generic && (formValues.generic.value === 'Otra' || formValues.generic.value === 'Otro')))
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{labelOtroValor || t('Digite un valor')}</span>
              <div className="form__form-group-field">
                <Field
                  name="other"
                  id="other"
                  component={renderSimpleInput}
                  type="text"
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          incluirDetalle
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Detalle')}</span>
              <div className="form__form-group-field">
                <Field
                  id="detail"
                  name="detail"
                  component={renderTextArea}
                  type="textarea"
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('generic'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

GenericForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  catalogo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  incluirDetalle: PropTypes.bool.isRequired,
  labelOtroValor: PropTypes.string,
};

GenericForm.defaultProps = {
  formValues: {
    generic: {
      value: '',
      label: '',
    },
    other: '',
  },
  labelOtroValor: '',
};

const selector = formValueSelector('generic');

function mapStateToProps(state) {
  const formValues = selector(state,
    'generic', 'other');
  return { formValues };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'generic',
})(withTranslation('common')(GenericForm)));
