import React from 'react';
import PropTypes from 'prop-types';

const renderTextArea = (props) => {
  const {
    input, rows, placeholder, meta,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <textarea {...input} placeholder={placeholder} rows={rows} />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderTextArea.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  rows: PropTypes.number,
  placeholder: PropTypes.string,
};

renderTextArea.defaultProps = {
  meta: null,
  rows: 4,
  placeholder: '',
};

export default renderTextArea;
