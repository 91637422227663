import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import { generate } from 'generate-password';
import { getNucleoData } from '../../../containers/Matricular/saga';
import {
  saveEncargadoSuccess, SAVE_ENCARGADO, setStudentAttribute,
} from '../../../redux/actions/matricularActions';
import { ROUTE_NUCLEO, ROUTE_NUCLEO_TELEFONO, ROUTE_USUARIO } from '../../../utils/constants';
import toAPIPath from '../../../utils/locationResolver';

export function* postPutEncargado(payload) {
  const { api, data } = payload;
  const { encargado, telefonosACrear } = data;
  let encargadoId;
  try {
    let { telefono } = encargado;
    if (telefonosACrear.length > 0) {
      yield all(telefonosACrear.map(t => call(api.post, toAPIPath([`${ROUTE_NUCLEO_TELEFONO}?access_token=${data.token}`]), t)));
    }
    const nucleoTelefonos = yield call(api.get, toAPIPath([ROUTE_NUCLEO, `${encargado.nucleo}?filter=${encodeURIComponent('{"include":{"relation":"telefono"}}')}&access_token=${data.token}`]));
    const nuevosIds = telefonosACrear.map((t) => {
      const nt = nucleoTelefonos.telefono.find(ntTemp => ntTemp.telefono === t.telefono && ntTemp.tipoTelefono === t.tipoTelefono);
      return nt.id;
    });
    telefono = telefono.concat(nuevosIds);
    encargado.telefono = telefono;
    if (data.isNew) {
      encargado.password = generate({
        length: 10,
        numbers: true,
      });
      const newEncargado = yield call(api.post, toAPIPath([`${ROUTE_USUARIO}?access_token=${data.token}`]), encargado);
      encargadoId = newEncargado.id;
    } else {
      yield call(api.patch, toAPIPath([ROUTE_USUARIO, `${data.itemId}?access_token=${data.token}`]), encargado);
      encargadoId = data.itemId;
    }
    yield call(getNucleoData, {
      api,
      data: {
        token: data.token,
        nucleo: data.encargado.nucleo,
      },
    });
    const encargados = yield select(state => state.matricular.encargado);
    const newEncargados = _.cloneDeep(encargados);
    if (newEncargados.indexOf(encargadoId) === -1) {
      newEncargados.push(encargadoId);
    }
    yield put(setStudentAttribute({
      attribute: 'encargado',
      value: newEncargados,
    }));
    yield put(saveEncargadoSuccess());
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(SAVE_ENCARGADO, postPutEncargado);
}
