/* eslint-disable react/no-array-index-key,react/no-typos */
import React from "react";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { CICLO_LECTIVO_A_MATRICULAR } from "../../../utils/constants";

// eslint-disable-next-line
const Moment = require("moment");

const TablaMatricula = ({ t, listado }) => (
  <Table responsive className="table--bordered dashboard__table-crypto">
    <thead>
      <tr>
        <th>#</th>
        <th>{t("Grado")}</th>
        <th>{t("Primer Apellido")}</th>
        <th>{t("Segundo Apellido")}</th>
        <th>{t("Nombre")}</th>
        <th>{t("Género")}</th>
        <th>{t("Tipo de Estudiante")}</th>
        <th>{t("Identificación")}</th>
        <th>{t("Estado")}</th>
        <th>{t("Fecha Matrícula")}</th>
      </tr>
    </thead>
    <tbody>
      {listado.map((n, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{n.nivel ? n.nivel.trim().toUpperCase() : ""}</td>
          <td>{n.apellido1 ? n.apellido1.trim().toUpperCase() : ""}</td>
          <td>{n.apellido2 ? n.apellido2.trim().toUpperCase() : ""}</td>
          <td>{n.nombreAlumno ? n.nombreAlumno.trim().toUpperCase() : ""}</td>
          <td>{n.sexo ? n.sexo.trim().toUpperCase() : ""}</td>
          <td>{n.nuevoIngreso ? t("NUEVO INGRESO") : t("REGULAR")}</td>
          <td>
            {n.identificacion
              ? n.identificacion.replace(/-/g, "").trim().toUpperCase()
              : ""}
          </td>
          <td>
            {n.estadoMatricula
              ? n.estadoMatricula
                  .trim()
                  .toUpperCase()
                  .replace(" SERVICIOS EDUCATIVOS", "")
                  .replace(` ${CICLO_LECTIVO_A_MATRICULAR}`, "")
              : ""}
          </td>
          <td>
            {n.fechaMatricula
              ? new Moment(n.fechaMatricula).format("DD-MM-YYYY HH:MM:SS")
              : ""}
          </td>
        </tr>
      ))}
      {listado.length === 0 && (
        <tr>
          <td>{t("No hay alumnos registrados")}</td>
        </tr>
      )}
    </tbody>
  </Table>
);

TablaMatricula.propTypes = {
  t: PropTypes.func.isRequired,
  listado: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withTranslation("common")(TablaMatricula);
