import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  forgotPasswordError,
  forgotPasswordSuccess,
  FORGOT_PASSWORD,
} from '../../redux/actions/appActions';
import { ROUTE_USUARIO } from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* forgotPassword(payload) {
  const { api, email } = payload;
  try {
    yield call(api.post, toAPIPath([ROUTE_USUARIO, 'reset']), {
      email,
    });
    yield put(forgotPasswordSuccess());
  } catch (err) {
    yield put(forgotPasswordError(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}
