import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  loadMatriculaSuccess,
  LOAD_MATRICULA,
} from '../../redux/actions/matriculaVerActions';
import {
  ROUTE_MATRICULA_DAY_CARE,
} from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* getMatricula(payload) {
  const { api, data } = payload;
  try {
    const matricula = yield call(api.get, toAPIPath([ROUTE_MATRICULA_DAY_CARE, `findOne?filter=${encodeURIComponent(`{"where": { "alumno": {"like": "${data.id}" }}, "order": "fecha DESC"}`)}&access_token=${data.token}`]));
    yield put(loadMatriculaSuccess(matricula));
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_MATRICULA, getMatricula);
}
