import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './containers/App/App';
import store from './redux/createStore';

Sentry.init({
  dsn: 'https://2a27f5e3bc9c4d8a835ad99dd34f75c6@o475901.ingest.sentry.io/5558279',
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
