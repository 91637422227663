import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, Col, Container, Row,
} from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import {
  loadMatricula,
} from '../../redux/actions/matriculaVerActions';
import ComunidadWeizman from './components/ComunidadWeizman';

// eslint-disable-next-line
const Moment = require("moment");

class MatriculaVer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    matriculaVer: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      calledLoad: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  loadData = () => {
    const { app, dispatch, match } = this.props;
    const { calledLoad } = this.state;
    if (!calledLoad && app.session) {
      dispatch(loadMatricula({
        token: app.session.id,
        id: match.params.id,
      }));
      this.setState({
        calledLoad: true,
      });
    }
  }

  render() {
    const {
      t, matriculaVer, app,
    } = this.props;

    return (
      ((!matriculaVer.matricula.id) || !app.session || matriculaVer.loading)
        ? <CircularProgress className="circular-progress" />
        : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{t('Constancia de matrícula')}</h3>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12}>
                <Card>
                  <div className="wizard">
                    <div className="wizard__form-wrapper">
                      <ComunidadWeizman
                        data={matriculaVer.matricula}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        )
    );
  }
}

const withSaga = injectSaga({ key: 'matriculaVer', saga });

const mapStateToProps = state => ({
  app: state.app,
  matriculaVer: state.matriculaVer,
});

export default withSaga(connect(mapStateToProps)(withTranslation('common')(MatriculaVer)));
