import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, loginError, t } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Correo Electrónico')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              id="username"
              component="input"
              type="text"
              placeholder={t('ingrese su correo electrónico')}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Contraseña')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              id="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('ingrese su contraseña')}
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/olvido-contrasenia">{t('Olvidó su contraseña')}</a>
          </div>
        </div>
        {
          loginError
          && (
            <div className="form__form-group">
              <p className="form__form-login-error">{t('Correo electrónico o contraseña incorrecta')}</p>
            </div>
          )
        }
        <button
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary account__btn account__btn--small"
        >
          {t('Ingresar')}
        </button>
      </form>
    );
  }
}

LogInForm.propTypes = {
  t: PropTypes.func.isRequired,
  loginError: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

LogInForm.defaultProps = {
  loginError: false,
};

export default withTranslation('common')(reduxForm({
  form: 'login',
})(LogInForm));
