/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  LOAD_ESTADISTICAS,
  LOAD_ESTADISTICAS_SUCCESS,
  LOAD_ESTADISTICAS_ERROR,
  LOAD_ESTADISTICAS_NUEVOS,
  LOAD_ESTADISTICAS_NUEVOS_SUCCESS,
  LOAD_ESTADISTICAS_NUEVOS_ERROR,
  LOAD_LISTADO,
  LOAD_LISTADO_ERROR,
  LOAD_LISTADO_SUCCESS,
  LOAD_CATALOGO_REPORTES,
  LOAD_CATALOGO_REPORTES_SUCCESS,
  LOAD_CAJAS,
  LOAD_CAJAS_ERROR,
  LOAD_CAJAS_SUCCESS,
} from '../actions/reporteActions';

const initialState = {
  dataLoaded: false,
  stats: {},
  statsNuevos: {},
  catalogo: {},
  listado: [],
  cajas: [],
  totalCajas: 0,
  error: false,
  loading: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_ESTADISTICAS:
    case LOAD_ESTADISTICAS_NUEVOS:
    case LOAD_LISTADO:
    case LOAD_CATALOGO_REPORTES:
    case LOAD_CAJAS:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_ESTADISTICAS_SUCCESS:
      draft.stats = action.stats;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_ESTADISTICAS_NUEVOS_SUCCESS:
      draft.statsNuevos = action.stats;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_LISTADO_SUCCESS:
      draft.listado = action.listado;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_CAJAS_SUCCESS:
      draft.cajas = action.cajas;
      draft.totalCajas = action.totalCajas;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_REPORTES_SUCCESS:
      draft.catalogo = action.catalogo;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_ESTADISTICAS_ERROR:
    case LOAD_ESTADISTICAS_NUEVOS_ERROR:
    case LOAD_LISTADO_ERROR:
    case LOAD_CAJAS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return state;
  }
});
