/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  LOAD_CATALOGO,
  LOAD_CATALOGO_SUCCESS,
  LOAD_CATALOGO_ERROR,
  LOAD_ALUMNO,
  LOAD_ALUMNO_SUCCESS,
  LOAD_ALUMNO_ERROR,
  LOAD_DATA_NUCLEO,
  LOAD_DATA_NUCLEO_SUCCESS,
  LOAD_DATA_NUCLEO_ERROR,
  SAVE_ADDRESS,
  SAVE_ADDRESS_SUCCESS,
  SAVE_ADDRESS_ERROR,
  SET_STUDENT_ATTRIBUTE,
  DESTROY_MATRICULAR_FORM,
  SAVE_PHONE,
  SAVE_PHONE_SUCCESS,
  SAVE_PHONE_ERROR,
  SAVE_DOCTOR,
  SAVE_DOCTOR_SUCCESS,
  SAVE_DOCTOR_ERROR,
  SAVE_ENCARGADO,
  SAVE_ENCARGADO_SUCCESS,
  SAVE_ENCARGADO_ERROR,
  PROCESAR_MATIRCULA,
  PROCESAR_MATIRCULA_ERROR,
  PROCESAR_MATIRCULA_SUCCESS,
  SAVE_DRAFT,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_ERROR,
} from '../actions/matricularActions';

const initialState = {
  dataLoaded: false,
  nucleo: {
    id: '',
    telefono: [],
    direccion: [],
    medico: [],
    encargado: [],
    alumno: [],
  },
  catalogo: {
    nivelEscolar: [],
    nacionalidad: [],
    sexo: [],
    tipoSangre: [],
    tipoTelefono: [],
    tipoIdentificacion: [],
    tipoDireccion: [],
    estadoCivil: [],
    rolEncargado: [],
    alergias: [],
    intolerancias: [],
    problemasGastrointestinales: [],
    tipoMedicamento: [],
    antecedentes: [],
    parentesco: [],
  },
  alumno: {
    nucleo: '',
    nivel: '',
    estadoMatricula: '',
    nombreAlumno: '',
    apellido1: '',
    apellido2: '',
    nombreAlumnoHebreo: '',
    nacionalidad: '',
    sexo: '',
    tipoIdentificacion: '',
    identificacion: '',
    fechaNacimiento: '',
    tipoSangre: '',
    peso: null,
    diabetesHiperinsulinemia: null,
    problemaHepatico: null,
    comentarioDieta: '',
    puedeAsistirAFisica: null,
    causasObervacionesFisica: null,
    puedeAsistirAGiras: null,
    causasObervacionesGiras: null,
    puedeAsistirAExpediciones: null,
    causasObervacionesExpediciones: null,
    prioridadEmergenciaPadres: null,
    prioridadEmergenciaEmergencias: null,
    prioridadEmergenciaMedico: null,
    dayCare: null,
  },
  dietaEspecial: null,
  direccion: '',
  contactoEmergencia: '',
  medicoCabecera: '',
  bitacora: [],
  telefono: [],
  encargado: [],
  alergiasAlimenticias: [],
  intoleranciasAlimenticias: [],
  problemasGastrointestinales: [],
  padecimientos: [],
  medicamentos: [],
  antecedentes: [],
  alergiasMedicamentos: [],
  limitacionesFisicas: [],
  alumnoDirty: false,
  error: false,
  loading: false,
  matriculaExitosa: false,
  notNewAnymore: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_CATALOGO:
      draft.loading = true;
      draft.dataLoaded = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_SUCCESS:
      draft.catalogo = action.catalogo;
      draft.dataLoaded = true;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_CATALOGO_ERROR:
      draft.loading = false;
      draft.dataLoaded = false;
      draft.error = action.error;
      break;
    case LOAD_ALUMNO:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_ALUMNO_SUCCESS:
      draft.alumno = action.alumno;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_ALUMNO_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case LOAD_DATA_NUCLEO:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_DATA_NUCLEO_SUCCESS:
      draft.nucleo = action.nucleoData;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_DATA_NUCLEO_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case SAVE_ADDRESS:
    case SAVE_PHONE:
    case SAVE_DOCTOR:
    case SAVE_ENCARGADO:
    case PROCESAR_MATIRCULA:
    case SAVE_DRAFT:
      draft.loading = true;
      draft.error = false;
      break;
    case SAVE_ADDRESS_SUCCESS:
    case SAVE_PHONE_SUCCESS:
    case SAVE_DOCTOR_SUCCESS:
    case SAVE_ENCARGADO_SUCCESS:
      draft.loading = false;
      draft.error = false;
      break;
    case PROCESAR_MATIRCULA_SUCCESS:
      draft.matriculaExitosa = true;
      draft.loading = false;
      draft.error = false;
      break;
    case SAVE_DRAFT_SUCCESS:
      draft.alumnoDirty = false;
      draft.notNewAnymore = true;
      draft.alumno.id = action.alumnoId;
      draft.loading = false;
      draft.error = false;
      break;
    case SAVE_ADDRESS_ERROR:
    case SAVE_PHONE_ERROR:
    case SAVE_DOCTOR_ERROR:
    case SAVE_ENCARGADO_ERROR:
    case PROCESAR_MATIRCULA_ERROR:
    case SAVE_DRAFT_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case SET_STUDENT_ATTRIBUTE:
      draft[action.data.attribute] = action.data.value;
      draft.alumnoDirty = !action.firstLoad;
      break;
    case DESTROY_MATRICULAR_FORM:
      draft.alumno = initialState.alumno;
      draft.alumnoDirty = false;
      draft.notNewAnymore = false;
      Object.keys(initialState).forEach(k => draft[k] = initialState[k]);
      break;
    default:
      return state;
  }
});
