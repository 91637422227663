export const LOAD_CATALOGO = 'LOAD_CATALOGO';
export const LOAD_CATALOGO_SUCCESS = 'LOAD_CATALOGO_SUCCESS';
export const LOAD_CATALOGO_ERROR = 'LOAD_CATALOGO_ERROR';

export const LOAD_ALUMNO = 'LOAD_ALUMNO';
export const LOAD_ALUMNO_SUCCESS = 'LOAD_ALUMNO_SUCCESS';
export const LOAD_ALUMNO_ERROR = 'LOAD_ALUMNO_ERROR';

export const SAVE_FILE = 'SAVE_FILE';

export const LOAD_DATA_NUCLEO = 'LOAD_DATA_NUCLEO';
export const LOAD_DATA_NUCLEO_SUCCESS = 'LOAD_DATA_NUCLEO_SUCCESS';
export const LOAD_DATA_NUCLEO_ERROR = 'LOAD_DATA_NUCLEO_ERROR';

export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const SAVE_ADDRESS_SUCCESS = 'SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_ERROR = 'SAVE_ADDRESS_ERROR';

export const SAVE_PHONE = 'SAVE_PHONE';
export const SAVE_PHONE_SUCCESS = 'SAVE_PHONE_SUCCESS';
export const SAVE_PHONE_ERROR = 'SAVE_PHONE_ERROR';

export const SAVE_DOCTOR = 'SAVE_DOCTOR';
export const SAVE_DOCTOR_SUCCESS = 'SAVE_DOCTOR_SUCCESS';
export const SAVE_DOCTOR_ERROR = 'SAVE_DOCTOR_ERROR';

export const SAVE_ENCARGADO = 'SAVE_ENCARGADO';
export const SAVE_ENCARGADO_SUCCESS = 'SAVE_ENCARGADO_SUCCESS';
export const SAVE_ENCARGADO_ERROR = 'SAVE_ENCARGADO_ERROR';

export const SET_STUDENT_ATTRIBUTE = 'SET_STUDENT_ATTRIBUTE';

export const DESTROY_MATRICULAR_FORM = 'DESTROY_MATRICULAR_FORM';

export const PROCESAR_MATIRCULA = 'PROCESAR_MATRICULA';
export const PROCESAR_MATIRCULA_SUCCESS = 'PROCESAR_MATIRCULA_SUCCESS';
export const PROCESAR_MATIRCULA_ERROR = 'PROCESAR_MATIRCULA_ERROR';

export const SAVE_DRAFT = 'SAVE_DRAFT';
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS';
export const SAVE_DRAFT_ERROR = 'SAVE_DRAFT_ERROR';

export function loadCatalogo(token, nucleo, isAdmin, alumnoId) {
  return {
    type: LOAD_CATALOGO,
    apiCall: true,
    token,
    nucleo,
    isAdmin,
    alumnoId,
  };
}

export function loadCatalogoSuccess(catalogo) {
  return {
    type: LOAD_CATALOGO_SUCCESS,
    catalogo,
  };
}

export function loadCatalogoError(error) {
  return {
    type: LOAD_CATALOGO_ERROR,
    error,
  };
}

export function loadAlumno(data) {
  return {
    type: LOAD_ALUMNO,
    apiCall: true,
    data,
  };
}

export function loadAlumnoSuccess(alumno) {
  return {
    type: LOAD_ALUMNO_SUCCESS,
    alumno,
  };
}

export function loadAlumnoError(error) {
  return {
    type: LOAD_ALUMNO_ERROR,
    error,
  };
}

export function saveFile(file, name, token) {
  return {
    type: SAVE_FILE,
    apiCall: true,
    file,
    name,
    token,
  };
}

export function loadDataNucleo(data) {
  return {
    type: LOAD_DATA_NUCLEO,
    apiCall: true,
    data,
  };
}

export function loadDataNucleoSuccess(nucleoData) {
  return {
    type: LOAD_DATA_NUCLEO_SUCCESS,
    nucleoData,
  };
}

export function loadDataNucleoError(error) {
  return {
    type: LOAD_DATA_NUCLEO_ERROR,
    error,
  };
}

export function saveAddress(data) {
  return {
    type: SAVE_ADDRESS,
    apiCall: true,
    data,
  };
}

export function saveAddressSuccess() {
  return {
    type: SAVE_ADDRESS_SUCCESS,
  };
}

export function saveAddressError(error) {
  return {
    type: SAVE_ADDRESS_ERROR,
    error,
  };
}

export function setStudentAttribute(data, firstLoad) {
  return {
    type: SET_STUDENT_ATTRIBUTE,
    data,
    firstLoad,
  };
}

export function destroyForm(form) {
  return {
    type: DESTROY_MATRICULAR_FORM,
    form,
  };
}

export function savePhone(data) {
  return {
    type: SAVE_PHONE,
    apiCall: true,
    data,
  };
}

export function savePhoneSuccess() {
  return {
    type: SAVE_PHONE_SUCCESS,
  };
}

export function savePhoneError(error) {
  return {
    type: SAVE_PHONE_ERROR,
    error,
  };
}

export function saveDoctor(data) {
  return {
    type: SAVE_DOCTOR,
    apiCall: true,
    data,
  };
}

export function saveDoctorSuccess() {
  return {
    type: SAVE_DOCTOR_SUCCESS,
  };
}

export function saveDoctorError(error) {
  return {
    type: SAVE_DOCTOR_ERROR,
    error,
  };
}

export function saveEncargado(data) {
  return {
    type: SAVE_ENCARGADO,
    apiCall: true,
    data,
  };
}

export function saveEncargadoSuccess() {
  return {
    type: SAVE_ENCARGADO_SUCCESS,
  };
}

export function saveEncargadoError(error) {
  return {
    type: SAVE_ENCARGADO_ERROR,
    error,
  };
}

export function procesarMatricula(data) {
  return {
    type: PROCESAR_MATIRCULA,
    apiCall: true,
    data,
  };
}

export function procesarMatriculaSuccess() {
  return {
    type: PROCESAR_MATIRCULA_SUCCESS,
  };
}

export function procesarMatriculaError(error) {
  return {
    type: PROCESAR_MATIRCULA_ERROR,
    error,
  };
}

export function saveDraft(data) {
  return {
    type: SAVE_DRAFT,
    apiCall: true,
    data,
  };
}

export function saveDraftSuccess(alumnoId) {
  return {
    type: SAVE_DRAFT_SUCCESS,
    alumnoId,
  };
}

export function saveDraftError(error) {
  return {
    type: SAVE_DRAFT_ERROR,
    error,
  };
}
