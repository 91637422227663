/* eslint-disable no-param-reassign, consistent-return, no-return-assign */

import produce from 'immer';

import {
  LOAD_MATRICULA_DAY_CARE,
  LOAD_MATRICULA_DAY_CARE_SUCCESS,
  LOAD_MATRICULA_DAY_CARE_ERROR,
} from '../actions/matriculaDayCareVerActions';

const initialState = {
  dataLoaded: false,
  matricula: {
    usuario: '',
    alumno: '',
    nucleo: '',
    cicloLectivo: null,
    frecuencia: '',
    nombreAlumno: '',
    encargado: [],
    inicialesEncargado: [],
    fecha: '',
    formulario: '',
    id: '',
  },
  error: false,
  loading: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_MATRICULA_DAY_CARE:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_MATRICULA_DAY_CARE_SUCCESS:
      draft.matricula = action.matricula;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_MATRICULA_DAY_CARE_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return state;
  }
});
