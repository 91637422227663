export const LOAD_CATALOGO_NUEVA_MATRICULA = 'LOAD_CATALOGO_NUEVA_MATRICULA';
export const LOAD_CATALOGO_NUEVA_MATRICULA_SUCCESS = 'LOAD_CATALOGO_NUEVA_MATRICULA_SUCCESS';
export const LOAD_CATALOGO_NUEVA_MATRICULA_ERROR = 'LOAD_CATALOGO_NUEVA_MATRICULA_ERROR';

export const DESTROY_NUEVA_MATRICULA_FORM = 'DESTROY_NUEVA_MATRICULA_FORM';

export const PROCESAR_NUEVA_MATRICULA = 'PROCESAR_NUEVA_MATRICULA';
export const PROCESAR_NUEVA_MATRICULA_SUCCESS = 'PROCESAR_NUEVA_MATRICULA_SUCCESS';
export const PROCESAR_NUEVA_MATRICULA_ERROR = 'PROCESAR_NUEVA_MATRICULA_ERROR';

export function loadCatalogo(token, nucleo, isAdmin, alumnoId) {
  return {
    type: LOAD_CATALOGO_NUEVA_MATRICULA,
    apiCall: true,
    token,
    nucleo,
    isAdmin,
    alumnoId,
  };
}

export function loadCatalogoSuccess(catalogo) {
  return {
    type: LOAD_CATALOGO_NUEVA_MATRICULA_SUCCESS,
    catalogo,
  };
}

export function loadCatalogoError(error) {
  return {
    type: LOAD_CATALOGO_NUEVA_MATRICULA_ERROR,
    error,
  };
}

export function destroyForm(form) {
  return {
    type: DESTROY_NUEVA_MATRICULA_FORM,
    form,
  };
}

export function procesarNuevaMatricula(data) {
  return {
    type: PROCESAR_NUEVA_MATRICULA,
    apiCall: true,
    data,
  };
}

export function procesarNuevaMatriculaSuccess() {
  return {
    type: PROCESAR_NUEVA_MATRICULA_SUCCESS,
  };
}

export function procesarNuevaMatriculaError(error) {
  return {
    type: PROCESAR_NUEVA_MATRICULA_ERROR,
    error,
  };
}
