export const LOAD_MATRICULA_DAY_CARE = 'LOAD_MATRICULA_DAY_CARE';
export const LOAD_MATRICULA_DAY_CARE_SUCCESS = 'LOAD_MATRICULA_DAY_CARE_SUCCESS';
export const LOAD_MATRICULA_DAY_CARE_ERROR = 'LOAD_MATRICULA_DAY_CARE_ERROR';

export function loadMatricula(data) {
  return {
    type: LOAD_MATRICULA_DAY_CARE,
    apiCall: true,
    data,
  };
}

export function loadMatriculaSuccess(matricula) {
  return {
    type: LOAD_MATRICULA_DAY_CARE_SUCCESS,
    matricula,
  };
}

export function loadMatriculaError(error) {
  return {
    type: LOAD_MATRICULA_DAY_CARE_ERROR,
    error,
  };
}
