import {
  combineReducers, createStore, applyMiddleware, compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducer as reduxFormReducer } from 'redux-form';
import apiMiddleware from '../utils/apiMiddleware';
import {
  appReducer,
  matriculaReducer,
  matricularReducer,
  nuevaMatriculaReducer,
  cargoReducer,
  cargoCrearReducer,
  matriculaVerReducer,
  matriculaDayCareVerReducer,
  reporteReducer,
} from './reducers/index';
import { EXPIRE_SESSION } from './actions/appActions';

// create the saga middleware
const composeEnhancers = compose;
/* eslint-disable no-use-before-define */
const reduxSagaMonitorOptions = {
  onError: (e) => {
    if (e.message === 'Request failed with status code 401') {
      store.dispatch({ type: EXPIRE_SESSION });
    } else {
      /* eslint-disable-next-line */
      console.error('AN ERROR OCURRED IN SAGAS: ', e);
    }
  },
};

const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

// Create the store with two middlewares
// 1. sagaMiddleware: Makes redux-sagas work
// 2. routerMiddleware: Syncs the location/URL path to the state
const middlewares = [
  apiMiddleware(),
  sagaMiddleware,
];
const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(
  combineReducers({
    form: reduxFormReducer,
    app: appReducer,
    matricula: matriculaReducer,
    matriculaVer: matriculaVerReducer,
    matriculaDayCare: matriculaDayCareVerReducer,
    matricular: matricularReducer,
    nuevaMatricula: nuevaMatriculaReducer,
    cargo: cargoReducer,
    cargoCrear: cargoCrearReducer,
    reporte: reporteReducer,
  }),
  {},
  composeEnhancers(...enhancers),
);

store.runSaga = sagaMiddleware.run;
store.injectedReducers = {}; // Reducer registry
store.injectedSagas = {}; // Saga registry

if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(combineReducers({
      form: reduxFormReducer,
      app: appReducer,
      matricula: matriculaReducer,
      matriculaVer: matriculaVerReducer,
      matricular: matricularReducer,
      nuevaMatricula: nuevaMatriculaReducer,
      cargo: cargoReducer,
      cargoCrear: cargoCrearReducer,
      reporte: reporteReducer,
    }));
  });
}

export default store;
