import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  loadListadoSuccess, LOAD_LISTADO, LOAD_CATALOGO_REPORTES, loadCatalagoReportesSuccess,
} from '../../redux/actions/reporteActions';
import {
  ROUTE_CATALOGO,
  ROUTE_MATRICULA,
} from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* getListado(payload) {
  const {
    api, anio, nivel, token,
  } = payload;
  try {
    const listado = yield call(api.get, toAPIPath([ROUTE_MATRICULA, 'listado', anio, `${nivel}?access_token=${token}`]));
    yield put(loadListadoSuccess(listado));
  } catch (err) {
    throw err;
  }
}


export function* getCatalogo(payload) {
  const { api, token } = payload;
  try {
    const catalogo = yield call(api.get, toAPIPath([ROUTE_CATALOGO, `findOne?access_token=${token}`]));
    yield put(loadCatalagoReportesSuccess(catalogo));
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_LISTADO, getListado);
  yield takeLatest(LOAD_CATALOGO_REPORTES, getCatalogo);
}
