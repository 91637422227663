import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  getCargosUsuarioSuccess, GET_CARGOS_USUARIO, BORRAR_CARGO,
} from '../../redux/actions/cargoActions';
import { ROUTE_CARGO_AUTOMATICO, ROUTE_NUCLEO } from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* loadCargos(payload) {
  const { api, userData } = payload;
  try {
    const user = yield call(api.get, toAPIPath([ROUTE_NUCLEO, `${userData.nucleo}?filter=${encodeURIComponent('{"include": [{"relation":"cargoAutomatico"}]}')}&access_token=${userData.token}`]));
    yield put(getCargosUsuarioSuccess(user.cargoAutomatico));
  } catch (err) {
    throw err;
  }
}

export function* deleteCargo(payload) {
  const { api, userData, id } = payload;
  try {
    yield call(api.delete, toAPIPath([ROUTE_CARGO_AUTOMATICO, `${id}?access_token=${userData.token}`]));
    yield call(loadCargos, {
      api, userData,
    });
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_CARGOS_USUARIO, loadCargos);
  yield takeLatest(BORRAR_CARGO, deleteCargo);
}
