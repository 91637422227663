import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import renderSimpleInput from '../../../shared/components/form/Simple';


class ChangePasswordForm extends PureComponent {
  render() {
    const { t, handleSubmit } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Nueva Contraseña')}</span>
          <div className="form__form-group-field">
            <Field
              id="newPassword"
              name="newPassword"
              component={renderSimpleInput}
              type="password"
              placeholder={t('ingrese su nueva contraseña')}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Confirmar Contraseña')}</span>
          <div className="form__form-group-field">
            <Field
              id="confirmPassword"
              name="confirmPassword"
              component={renderSimpleInput}
              type="password"
              placeholder={t('confirme su nueva contraseña')}
            />
          </div>
        </div>
        <button
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary account__btn account__btn--small"
        >
          {t('Cambiar contraseña')}
        </button>
      </form>
    );
  }
}

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withTranslation('common')(reduxForm({
  form: 'setNewPassword',
})(ChangePasswordForm));
