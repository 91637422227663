export const LOAD_CATALOGO_CARGO = 'LOAD_CATALOGO_CARGO';
export const LOAD_CATALOGO_CARGO_SUCCESS = 'LOAD_CATALOGO_CARGO_SUCCESS';
export const LOAD_CATALOGO_CARGO_ERROR = 'LOAD_CATALOGO_CARGO_ERROR';

export const LOAD_CARGO = 'LOAD_CARGO';
export const LOAD_CARGO_SUCCESS = 'LOAD_CARGO_SUCCESS';
export const LOAD_CARGO_ERROR = 'LOAD_CARGO_ERROR';

export const LOAD_DATA_NUCLEO_CARGO = 'LOAD_DATA_NUCLEO_CARGO';
export const LOAD_DATA_NUCLEO_CARGO_SUCCESS = 'LOAD_DATA_NUCLEO_CARGO_SUCCESS';
export const LOAD_DATA_NUCLEO_CARGO_ERROR = 'LOAD_DATA_NUCLEO_CARGO_ERROR';

export const SET_CARGO_ATTRIBUTE = 'SET_CARGO_ATTRIBUTE';

export const DESTROY_MATRICULAR_CARGO_FORM = 'DESTROY_MATRICULAR_CARGO_FORM';

export const PROCESAR_CARGO = 'PROCESAR_CARGO';
export const PROCESAR_CARGO_SUCCESS = 'PROCESAR_CARGO_SUCCESS';
export const PROCESAR_CARGO_ERROR = 'PROCESAR_CARGO_ERROR';

export function loadCatalogo(token, nucleo) {
  return {
    type: LOAD_CATALOGO_CARGO,
    apiCall: true,
    token,
    nucleo,
  };
}

export function loadCatalogoSuccess(catalogo) {
  return {
    type: LOAD_CATALOGO_CARGO_SUCCESS,
    catalogo,
  };
}

export function loadCatalogoError(error) {
  return {
    type: LOAD_CATALOGO_CARGO_ERROR,
    error,
  };
}

export function loadCargo(data) {
  return {
    type: LOAD_CARGO,
    apiCall: true,
    data,
  };
}

export function loadCargoSuccess(alumno) {
  return {
    type: LOAD_CARGO_SUCCESS,
    alumno,
  };
}

export function loadCargoError(error) {
  return {
    type: LOAD_CARGO_ERROR,
    error,
  };
}

export function loadDataNucleo(data) {
  return {
    type: LOAD_DATA_NUCLEO_CARGO,
    apiCall: true,
    data,
  };
}

export function loadDataNucleoSuccess(nucleoData) {
  return {
    type: LOAD_DATA_NUCLEO_CARGO_SUCCESS,
    nucleoData,
  };
}

export function loadDataNucleoError(error) {
  return {
    type: LOAD_DATA_NUCLEO_CARGO_ERROR,
    error,
  };
}

export function setCargoAttribute(data) {
  return {
    type: SET_CARGO_ATTRIBUTE,
    data,
  };
}

export function destroyMatricularForm() {
  return {
    type: DESTROY_MATRICULAR_CARGO_FORM,
  };
}

export function procesarCargo(data) {
  return {
    type: PROCESAR_CARGO,
    apiCall: true,
    data,
  };
}

export function procesarCargoSuccess() {
  return {
    type: PROCESAR_CARGO_SUCCESS,
  };
}

export function procesarCargoError(error) {
  return {
    type: PROCESAR_CARGO_ERROR,
    error,
  };
}
