import React from 'react';
import PropTypes from 'prop-types';

const PasoBoton = (props) => {
  const {
    label,
    page,
    onClick,
    active,
    disabled,
  } = props;
  return (
    <div
      style={{ cursor: 'pointer' }}
      tabIndex={0}
      role="button"
      onClick={() => { onClick(page); }}
      onKeyDown={() => { onClick(page); }}
      className={`wizard__step${active ? ' wizard__step--active' : ''}${disabled ? ' wizard__step-disabled' : ''}`}
    >
      <p>{label}</p>
    </div>
  );
};

PasoBoton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PasoBoton;
