import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { loadEstadisticasSuccess, LOAD_ESTADISTICAS } from '../../redux/actions/reporteActions';
import {
  ROUTE_MATRICULA,
} from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* getStats(payload) {
  const { api, anio, token } = payload;
  try {
    const stats = yield call(api.get, toAPIPath([ROUTE_MATRICULA, 'estadisticas', `${anio}?access_token=${token}`]));
    yield put(loadEstadisticasSuccess(stats));
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_ESTADISTICAS, getStats);
}
