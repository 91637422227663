import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Col, Card, Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import renderSelectField from '../../../shared/components/form/Select';
import renderSimpleInput from '../../../shared/components/form/Simple';

class DatosGenerales extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    language: PropTypes.string.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  closeModal = (modalName) => {
    const { openedModal } = this.state;
    if (modalName === openedModal) {
      this.setState({
        openedModal: null,
      });
    }
  }

  render() {
    // onSetStudentAttribute,
    const {
      handleSubmit, t, language, data,
    } = this.props;
    const nivelesDisponibles = data.catalogo.nivelEscolar;

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div style={{
              backgroundColor: '#FFF',
              padding: '10px',
            }}
            >
              <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
                <h3 className="wizard__title">{t('Datos para el nuevo alumno')}</h3>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Grado por matricular')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="nivel"
                      id="nivel"
                      component={renderSelectField}
                      options={_.sortBy(nivelesDisponibles, 'orden').filter(n => n.habilitado).map(nivelEscolar => (
                        {
                          label: nivelEscolar[language],
                          value: nivelEscolar.es,
                        }
                      ))}
                      validate={value => (value ? undefined : t('Obligatorio'))}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Nombre Completo del Alumno')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="nombreAlumno"
                      id="nombreAlumno"
                      component={renderSimpleInput}
                      type="text"
                      validate={value => (value ? undefined : t('Obligatorio'))}
                      help={t('Por favor ingrese el nombre completo con todos los apellidos')}
                      forceHelp
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Nombre Completo del Padre')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="nombrePadre"
                      id="nombrePadre"
                      component={renderSimpleInput}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Email del Padre')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="emailPadre"
                      id="emailPadre"
                      component={renderSimpleInput}
                      help={t('Verifique que el email sea correcto, ya que se utilizará para enviarle la información de acceso al sistema')}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Nombre Completo de la Madre')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="nombreMadre"
                      id="nombreMadre"
                      component={renderSimpleInput}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Email de la Madre')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="emailMadre"
                      id="emailMadre"
                      component={renderSimpleInput}
                      help={t('Verifique que el email sea correcto, ya que se utilizará para enviarle la información de acceso al sistema')}
                      type="text"
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
                  <Button color="primary" type="submit" className="next">{t('Guardar')}</Button>
                </ButtonToolbar>
              </form>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(DatosGenerales));
