import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { reduxForm, Field } from 'redux-form';
import renderSimpleInput from '../../../shared/components/form/Simple';

class ForgotPasswordForm extends PureComponent {
  render() {
    const { t, handleSubmit } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Correo Electrónico')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              id="email"
              component={renderSimpleInput}
              type="email"
              placeholder={t('ingrese su nueva contraseña')}
            />
          </div>
        </div>
        <button
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary account__btn account__btn--small"
        >
          {t('Recuperar contraseña')}
        </button>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withTranslation('common')(reduxForm({
  form: 'resetPassword',
})(ForgotPasswordForm));
