import _ from 'lodash';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  loadAccesosSuccess,
  LOAD_ACCESOS,
  startSessionError,
  startSessionSuccess,
  START_SESSION,
} from '../../redux/actions/appActions';
import { ROUTE_USUARIO, ROUTE_ACCESO } from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* login(payload) {
  const { api, credentials } = payload;
  credentials.username = credentials.username.toLowerCase();
  try {
    const session = yield call(api.post, toAPIPath([ROUTE_USUARIO, 'login']), credentials);
    const user = yield call(api.get, toAPIPath([ROUTE_USUARIO, `${session.userId}?filter=${encodeURIComponent('{"include": [{"relation":"roles", "scope": { "include": [{"relation":"role"}] } }]}')}&access_token=${session.id}`]));
    if (user.roles && user.roles.length > 0) {
      user.roles = _.map(_.map(user.roles, 'role'), 'name');
    } else {
      user.roles = [];
    }
    session.user = user;
    localStorage.setItem('portal_weizman_session', JSON.stringify(session));
    yield put(startSessionSuccess(session));
  } catch (err) {
    yield put(startSessionError(err));
  }
}

export function* getAccesos(payload) {
  const { api, token } = payload;
  try {
    const accesos = yield call(api.get, toAPIPath([`${ROUTE_ACCESO}?access_token=${token}`]));
    yield put(loadAccesosSuccess(accesos));
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(START_SESSION, login);
  yield takeLatest(LOAD_ACCESOS, getAccesos);
}
