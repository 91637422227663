export const GET_ALUMNOS_USUARIO = 'GET_ALUMNOS_USUARIO';
export const GET_ALUMNOS_USUARIO_SUCCESS = 'GET_ALUMNOS_USUARIO_SUCCESS';
export const GET_ALUMNOS_USUARIO_ERROR = 'GET_ALUMNOS_USUARIO_ERROR';
export const SWITCH_PAYMENT_STATUS = 'SWITCH_PAYMENT_STATUS';
export const SWITCH_CONTRACT_STATUS = 'SWITCH_CONTRACT_STATUS';
export const SWITCH_STUDENT_STATUS = 'SWITCH_STUDENT_STATUS';

export function getAlumnosUsuario(userData) {
  return {
    type: GET_ALUMNOS_USUARIO,
    apiCall: true,
    userData,
  };
}


export function getAlumnosUsuarioSuccess(alumnos, total) {
  return {
    type: GET_ALUMNOS_USUARIO_SUCCESS,
    alumnos,
    total,
  };
}


export function getAlumnosUsuarioError(error) {
  return {
    type: GET_ALUMNOS_USUARIO_ERROR,
    error,
  };
}

export function switchPaymentStatus(id, pendientePago, token) {
  return {
    type: SWITCH_PAYMENT_STATUS,
    apiCall: true,
    id,
    pendientePago,
    token,
  };
}

export function switchContractStatus(alumno, token) {
  return {
    type: SWITCH_CONTRACT_STATUS,
    apiCall: true,
    alumno,
    token,
  };
}

export function switchStudentStatus(alumno, activo, token) {
  return {
    type: SWITCH_STUDENT_STATUS,
    apiCall: true,
    alumno,
    activo,
    token,
  };
}
