import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import renderSimpleInput from '../../../shared/components/form/Simple';
import renderTextArea from '../../../shared/components/form/TextArea';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';
import AlumnoCargo from '../../../shared/components/AlumnoCargo';
import { concatenarNombreAlumno } from '../../../utils/stringUtils';

class CargoTarjeta extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    language: PropTypes.string.isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    noHayAlumnos: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      openedModal: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openModal = (modalName) => {
    this.setState({
      openedModal: modalName,
    });
  }

  closeModal = (modalName) => {
    const { openedModal } = this.state;
    if (modalName === openedModal) {
      this.setState({
        openedModal: null,
      });
    }
  }

  render() {
    const {
      handleSubmit, t, formValues, data, onSetStudentAttribute, language, noHayAlumnos,
    } = this.props;

    const {
      openedModal,
    } = this.state;

    const {
      nucleo,
      alumno,
    } = data;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">{t('Cargo Automático Tarjeta')} {CICLO_LECTIVO_A_MATRICULAR}</h3>
        <p
          style={{ marginBottom: '20px' }}
          className="wizard__contract-text"
        >
          {t('Por favor complete el siguiente formulario si desea habilitar el cargo automático para la matrícula, cuota de inscripción, mensualidades, giras, libros, clubes, alimentación y demás rubros para los estudiantes seleccionados')}.
        </p>
        <div style={{ marginTop: '20px' }}>
          <div style={{ width: '100%' }}>
            <p>{t('Señores')}</p>
            <p>{t('Instituto Dr')}. {t('Jaim Weizman')}</p>
            <p>{t('Departamento Contable')}</p>
            <p>{t('Presente')}</p>
          </div>
          <span className="wizard__contract-text" style={{ margin: '20px 0px' }}>
            {t('El suscrito,')}
            <Field
              containerStyle={{
                width: '200px',
                display: 'inline-flex',
                marginLeft: '5px',
                marginRight: '2px',
              }}
              name="suscrito"
              id="suscrito"
              component={renderSimpleInput}
              type="text"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            {t('; portador del documento de identificación número')}
            <Field
              containerStyle={{
                width: '150px',
                display: 'inline-flex',
                marginLeft: '5px',
                marginRight: '2px',
              }}
              name="identificacionSuscrito"
              id="identificacionSuscrito"
              component={renderSimpleInput}
              type="text"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            ; {t('por este medio expresamente instruyo y autorizo al Instituto Dr')}. {t('Jaim Weizman, a debitar de mi tarjeta de crédito autorizada, la suma correspondiente a Matrícula, Cuota de Inscripción, Mensualidades, Giras, Libros, Clubes, Alimentación, y demás rubros que por su naturaleza estén directamente relacionados al centro educativo para los estudiantes')}:
          </span>
          <div className="wizard__cards-first-container">
            <div>
              <h4 className="wizard__section-title">{t('Alumnos')}</h4>
              <Dialog
                onBackdropClick={() => { this.closeModal('student'); }}
                open={openedModal === 'student'}
              >
                <DialogTitle onClose={() => { this.closeModal('student'); }}>{t('Vincular Alumno')}</DialogTitle>
                <DialogContent dividers>
                  <AlumnoCargo
                    onCancel={() => {
                      this.closeModal('student');
                    }}
                    currentValue={alumno}
                    catalogo={data.catalogo}
                    language={language}
                    nucleoId={data.nucleo.id}
                    nucleoAlumnos={data.nucleo.alumno}
                  />
                </DialogContent>
              </Dialog>
              {
                alumno.length < nucleo.alumno.length
                && (
                  <Button
                    type="button"
                    onClick={() => { this.openModal('student'); }}
                    size="sm"
                    style={{
                      display: 'inline',
                      marginLeft: '10px',
                      position: 'relative',
                      top: '7px',
                    }}
                    color="primary"
                  >
                    + {t('Agregar')}
                  </Button>
                )
              }
              {
                alumno.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
              }
              {
                alumno.map(e => (
                  <Card key={`alumno_${e.alumno}`} className="wizard__form-card">
                    <CardBody className="wizard__form-card-body">
                      <div className="card__title">
                        <h4 className="bold-text">{concatenarNombreAlumno(e)}</h4>
                      </div>
                      <p>{e.rolEncargado}</p>
                      <div className="wizard__card-buttons">
                        <Button
                          type="button"
                          onClick={() => { onSetStudentAttribute('alumno', alumno.filter(al => al.alumno !== e.alumno)); }}
                          size="sm"
                          style={{
                            display: 'inline',
                          }}
                          color="danger"
                        >
                          {t('Desvincular')}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                ))
              }
            </div>
          </div>
          <div className="wizard__cards-container">
            <div>
              <h4 className="wizard__section-title">{t('Tarjeta')}</h4>
            </div>
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <span className="form__form-group-label">{t('Nombre')}</span>
              <div className="form__form-group-field">
                <Field
                  name="nombreTarjeta"
                  id="nombreTarjeta"
                  component={renderSimpleInput}
                  type="text"
                  validate={value => (value ? undefined : t('Obligatorio'))}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <span className="form__form-group-label">{t('Banco emisor')}</span>
              <div className="form__form-group-field">
                <Field
                  name="banco"
                  id="banco"
                  component={renderSimpleInput}
                  type="text"
                  validate={value => (value ? undefined : t('Obligatorio'))}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <span className="form__form-group-label">{t('Número de tarjeta')}</span>
              <div className="form__form-group-field">
                <Field
                  name="tarjeta"
                  id="tarjeta"
                  component={renderSimpleInput}
                  type="text"
                  validate={value => (value ? undefined : t('Obligatorio'))}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <span className="form__form-group-label">{t('Mes Vencimiento')}</span>
              <div className="form__form-group-field">
                <Field
                  name="mesVencimiento"
                  id="mesVencimiento"
                  component={renderSimpleInput}
                  type="number"
                  min="1"
                  max="12"
                  validate={value => (value ? undefined : t('Obligatorio'))}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <span className="form__form-group-label">{t('Año Vencimiento')}</span>
              <div className="form__form-group-field">
                <Field
                  name="anioVencimiento"
                  id="anioVencimiento"
                  component={renderSimpleInput}
                  type="number"
                  min="2020"
                  validate={value => (value ? undefined : t('Obligatorio'))}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('Comentarios / Observaciones')}</span>
            <div className="form__form-group-field">
              <Field
                id="comentarios"
                name="comentarios"
                component={renderTextArea}
                type="textarea"
              />
            </div>
          </div>
          <p className="wizard__contract-text">{t('Me comprometo a reportar cualquier cambio efectuado a la tarjeta autorizada; tales como fecha de vencimiento, reposición, cambio en su numeración, robo, sustracción o suspensión')}. {t('Así mismo, eximo de toda responsabilidad al Instituto Dr')}. {t('Jaim Weizman en los casos que, por omisión de cambios en los datos de las tarjetas, no se puedan hacer los cargos automáticos respectivos, generando en estos morosidad, atraso, y cobro de recargos')}.</p>
          {
            formValues.suscrito && formValues.identificacionSuscrito
            && (
              <div style={{ marginTop: '20px', minWidth: '100%' }}>
                <div style={{ marginBottom: '10px' }}>
                  <p className="bold-text">{formValues.suscrito} {formValues.identificacionSuscrito}</p>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('Iniciales')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="firmaTarjetahabiente"
                      id="firmaTarjetahabiente"
                      component={renderSimpleInput}
                      type="text"
                      validate={value => (value ? undefined : t('Obligatorio'))}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          noHayAlumnos && <span style={{ color: '#ff4861', textTransform: 'uppercase', width: '100%' }} className="bold-text">{t('Debe agregar al menos un alumno al cargo automático')}</span>
        }
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Link to="/cargo"><Button color="primary" type="button" className="previous">{t('Salir')}</Button></Link>
          <Button color="success" type="submit" className="next">{t('Registrar Cargo')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

const selector = formValueSelector('cargo');

function mapStateToProps(state) {
  const formValues = selector(state,
    'suscrito',
    'identificacionSuscrito');
  return { formValues };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'cargo', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(CargoTarjeta)));
