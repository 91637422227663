import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, formValueSelector, reduxForm, change, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';
import renderSimpleInput from '../../form/Simple';
import { concatenarNombreEncargado, findLabelByLanguage } from '../../../../utils/stringUtils';

class EncargadoForm extends PureComponent {
  componentDidMount() {
    /* eslint-disable-next-line no-shadow */
    const { change, itemId } = this.props;
    if (itemId) {
      this.handleEncargadoChange(itemId);
    } else {
      change('encargadoDeLista', null);
    }
  }

  handleEncargadoChange = (id) => {
    /* eslint-disable no-shadow */
    const {
      change, nucleoEncargados, catalogo, language, t, nucleoTelefonos,
    } = this.props;
    if (id !== 'new') {
      const nucleoEncargado = nucleoEncargados.find(n => n.id === id);
      change('encargadoDeLista', {
        value: nucleoEncargado.id,
        label: concatenarNombreEncargado(nucleoEncargado),
      });
      change('nombre', nucleoEncargado.nombre);
      let label = findLabelByLanguage(nucleoEncargado.rolEncargado, catalogo.rolEncargado);
      change('rolEncargado', {
        value: nucleoEncargado.rolEncargado,
        label: label ? label[language] : t('Desconocido'),
      });
      label = findLabelByLanguage(nucleoEncargado.estadoCivil, catalogo.estadoCivil);
      change('estadoCivil', {
        value: nucleoEncargado.estadoCivil,
        label: label ? label[language] : t('Desconocido'),
      });
      label = findLabelByLanguage(nucleoEncargado.nacionalidad, catalogo.nacionalidad);
      change('nacionalidad', {
        value: nucleoEncargado.nacionalidad,
        label: label ? label[language] : t('Desconocido'),
      });
      change('identificacion', nucleoEncargado.identificacion);
      change('ocupacion', nucleoEncargado.ocupacion);
      change('apartadoPostal', nucleoEncargado.apartadoPostal);
      change('username', nucleoEncargado.username);

      nucleoEncargado.telefono.forEach((t) => {
        const telefono = nucleoTelefonos.find(nt => nt.id === t);
        if (telefono) {
          switch (telefono.tipoTelefono) {
            case 'Celular':
              change('telefonoCelular', telefono.telefono);
              break;
            case 'Casa':
              change('telefonoCasa', telefono.telefono);
              break;
            case 'Oficina':
              change('telefonoOficina', telefono.telefono);
              break;
            default:
              break;
          }
        }
      });
    } else {
      change('nombre', null);
      change('rolEncargado', null);
      change('estadoCivil', null);
      change('nacionalidad', null);
      change('identificacion', null);
      change('ocupacion', null);
      change('telefonoCelular', null);
      change('telefonoOficina', null);
      change('telefonoCasa', null);
      change('username', null);
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, formValues, onCancel, catalogo, language, nucleoEncargados, itemId, puedeActualizarDG,
    } = this.props;

    const {
      rolEncargado, estadoCivil, nacionalidad,
    } = catalogo;

    return (
      <form className="form" onSubmit={handleSubmit}>
        {
          !itemId
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Seleccione el encargado a vincular')}</span>
              <div className="form__form-group-field">
                <Field
                  name="encargadoDeLista"
                  id="encargadoDeLista"
                  component={renderSelectField}
                  options={nucleoEncargados.map(nucleoEncargado => (
                    {
                      label: `${nucleoEncargado.rolEncargado ? `${nucleoEncargado.rolEncargado} - ` : ''}${concatenarNombreEncargado(nucleoEncargado)}`,
                      value: nucleoEncargado.id,
                    }
                  )).concat([{
                    label: t('+ Agregar Nuevo'),
                    value: 'new',
                  }])
                  }
                  onChange={(value) => { this.handleEncargadoChange(value.value); }}
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          formValues && formValues.encargadoDeLista
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Nombre')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="nombre"
                    id="nombre"
                    component={renderSimpleInput}
                    type="text"
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Rol del Encargado')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="rolEncargado"
                    id="rolEncargado"
                    component={renderSelectField}
                    options={rolEncargado.map(tipo => (
                      {
                        label: tipo[language],
                        value: tipo.es,
                      }
                    ))
                    }
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Estado Civil')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="estadoCivil"
                    id="estadoCivil"
                    component={renderSelectField}
                    options={estadoCivil.map(tipo => (
                      {
                        label: tipo[language],
                        value: tipo.es,
                      }
                    ))
                    }
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Identificación')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="identificacion"
                    id="identificacion"
                    component={renderSimpleInput}
                    type="text"
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Nacionalidad')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="nacionalidad"
                    id="nacionalidad"
                    component={renderSelectField}
                    options={nacionalidad.map(tipo => (
                      {
                        label: tipo[language],
                        value: tipo.es,
                      }
                    ))
                    }
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Ocupación')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="ocupacion"
                    id="ocupacion"
                    component={renderSimpleInput}
                    type="text"
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono Celular')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefonoCelular"
                    id="telefonoCelular"
                    component={renderSimpleInput}
                    type="text"
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono Casa')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefonoCasa"
                    id="telefonoCasa"
                    component={renderSimpleInput}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono Oficina')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefonoOficina"
                    id="telefonoOficina"
                    component={renderSimpleInput}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Correo Electrónico')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="username"
                    id="username"
                    component={renderSimpleInput}
                    type="email"
                    validate={this.required}
                    help="Al actualizar este campo deberá utilizar este correo electrónico para acceder al Portal Weizman"
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        {
          puedeActualizarDG
          && (
            <button
              onClick={(evt) => {
                evt.preventDefault();
                const { dispatch } = this.props;
                dispatch(submit('encargado'));
              }}
              type="submit"
              style={{ marginTop: '20px' }}
              className="btn btn-primary modal-form__btn modal-form__btn--small"
            >
              {t('Guardar y Vincular')}
            </button>
          )
        }
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {puedeActualizarDG ? t('Cancelar') : t('Cerrar')}
        </button>
      </form>
    );
  }
}

EncargadoForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  catalogo: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  nucleoEncargados: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nucleoTelefonos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  puedeActualizarDG: PropTypes.bool.isRequired,
};

EncargadoForm.defaultProps = {
  formValues: {
    encargado: '',
  },
  itemId: '',
};

const selector = formValueSelector('encargado');

function mapStateToProps(state) {
  const formValues = selector(state,
    'encargadoDeLista', 'encargado');
  return { formValues };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'encargado',
})(withTranslation('common')(EncargadoForm)));
