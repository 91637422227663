/* eslint-disable */
import { destroy } from "redux-form";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  loadCatalogoSuccess,
  procesarNuevaMatriculaSuccess,
  LOAD_CATALOGO_NUEVA_MATRICULA,
  DESTROY_NUEVA_MATRICULA_FORM,
  PROCESAR_NUEVA_MATRICULA,
} from "../../redux/actions/nuevaMatriculaActions";
import {
  CICLO_LECTIVO_A_MATRICULAR,
  ROUTE_CATALOGO,
  ROUTE_MATRICULA,
  ROUTE_NOTIFICACION,
} from "../../utils/constants";
import toAPIPath from "../../utils/locationResolver";

export function* getCatalogo(payload) {
  const { api, token } = payload;
  try {
    const catalogo = yield call(
      api.get,
      toAPIPath([ROUTE_CATALOGO, `findOne?access_token=${token}`])
    );
    yield put(loadCatalogoSuccess(catalogo));
  } catch (err) {
    throw err;
  }
}

export function* destroyForm(payload) {
  const { form } = payload;
  try {
    yield put(destroy(form));
  } catch (err) {
    /* eslint-disable-next-line */
    console.err("An error ocurred deleting the matricula form: ", err);
  }
}

export function* postNotificarEncargado(payload) {
  const { api, token, notificacion } = payload;
  try {
    yield call(
      api.post,
      toAPIPath([`${ROUTE_NOTIFICACION}?access_token=${token}`]),
      notificacion
    );
  } catch (err) {
    throw err;
  }
}

export function* postMatricula(payload) {
  const { api, data } = payload;
  const { nuevoAlumno, token } = data;
  try {
    const newStudent = yield call(
      api.post,
      toAPIPath([`${ROUTE_MATRICULA}/nueva?access_token=${token}`]),
      nuevoAlumno
    );

    const correosDeLosPadres = [];
    if (nuevoAlumno.padre.email) {
      correosDeLosPadres.push({
        email: nuevoAlumno.padre.email,
        password: newStudent.contraseniaPadre,
      });
    }
    if (nuevoAlumno.madre.email) {
      correosDeLosPadres.push({
        email: nuevoAlumno.madre.email,
        password: newStudent.contraseniaMadre,
      });
    }

    const nuevosPadres =
      newStudent.contraseniaPadre && newStudent.contraseniaMadre;
    let notificacionUsuario = null;
    const notificaciones = [];
    if (nuevosPadres) {
      correosDeLosPadres.forEach((padre) => {
        notificacionUsuario = {
          to: padre.email,
          bcc: 'asistente.direccion@weizmancr.net, informatica@weizmancr.net, cloud@polpocr.com',
          from: "Portal Weizman <noresponder@weizmancr.net>",
          subject: `Bienvenido al Portal Weizman - Alumno ${nuevoAlumno.nombre}`,
          text: `Estimado Padre de Familia
  
          Usted ha sido registrado automáticamente por el Instituto Dr. Jaim Weizman en la plataforma de matrícula para el Curso Lectivo ${CICLO_LECTIVO_A_MATRICULAR}.
          
          Por favor, ingrese al portal https://portal.weizmancr.net para que pueda realizar el proceso de matrícula con el siguiente usuario y contraseña.
          
          Usuario: ${padre.email}
          Contraseña: ${padre.password}
          
          Si lo desean pueden establecer una nueva contraseña presionando sobre la opción de "Olvidó su contraseña" en la pantalla de inicio del portal, o presionando aquí: https://portal.weizmancr.net/olvido-contrasenia
          
          En este link encontrarán un tutorial de la plataforma
          https://youtu.be/CfXFqTBR6dU
          
          En caso de tener alguna consulta, pueden escribir al correo informatica@weizmancr.net (mailto:informatica@weizmancr.net) .
          
          Muchas gracias por su colaboración.`,
          html: `<!doctype html>
          <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
              <head>
                  <!-- NAME: 1 COLUMN -->
                  <!--[if gte mso 15]>
                  <xml>
                      <o:OfficeDocumentSettings>
                      <o:AllowPNG/>
                      <o:PixelsPerInch>96</o:PixelsPerInch>
                      </o:OfficeDocumentSettings>
                  </xml>
                  <![endif]-->
                  <meta charset="UTF-8">
                  <meta http-equiv="X-UA-Compatible" content="IE=edge">
                  <meta name="viewport" content="width=device-width, initial-scale=1">
                  <title>Bienvenido al Portal Weizman</title>
                  
              <style type="text/css">
              p{
                margin:10px 0;
                padding:0;
              }
              table{
                border-collapse:collapse;
              }
              h1,h2,h3,h4,h5,h6{
                display:block;
                margin:0;
                padding:0;
              }
              img,a img{
                border:0;
                height:auto;
                outline:none;
                text-decoration:none;
              }
              body,#bodyTable,#bodyCell{
                height:100%;
                margin:0;
                padding:0;
                width:100%;
              }
              .mcnPreviewText{
                display:none !important;
              }
              #outlook a{
                padding:0;
              }
              img{
                -ms-interpolation-mode:bicubic;
              }
              table{
                mso-table-lspace:0pt;
                mso-table-rspace:0pt;
              }
              .ReadMsgBody{
                width:100%;
              }
              .ExternalClass{
                width:100%;
              }
              p,a,li,td,blockquote{
                mso-line-height-rule:exactly;
              }
              a[href^=tel],a[href^=sms]{
                color:inherit;
                cursor:default;
                text-decoration:none;
              }
              p,a,li,td,body,table,blockquote{
                -ms-text-size-adjust:100%;
                -webkit-text-size-adjust:100%;
              }
              .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
                line-height:100%;
              }
              a[x-apple-data-detectors]{
                color:inherit !important;
                text-decoration:none !important;
                font-size:inherit !important;
                font-family:inherit !important;
                font-weight:inherit !important;
                line-height:inherit !important;
              }
              #bodyCell{
                padding:10px;
              }
              .templateContainer{
                max-width:600px !important;
              }
              a.mcnButton{
                display:block;
              }
              .mcnImage,.mcnRetinaImage{
                vertical-align:bottom;
              }
              .mcnTextContent{
                word-break:break-word;
              }
              .mcnTextContent img{
                height:auto !important;
              }
              .mcnDividerBlock{
                table-layout:fixed !important;
              }
              body,#bodyTable{
                background-color:#FAFAFA;
              }
              #bodyCell{
                border-top:0;
              }
              .templateContainer{
                border:0;
              }
              h1{
                color:#202020;
                font-family:Helvetica;
                font-size:26px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
              }
              h2{
                color:#202020;
                font-family:Helvetica;
                font-size:22px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
              }
              h3{
                color:#202020;
                font-family:Helvetica;
                font-size:20px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
              }
              h4{
                color:#202020;
                font-family:Helvetica;
                font-size:18px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
              }
              #templatePreheader{
                background-color:#FAFAFA;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:9px;
                padding-bottom:9px;
              }
              #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
                color:#656565;
                font-family:Helvetica;
                font-size:12px;
                line-height:150%;
                text-align:left;
              }
              #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
                color:#656565;
                font-weight:normal;
                text-decoration:underline;
              }
              #templateHeader{
                background-color:#FFFFFF;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:9px;
                padding-bottom:0;
              }
              #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
                color:#202020;
                font-family:Helvetica;
                font-size:16px;
                line-height:150%;
                text-align:left;
              }
              #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
                color:#007C89;
                font-weight:normal;
                text-decoration:underline;
              }
              #templateBody{
                background-color:#FFFFFF;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:2px solid #EAEAEA;
                padding-top:0;
                padding-bottom:9px;
              }
              #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
                color:#202020;
                font-family:Helvetica;
                font-size:16px;
                line-height:150%;
                text-align:left;
              }
              #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
                color:#007C89;
                font-weight:normal;
                text-decoration:underline;
              }
              #templateFooter{
                background-color:#FAFAFA;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:9px;
                padding-bottom:9px;
              }
              #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
                color:#656565;
                font-family:Helvetica;
                font-size:12px;
                line-height:150%;
                text-align:center;
              }
              #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
                color:#656565;
                font-weight:normal;
                text-decoration:underline;
              }
            @media only screen and (min-width:768px){
              .templateContainer{
                width:600px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              body,table,td,p,a,li,blockquote{
                -webkit-text-size-adjust:none !important;
              }
          
          }	@media only screen and (max-width: 480px){
              body{
                width:100% !important;
                min-width:100% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnRetinaImage{
                max-width:100% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImage{
                width:100% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                max-width:100% !important;
                width:100% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnBoxedTextContentContainer{
                min-width:100% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImageGroupContent{
                padding:9px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
                padding-top:9px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
                padding-top:18px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImageCardBottomImageContent{
                padding-bottom:9px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImageGroupBlockInner{
                padding-top:0 !important;
                padding-bottom:0 !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImageGroupBlockOuter{
                padding-top:9px !important;
                padding-bottom:9px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnTextContent,.mcnBoxedTextContentColumn{
                padding-right:18px !important;
                padding-left:18px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
                padding-right:18px !important;
                padding-bottom:0 !important;
                padding-left:18px !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcpreview-image-uploader{
                display:none !important;
                width:100% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              h1{
                font-size:22px !important;
                line-height:125% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              h2{
                font-size:20px !important;
                line-height:125% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              h3{
                font-size:18px !important;
                line-height:125% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              h4{
                font-size:16px !important;
                line-height:150% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              #templatePreheader{
                display:block !important;
              }
          
          }	@media only screen and (max-width: 480px){
              #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
                font-size:16px !important;
                line-height:150% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
                font-size:16px !important;
                line-height:150% !important;
              }
          
          }	@media only screen and (max-width: 480px){
              #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
              }
          
          }</style></head>
              <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FAFAFA;">
                  <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">A través de este portal podrá realizar la matrícula para el período ${CICLO_LECTIVO_A_MATRICULAR}</span><!--<![endif]-->
                  <center>
                      <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #FAFAFA;">
                          <tr>
                              <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                                  <!-- BEGIN TEMPLATE // -->
                                  <!--[if (gte mso 9)|(IE)]>
                                  <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                  <tr>
                                  <td align="center" valign="top" width="600" style="width:600px;">
                                  <![endif]-->
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                                      <tr>
                                          <td valign="top" id="templatePreheader" style="background:#FAFAFA none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FAFAFA;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <tbody class="mcnImageCardBlockOuter">
                  <tr>
                      <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                          
          <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="background-color: #404040;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <tbody><tr>
                  <td class="mcnImageCardBottomImageContent" align="left" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                  
                      
          
                      <img alt="" src="https://portal-weizman.s3.amazonaws.com/logo_mailchimp2.png" width="564" style="max-width: 576px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
                      
                  
                  </td>
              </tr>
              <tr>
                  <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #F2F2F2;font-family: Helvetica;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
                      
                  </td>
              </tr>
          </tbody></table>
          
          
          
          
                      </td>
                  </tr>
              </tbody>
          </table></td>
                                      </tr>
                                      <tr>
                                          <td valign="top" id="templateHeader" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 0;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <tbody class="mcnTextBlockOuter">
                  <tr>
                      <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                          <!--[if mso]>
                  <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                  <tr>
                  <![endif]-->
                    
                  <!--[if mso]>
                  <td valign="top" width="600" style="width:600px;">
                  <![endif]-->
                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                              <tbody><tr>
                                  
                                  <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">
                                  
                                      <h1 style="text-align: center;display: block;margin: 0;padding: 0;color: #202020;font-family: Helvetica;font-size: 26px;font-style: normal;font-weight: bold;line-height: 125%;letter-spacing: normal;">¡Bienvenido al Portal Weizman!</h1>
          
                                  </td>
                              </tr>
                          </tbody></table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                          
                  <!--[if mso]>
                  </tr>
                  </table>
                  <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table></td>
                                      </tr>
                                      <tr>
                                          <td valign="top" id="templateBody" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <tbody class="mcnTextBlockOuter">
                  <tr>
                      <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                          <!--[if mso]>
                  <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                  <tr>
                  <![endif]-->
                    
                  <!--[if mso]>
                  <td valign="top" width="600" style="width:600px;">
                  <![endif]-->
                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                              <tbody><tr>
                                  
                                  <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">
                                  
                                      Estimado Padre de Familia
          <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Usted ha sido registrado automáticamente por el Instituto Dr. Jaim Weizman en la plataforma de matrícula para el Curso Lectivo ${CICLO_LECTIVO_A_MATRICULAR}.</p>
          
          <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Por favor, ingrese al portal <a href="https://portal.weizmancr.net" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">https://portal.weizmancr.net</a>&nbsp;para que pueda realizar el proceso de matrícula con el siguiente usuario y contraseña.<br>
          <br>
          Usuario:&nbsp;${padre.email}<br>
          Contraseña:&nbsp;${padre.password}<br>
          <br>
          Si lo desean pueden establecer una nueva contraseña presionando sobre la opción de "Olvidó su contraseña" en la pantalla de inicio del portal, o presionando aquí:&nbsp;<a href="https://portal.weizmancr.net/olvido-contrasenia" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">https://portal.weizmancr.net/olvido-contrasenia</a><br>
          <br>
          En este link encontrarán un tutorial de la plataforma&nbsp;<br>
          <a href="https://youtu.be/CfXFqTBR6dU" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">https://youtu.be/CfXFqTBR6dU</a></p>
          
          <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">En caso de tener alguna consulta, pueden escribir al correo <a href="mailto:informatica@weizmancr.net" rel="noreferrer noopener" tabindex="-1" target="_blank" title="mailto:informatica@weizmancr.net" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">informatica@weizmancr.net</a>.<br>
          &nbsp;</p>
          
          <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Muchas gracias por su colaboración.</p>
          
                                  </td>
                              </tr>
                          </tbody></table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                          
                  <!--[if mso]>
                  </tr>
                  </table>
                  <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <tbody class="mcnImageCardBlockOuter">
                  <tr>
                      <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                          
          <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="background-color: #404040;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <tbody><tr>
                  <td class="mcnImageCardBottomImageContent" align="left" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                  
                      
                      <a href="https://youtu.be/CfXFqTBR6dU" title="Matricula Primer Ingreso" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                      
          
                      <img alt="" src="https://mcusercontent.com/b8702fd1f4d400a98460b10ec/video_thumbnails_new/48e3ba7a246457956947206bf6f3e17e.png" width="564" style="max-width: 640px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
                      </a>
                  
                  </td>
              </tr>
              <tr>
                  <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #F2F2F2;font-family: Helvetica;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
                      
                  </td>
              </tr>
          </tbody></table>
          
          
          
          
                      </td>
                  </tr>
              </tbody>
          </table></td>
                                      </tr>
                                      <tr>
                                          <td valign="top" id="templateFooter" style="background:#FAFAFA none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FAFAFA;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 9px;"></td>
                                      </tr>
                                  </table>
                                  <!--[if (gte mso 9)|(IE)]>
                                  </td>
                                  </tr>
                                  </table>
                                  <![endif]-->
                                  <!-- // END TEMPLATE -->
                              </td>
                          </tr>
                      </table>
                  </center>
                          <center>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <style type="text/css">
                              @media only screen and (max-width: 480px){
                                  table#canspamBar td{font-size:14px !important;}
                                  table#canspamBar td a{display:block !important; margin-top:10px !important;}
                              }
                          </style>
                      </center></body>
          </html>
          `,
        };
        notificaciones.push(notificacionUsuario);
      });
    } else {
      correosDeLosPadres.forEach((padre) => {
        notificacionUsuario = {
          to: padre.email,
          bcc: 'asistente.direccion@weizmancr.net, informatica@weizmancr.net, cloud@polpocr.com',
          from: "Portal Weizman <noresponder@weizmancr.net>",
          subject: `Bienvenido al Portal Weizman - Alumno ${nuevoAlumno.nombre}`,
          text: `Estimado Padre / Madre de Familia

        Usted ha sido registrado automáticamente por el Instituto Dr. Jaim Weizman en la plataforma de matrícula para el Curso Lectivo ${CICLO_LECTIVO_A_MATRICULAR}.
        
        Por favor, ingrese al portal https://portal.weizmancr.net para que pueda realizar el proceso de matrícula.
        
        Si lo desean pueden establecer una nueva contraseña presionando sobre la opción de "Olvidó su contraseña" en la pantalla de inicio del portal, o presionando aquí: https://portal.weizmancr.net/olvido-contrasenia
        
        En este link encontrarán un tutorial de la plataforma
        https://youtu.be/CfXFqTBR6dU
        
        En caso de tener alguna consulta, pueden escribir al correo informatica@weizmancr.net (mailto:informatica@weizmancr.net) .
        
        Muchas gracias por su colaboración.`,
          html: `<!doctype html>
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
            <head>
                <!-- NAME: 1 COLUMN -->
                <!--[if gte mso 15]>
                <xml>
                    <o:OfficeDocumentSettings>
                    <o:AllowPNG/>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                    </o:OfficeDocumentSettings>
                </xml>
                <![endif]-->
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                <title>Bienvenido al Portal Weizman - Alumno ${nuevoAlumno.nombre}</title>
                
            <style type="text/css">
            p{
              margin:10px 0;
              padding:0;
            }
            table{
              border-collapse:collapse;
            }
            h1,h2,h3,h4,h5,h6{
              display:block;
              margin:0;
              padding:0;
            }
            img,a img{
              border:0;
              height:auto;
              outline:none;
              text-decoration:none;
            }
            body,#bodyTable,#bodyCell{
              height:100%;
              margin:0;
              padding:0;
              width:100%;
            }
            .mcnPreviewText{
              display:none !important;
            }
            #outlook a{
              padding:0;
            }
            img{
              -ms-interpolation-mode:bicubic;
            }
            table{
              mso-table-lspace:0pt;
              mso-table-rspace:0pt;
            }
            .ReadMsgBody{
              width:100%;
            }
            .ExternalClass{
              width:100%;
            }
            p,a,li,td,blockquote{
              mso-line-height-rule:exactly;
            }
            a[href^=tel],a[href^=sms]{
              color:inherit;
              cursor:default;
              text-decoration:none;
            }
            p,a,li,td,body,table,blockquote{
              -ms-text-size-adjust:100%;
              -webkit-text-size-adjust:100%;
            }
            .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
              line-height:100%;
            }
            a[x-apple-data-detectors]{
              color:inherit !important;
              text-decoration:none !important;
              font-size:inherit !important;
              font-family:inherit !important;
              font-weight:inherit !important;
              line-height:inherit !important;
            }
            #bodyCell{
              padding:10px;
            }
            .templateContainer{
              max-width:600px !important;
            }
            a.mcnButton{
              display:block;
            }
            .mcnImage,.mcnRetinaImage{
              vertical-align:bottom;
            }
            .mcnTextContent{
              word-break:break-word;
            }
            .mcnTextContent img{
              height:auto !important;
            }
            .mcnDividerBlock{
              table-layout:fixed !important;
            }
            body,#bodyTable{
              background-color:#FAFAFA;
            }
            #bodyCell{
              border-top:0;
            }
            .templateContainer{
              border:0;
            }
            h1{
              color:#202020;
              font-family:Helvetica;
              font-size:26px;
              font-style:normal;
              font-weight:bold;
              line-height:125%;
              letter-spacing:normal;
              text-align:left;
            }
            h2{
              color:#202020;
              font-family:Helvetica;
              font-size:22px;
              font-style:normal;
              font-weight:bold;
              line-height:125%;
              letter-spacing:normal;
              text-align:left;
            }
            h3{
              color:#202020;
              font-family:Helvetica;
              font-size:20px;
              font-style:normal;
              font-weight:bold;
              line-height:125%;
              letter-spacing:normal;
              text-align:left;
            }
            h4{
              color:#202020;
              font-family:Helvetica;
              font-size:18px;
              font-style:normal;
              font-weight:bold;
              line-height:125%;
              letter-spacing:normal;
              text-align:left;
            }
            #templatePreheader{
              background-color:#FAFAFA;
              background-image:none;
              background-repeat:no-repeat;
              background-position:center;
              background-size:cover;
              border-top:0;
              border-bottom:0;
              padding-top:9px;
              padding-bottom:9px;
            }
            #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
              color:#656565;
              font-family:Helvetica;
              font-size:12px;
              line-height:150%;
              text-align:left;
            }
            #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
              color:#656565;
              font-weight:normal;
              text-decoration:underline;
            }
            #templateHeader{
              background-color:#FFFFFF;
              background-image:none;
              background-repeat:no-repeat;
              background-position:center;
              background-size:cover;
              border-top:0;
              border-bottom:0;
              padding-top:9px;
              padding-bottom:0;
            }
            #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
              color:#202020;
              font-family:Helvetica;
              font-size:16px;
              line-height:150%;
              text-align:left;
            }
            #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
              color:#007C89;
              font-weight:normal;
              text-decoration:underline;
            }
            #templateBody{
              background-color:#FFFFFF;
              background-image:none;
              background-repeat:no-repeat;
              background-position:center;
              background-size:cover;
              border-top:0;
              border-bottom:2px solid #EAEAEA;
              padding-top:0;
              padding-bottom:9px;
            }
            #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
              color:#202020;
              font-family:Helvetica;
              font-size:16px;
              line-height:150%;
              text-align:left;
            }
            #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
              color:#007C89;
              font-weight:normal;
              text-decoration:underline;
            }
            #templateFooter{
              background-color:#FAFAFA;
              background-image:none;
              background-repeat:no-repeat;
              background-position:center;
              background-size:cover;
              border-top:0;
              border-bottom:0;
              padding-top:9px;
              padding-bottom:9px;
            }
            #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
              color:#656565;
              font-family:Helvetica;
              font-size:12px;
              line-height:150%;
              text-align:center;
            }
            #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
              color:#656565;
              font-weight:normal;
              text-decoration:underline;
            }
          @media only screen and (min-width:768px){
            .templateContainer{
              width:600px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            body,table,td,p,a,li,blockquote{
              -webkit-text-size-adjust:none !important;
            }
        
        }	@media only screen and (max-width: 480px){
            body{
              width:100% !important;
              min-width:100% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnRetinaImage{
              max-width:100% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImage{
              width:100% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
              max-width:100% !important;
              width:100% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnBoxedTextContentContainer{
              min-width:100% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImageGroupContent{
              padding:9px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
              padding-top:9px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
              padding-top:18px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImageCardBottomImageContent{
              padding-bottom:9px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImageGroupBlockInner{
              padding-top:0 !important;
              padding-bottom:0 !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImageGroupBlockOuter{
              padding-top:9px !important;
              padding-bottom:9px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnTextContent,.mcnBoxedTextContentColumn{
              padding-right:18px !important;
              padding-left:18px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
              padding-right:18px !important;
              padding-bottom:0 !important;
              padding-left:18px !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcpreview-image-uploader{
              display:none !important;
              width:100% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            h1{
              font-size:22px !important;
              line-height:125% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            h2{
              font-size:20px !important;
              line-height:125% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            h3{
              font-size:18px !important;
              line-height:125% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            h4{
              font-size:16px !important;
              line-height:150% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
              font-size:14px !important;
              line-height:150% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            #templatePreheader{
              display:block !important;
            }
        
        }	@media only screen and (max-width: 480px){
            #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
              font-size:14px !important;
              line-height:150% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
              font-size:16px !important;
              line-height:150% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
              font-size:16px !important;
              line-height:150% !important;
            }
        
        }	@media only screen and (max-width: 480px){
            #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
              font-size:14px !important;
              line-height:150% !important;
            }
        
        }</style></head>
            <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FAFAFA;">
                <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">A través de este portal podrá realizar la matrícula para el período ${CICLO_LECTIVO_A_MATRICULAR}</span><!--<![endif]-->
                <center>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #FAFAFA;">
                        <tr>
                            <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                                <!-- BEGIN TEMPLATE // -->
                                <!--[if (gte mso 9)|(IE)]>
                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                <tr>
                                <td align="center" valign="top" width="600" style="width:600px;">
                                <![endif]-->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                                    <tr>
                                        <td valign="top" id="templatePreheader" style="background:#FAFAFA none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FAFAFA;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <tbody class="mcnImageCardBlockOuter">
                <tr>
                    <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        
        <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="background-color: #404040;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <tbody><tr>
                <td class="mcnImageCardBottomImageContent" align="left" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                
                    
        
                    <img alt="" src="https://portal-weizman.s3.amazonaws.com/logo_mailchimp2.png" width="564" style="max-width: 576px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
                    
                
                </td>
            </tr>
            <tr>
                <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #F2F2F2;font-family: Helvetica;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
                    
                </td>
            </tr>
        </tbody></table>
        
        
        
        
                    </td>
                </tr>
            </tbody>
        </table></td>
                                    </tr>
                                    <tr>
                                        <td valign="top" id="templateHeader" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 0;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <tbody class="mcnTextBlockOuter">
                <tr>
                    <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->
                  
                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                            <tbody><tr>
                                
                                <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">
                                
                                    <h1 style="text-align: center;display: block;margin: 0;padding: 0;color: #202020;font-family: Helvetica;font-size: 26px;font-style: normal;font-weight: bold;line-height: 125%;letter-spacing: normal;">¡Bienvenido al Portal Weizman!</h1>
        
                                </td>
                            </tr>
                        </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->
                        
                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
                    </td>
                </tr>
            </tbody>
        </table></td>
                                    </tr>
                                    <tr>
                                        <td valign="top" id="templateBody" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <tbody class="mcnTextBlockOuter">
                <tr>
                    <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->
                  
                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                            <tbody><tr>
                                
                                <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">
                                
                                    Estimado Padre / Madre de Familia
        <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Usted ha sido registrado automáticamente por el Instituto Dr. Jaim Weizman en la plataforma de matrícula para el Curso Lectivo ${CICLO_LECTIVO_A_MATRICULAR}.</p>
        
        <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Por favor, ingrese al portal <a href="https://portal.weizmancr.net" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">https://portal.weizmancr.net</a>&nbsp;para que pueda realizar el proceso de matrícula.<br>
        <br>
        Si lo desean pueden establecer una nueva contraseña presionando sobre la opción de "Olvidó su contraseña" en la pantalla de inicio del portal, o presionando aquí:&nbsp;<a href="https://portal.weizmancr.net/olvido-contrasenia" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">https://portal.weizmancr.net/olvido-contrasenia</a><br>
        <br>
        En este link encontrarán un tutorial de la plataforma&nbsp;<br>
        <a href="https://youtu.be/CfXFqTBR6dU" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">https://youtu.be/CfXFqTBR6dU</a></p>
        
        <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">En caso de tener alguna consulta, pueden escribir al correo <a href="mailto:informatica@weizmancr.net" rel="noreferrer noopener" tabindex="-1" target="_blank" title="mailto:informatica@weizmancr.net" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">informatica@weizmancr.net</a>.<br>
        &nbsp;</p>
        
        <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Muchas gracias por su colaboración.</p>
        
                                </td>
                            </tr>
                        </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->
                        
                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
                    </td>
                </tr>
            </tbody>
        </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <tbody class="mcnImageCardBlockOuter">
                <tr>
                    <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        
        <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="background-color: #404040;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <tbody><tr>
                <td class="mcnImageCardBottomImageContent" align="left" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                
                    
                    <a href="https://youtu.be/CfXFqTBR6dU" title="Matricula Primer Ingreso" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    
        
                    <img alt="" src="https://mcusercontent.com/b8702fd1f4d400a98460b10ec/video_thumbnails_new/48e3ba7a246457956947206bf6f3e17e.png" width="564" style="max-width: 640px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
                    </a>
                
                </td>
            </tr>
            <tr>
                <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #F2F2F2;font-family: Helvetica;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
                    
                </td>
            </tr>
        </tbody></table>
        
        
        
        
                    </td>
                </tr>
            </tbody>
        </table></td>
                                    </tr>
                                    <tr>
                                        <td valign="top" id="templateFooter" style="background:#FAFAFA none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FAFAFA;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 9px;"></td>
                                    </tr>
                                </table>
                                <!--[if (gte mso 9)|(IE)]>
                                </td>
                                </tr>
                                </table>
                                <![endif]-->
                                <!-- // END TEMPLATE -->
                            </td>
                        </tr>
                    </table>
                </center>
                        <center>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <style type="text/css">
                            @media only screen and (max-width: 480px){
                                table#canspamBar td{font-size:14px !important;}
                                table#canspamBar td a{display:block !important; margin-top:10px !important;}
                            }
                        </style>
                    </center></body>
        </html>
        `,
        };
        notificaciones.push(notificacionUsuario);
      });
    }

    yield all(
      notificaciones.map((notificacion) =>
        call(postNotificarEncargado, {
          api,
          token,
          notificacion,
        })
      )
    );

    yield put(procesarNuevaMatriculaSuccess());
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_CATALOGO_NUEVA_MATRICULA, getCatalogo);
  yield takeLatest(DESTROY_NUEVA_MATRICULA_FORM, destroyForm);
  yield takeLatest(PROCESAR_NUEVA_MATRICULA, postMatricula);
}
