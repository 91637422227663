import { destroy } from 'redux-form';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  loadCargoSuccess,
  loadCatalogoSuccess,
  LOAD_CARGO,
  LOAD_CATALOGO_CARGO,
  LOAD_DATA_NUCLEO_CARGO,
  loadDataNucleoSuccess,
  setCargoAttribute,
  DESTROY_MATRICULAR_CARGO_FORM,
  PROCESAR_CARGO,
  procesarCargoSuccess,
} from '../../redux/actions/cargoCrearActions';
import {
  ROUTE_CATALOGO,
  ROUTE_NUCLEO,
  ROUTE_CARGO_AUTOMATICO,
} from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* getNucleoData(payload) {
  const { api, data } = payload;
  try {
    const nucleoData = yield call(api.get, toAPIPath([ROUTE_NUCLEO, `${data.nucleo}?filter=${encodeURIComponent('{"include": [{"relation":"alumno"},{"relation":"telefono"},{"relation":"direccion"},{"relation":"medico"}]}')}&access_token=${data.token}`]));
    yield put(loadDataNucleoSuccess(nucleoData));
  } catch (err) {
    throw err;
  }
}

export function* getCatalogo(payload) {
  const { api, token, nucleo } = payload;
  try {
    const catalogo = yield call(api.get, toAPIPath([ROUTE_CATALOGO, `findOne?access_token=${token}`]));
    yield call(getNucleoData, { api, data: { token, nucleo } });
    yield put(loadCatalogoSuccess(catalogo));
  } catch (err) {
    throw err;
  }
}

export function* getCargo(payload) {
  const { api, data } = payload;
  try {
    const alumno = yield call(api.get, toAPIPath([ROUTE_CARGO_AUTOMATICO, `${data.id}?access_token=${data.token}`]));
    yield all([
      'alumno',
    ].map(a => put(setCargoAttribute({
      attribute: a,
      value: alumno[a],
    }))));
    yield put(loadCargoSuccess(alumno));
  } catch (err) {
    throw err;
  }
}

export function* postCargo(payload) {
  const {
    api, data,
  } = payload;
  const {
    cargo, token, isNew,
  } = data;
  try {
    const cargoId = cargo.id;
    delete cargo.id;
    if (isNew) {
      yield call(api.post, toAPIPath([`${ROUTE_CARGO_AUTOMATICO}?access_token=${token}`]), cargo);
    } else {
      yield call(api.put, toAPIPath([ROUTE_CARGO_AUTOMATICO, `${cargoId}?access_token=${token}`]), cargo);
    }
    yield put(procesarCargoSuccess());
  } catch (err) {
    throw err;
  }
}

export function* destroyForm() {
  try {
    yield put(destroy('cargo'));
  } catch (err) {
    /* eslint-disable-next-line */
    console.err('An error ocurred deleting the matricula form: ', err);
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_CATALOGO_CARGO, getCatalogo);
  yield takeLatest(LOAD_CARGO, getCargo);
  yield takeLatest(LOAD_DATA_NUCLEO_CARGO, getNucleoData);
  yield takeLatest(DESTROY_MATRICULAR_CARGO_FORM, destroyForm);
  yield takeLatest(PROCESAR_CARGO, postCargo);
}
