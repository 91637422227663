import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
// eslint-disable-next-line
const Moment = require("moment");

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { onChange } = this.props;
    onChange(date);
  }

  render() {
    const { value } = this.props;

    return (
      <div className="date-picker">
        <DatePicker
          className="form__form-group-datepicker"
          selected={value ? new Date(value) : ''}
          onChange={this.handleChange}
          dateFormat="dd/MM/yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </div>
    );
  }
}

DatePickerField.defaultProps = {
  value: '1/1/1980',
};

const renderDatePickerField = (props) => {
  const { input, meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <DatePickerField {...input} />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderDatePickerField.defaultProps = {
  meta: null,
};

export default renderDatePickerField;
