import React, { PureComponent } from 'react';
import { Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CargoTarjeta from './CargoTarjeta';
import PasoBoton from './PasoBoton';

class WizardForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    esNuevo: PropTypes.bool.isRequired,
    noHayAlumnos: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  nextPage = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  setPage = (page) => {
    this.setState({ page });
  };

  render() {
    const {
      onSubmit, initialValues, t, data, i18n, onSetStudentAttribute, esNuevo, noHayAlumnos,
    } = this.props;
    const { page } = this.state;

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                {
                  [t('Cargo Automático')].map((paso, index) => (
                    <PasoBoton
                      key={`paso_boton_${paso}`}
                      page={index + 1}
                      label={`${paso}`}
                      onClick={this.setPage}
                      active={page === index + 1}
                    />
                  ))
                }
              </div>
              <div className="wizard__form-wrapper">
                {page === 1
                && (
                <CargoTarjeta
                  noHayAlumnos={noHayAlumnos}
                  esNuevo={esNuevo}
                  data={data}
                  language={i18n.language}
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  onSetStudentAttribute={onSetStudentAttribute}
                />
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withTranslation('common')(WizardForm);
