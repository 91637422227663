import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';
import GenericForm from './components/GenericForm';
import { setStudentAttribute } from '../../../redux/actions/matricularActions';

class Generic extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, attribute, currentValue, onCancel, etiquetaDetalle, etiquetaValor,
    } = this.props;

    const newValues = _.cloneDeep(currentValue);
    let newValue = values.other ? values.other : values.generic.value;

    if (etiquetaValor && etiquetaDetalle) {
      newValue = {};
      newValue[etiquetaValor] = values.other ? values.other : values.generic.value;
      newValue[etiquetaDetalle] = values.detail;
    }

    if (newValues.indexOf(newValue) === -1) {
      newValues.push(newValue);
    }
    dispatch(setStudentAttribute({
      attribute,
      value: newValues,
    }));

    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      language,
      catalogo,
      currentValue,
      incluirDetalle,
      labelOtroValor,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <GenericForm
              incluirDetalle={incluirDetalle}
              language={language}
              catalogo={catalogo}
              currentValue={currentValue}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
              labelOtroValor={labelOtroValor}
            />
          </div>
        </div>
      </div>
    );
  }
}

Generic.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  catalogo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  incluirDetalle: PropTypes.bool,
  currentValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  etiquetaValor: PropTypes.string,
  etiquetaDetalle: PropTypes.string,
  labelOtroValor: PropTypes.string,
};

Generic.defaultProps = {
  incluirDetalle: false,
  etiquetaValor: '',
  etiquetaDetalle: '',
  labelOtroValor: '',
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(Generic);
