/* eslint-disable no-param-reassign, no-return-assign, consistent-return, no-case-declarations */

import produce from 'immer';

import {
  START_SESSION,
  START_SESSION_SUCCESS,
  START_SESSION_ERROR,
  FINISH_SESSION,
  FINISH_SESSION_ERROR,
  FINISH_SESSION_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  EXPIRE_SESSION,
  LOAD_ACCESOS,
  LOAD_ACCESOS_SUCCESS,
  LOAD_ACCESOS_ERROR,
} from '../actions/appActions';

const initialState = {
  loading: false,
  error: false,
  session: null,
  accesos: [],
  resetEmailSent: false,
  passwordChangeSuccess: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case START_SESSION:
    case LOAD_ACCESOS:
      draft.loading = true;
      draft.error = false;
      break;
    case START_SESSION_SUCCESS:
      draft.session = action.session;
      draft.loading = false;
      draft.error = null;
      break;
    case LOAD_ACCESOS_SUCCESS:
      draft.accesos = action.accesos;
      draft.loading = false;
      draft.error = null;
      break;
    case START_SESSION_ERROR:
    case LOAD_ACCESOS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case FINISH_SESSION:
      draft.loading = true;
      draft.error = null;
      break;
    case FINISH_SESSION_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case FINISH_SESSION_SUCCESS:
      draft.session = initialState.session;
      draft.error = false;
      draft.loading = false;
      draft.passwordChangeSuccess = initialState.passwordChangeSuccess;
      draft.resetEmailSent = initialState.resetEmailSent;
      break;
    case CHANGE_PASSWORD:
      draft.loading = true;
      draft.error = false;
      break;
    case CHANGE_PASSWORD_SUCCESS:
      draft.session = null;
      draft.passwordChangeSuccess = true;
      draft.loading = false;
      draft.error = null;
      break;
    case CHANGE_PASSWORD_ERROR:
      draft.passwordChangeSuccess = false;
      draft.loading = false;
      draft.error = action.error;
      break;
    case FORGOT_PASSWORD:
      draft.loading = true;
      draft.error = null;
      break;
    case FORGOT_PASSWORD_SUCCESS:
      draft.resetEmailSent = true;
      draft.loading = false;
      draft.error = null;
      break;
    case FORGOT_PASSWORD_ERROR:
      draft.loading = false;
      draft.resetEmailSent = false;
      draft.error = action.error;
      break;
    case EXPIRE_SESSION:
      const sessionInStorage = localStorage.getItem('portal_weizman_session');
      if (sessionInStorage) {
        /* const jsonSession = JSON.parse(sessionInStorage);
        jsonSession.expired = true;
        localStorage.setItem('portal_weizman_session', JSON.stringify(jsonSession)); */
        localStorage.removeItem('portal_weizman_session');
      }
      draft.loading = false;
      draft.session.expired = true;
      break;
    default:
      return state;
  }
});
