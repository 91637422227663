import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, formValueSelector, reduxForm, change, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';
import renderSimpleInput from '../../form/Simple';
import { findLabelByLanguage } from '../../../../utils/stringUtils';

class PhoneForm extends PureComponent {
  componentDidMount() {
    /* eslint-disable-next-line no-shadow */
    const { change, itemId } = this.props;
    if (itemId) {
      this.handleTelefonoChange(itemId);
    } else {
      change('telefonoDeLista', null);
    }
  }

  handleTelefonoChange = (id) => {
    /* eslint-disable no-shadow */
    const {
      change, nucleoTelefonos, tipoTelefono, language, t,
    } = this.props;
    if (id !== 'new') {
      const nucleoTelefono = nucleoTelefonos.find(n => n.id === id);
      change('telefonoDeLista', {
        value: nucleoTelefono.id,
        label: `${nucleoTelefono.tipoTelefono} - ${nucleoTelefono.telefono}`,
      });
      const label = findLabelByLanguage(nucleoTelefono.tipoTelefono, tipoTelefono);
      change('tipoTelefono', {
        value: nucleoTelefono.tipoTelefono,
        label: label ? label[language] : t('Desconocido'),
      });
      change('telefono', nucleoTelefono.telefono);
    } else {
      change('tipoTelefono', null);
      change('telefono', null);
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, formValues, onCancel, tipoTelefono, language, nucleoTelefonos, itemId,
    } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        {
          !itemId
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Seleccione el teléfono a vincular')}</span>
              <div className="form__form-group-field">
                <Field
                  name="telefonoDeLista"
                  id="telefonoDeLista"
                  component={renderSelectField}
                  options={nucleoTelefonos.map(nucleoTelefono => (
                    {
                      label: `${nucleoTelefono.tipoTelefono} - ${nucleoTelefono.telefono}`,
                      value: nucleoTelefono.id,
                    }
                  )).concat([{
                    label: t('+ Agregar Nuevo'),
                    value: 'new',
                  }])
                  }
                  onChange={(value) => { this.handleTelefonoChange(value.value); }}
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          formValues && formValues.telefonoDeLista
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Tipo de Teléfono')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="tipoTelefono"
                    id="tipoTelefono"
                    component={renderSelectField}
                    options={tipoTelefono.map(tipo => (
                      {
                        label: tipo[language],
                        value: tipo.es,
                      }
                    ))
                    }
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefono"
                    id="telefono"
                    component={renderSimpleInput}
                    type="phone"
                    validate={this.required}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('phone'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

PhoneForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tipoTelefono: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  nucleoTelefonos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

PhoneForm.defaultProps = {
  formValues: {
    telefono: '',
  },
  itemId: '',
};

const selector = formValueSelector('phone');

function mapStateToProps(state) {
  const formValues = selector(state,
    'telefonoDeLista', 'telefono');
  return { formValues };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'phone',
})(withTranslation('common')(PhoneForm)));
