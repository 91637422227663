export const LOAD_ESTADISTICAS = 'LOAD_ESTADISTICAS';
export const LOAD_ESTADISTICAS_SUCCESS = 'LOAD_ESTADISTICAS_SUCCESS';
export const LOAD_ESTADISTICAS_ERROR = 'LOAD_ESTADISTICAS_ERROR';

export const LOAD_ESTADISTICAS_NUEVOS = 'LOAD_ESTADISTICAS_NUEVOS';
export const LOAD_ESTADISTICAS_NUEVOS_SUCCESS = 'LOAD_ESTADISTICAS_NUEVOS_SUCCESS';
export const LOAD_ESTADISTICAS_NUEVOS_ERROR = 'LOAD_ESTADISTICAS_NUEVOS_ERROR';

export const LOAD_LISTADO = 'LOAD_LISTADO';
export const LOAD_LISTADO_SUCCESS = 'LOAD_LISTADO_SUCCESS';
export const LOAD_LISTADO_ERROR = 'LOAD_LISTADO_ERROR';

export const LOAD_CAJAS = 'LOAD_CAJAS';
export const LOAD_CAJAS_SUCCESS = 'LOAD_CAJAS_SUCCESS';
export const LOAD_CAJAS_ERROR = 'LOAD_CAJAS_ERROR';

export const LOAD_CATALOGO_REPORTES = 'LOAD_CATALOGO_REPORTES';
export const LOAD_CATALOGO_REPORTES_SUCCESS = 'LOAD_CATALOGO_REPORTES_SUCCESS';

export function loadEstadisticas(token, anio) {
  return {
    type: LOAD_ESTADISTICAS,
    apiCall: true,
    token,
    anio,
  };
}

export function loadEstadisticasSuccess(stats) {
  return {
    type: LOAD_ESTADISTICAS_SUCCESS,
    stats,
  };
}

export function loadEstadisticasError(error) {
  return {
    type: LOAD_ESTADISTICAS_ERROR,
    error,
  };
}

export function loadEstadisticasNuevos(token, anio) {
  return {
    type: LOAD_ESTADISTICAS_NUEVOS,
    apiCall: true,
    token,
    anio,
  };
}

export function loadEstadisticasNuevosSuccess(stats) {
  return {
    type: LOAD_ESTADISTICAS_NUEVOS_SUCCESS,
    stats,
  };
}

export function loadEstadisticasNuevosError(error) {
  return {
    type: LOAD_ESTADISTICAS_NUEVOS_ERROR,
    error,
  };
}

export function loadListado(token, nivel, anio) {
  return {
    type: LOAD_LISTADO,
    apiCall: true,
    token,
    nivel,
    anio,
  };
}

export function loadListadoSuccess(listado) {
  return {
    type: LOAD_LISTADO_SUCCESS,
    listado,
  };
}

export function loadListadoError(error) {
  return {
    type: LOAD_LISTADO_ERROR,
    error,
  };
}

export function loadCatalagoReportes(token) {
  return {
    type: LOAD_CATALOGO_REPORTES,
    apiCall: true,
    token,
  };
}

export function loadCatalagoReportesSuccess(catalogo) {
  return {
    type: LOAD_CATALOGO_REPORTES_SUCCESS,
    catalogo,
  };
}

export function loadCajas(token, page, pageSize) {
  return {
    type: LOAD_CAJAS,
    apiCall: true,
    token,
    page,
    pageSize,
  };
}

export function loadCajasSuccess(cajas, totalCajas) {
  return {
    type: LOAD_CAJAS_SUCCESS,
    cajas,
    totalCajas,
  };
}

export function loadCajasError(error) {
  return {
    type: LOAD_CAJAS_ERROR,
    error,
  };
}
