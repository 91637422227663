import React, { PureComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { loadCajas } from '../../redux/actions/reporteActions';
import saga from './saga';
import injectSaga from '../../utils/injectSaga';
// import TablaCajas from './components/TablaCajas';
// import Panel from '../../shared/components/Panel';
import check from '../../shared/img/check.svg';

class ReporteEstadisticas extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    reporte: PropTypes.shape({}).isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 0,
      pageSize: 5,
    };
  }

  componentDidMount() {
    const { dispatch, app } = this.props;
    const {
      page, pageSize,
    } = this.state;
    dispatch(loadCajas(app.session.id, page, pageSize));
  }

  onChangePagination = (page, pageSize) => {
    const { dispatch, app } = this.props;
    this.setState({
      page,
      pageSize,
    });
    dispatch(loadCajas(app.session.id, page, pageSize));
  }

  render() {
    const {
      t, app,
    } = this.props;
    /* const {
      t, app, reporte,
    } = this.props; */

    /* const {
      page, pageSize,
    } = this.state; */

    return (
      (!app.session)
        ? <CircularProgress className="circular-progress" />
        : (
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <h3 style={{ display: 'inline' }} className="page-title">{t('Reporte de Cargos Automáticos')}</h3>
              </Col>
            </Row>
            {
              /* <Row>
              {
                reporte.loading && <CircularProgress className="circular-progress" />
              }
              <Panel title={t('Cargos Automáticos Registrados')} lg={12}>
                <TablaCajas
                  total={reporte.totalCajas}
                  page={page}
                  pageSize={pageSize}
                  cajas={reporte.cajas}
                  onChangePage={(newPage) => { this.onChangePagination(newPage, pageSize); }}
                  onChangeRowsPerPage={(value) => { this.onChangePagination(page, value); }}
                />
              </Panel>
            </Row> */
            }
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div style={{ textAlign: 'center' }}>
                      <img alt="Matrícula exitosa" src={check} style={{ maxWidth: '10vw' }} />
                      <h1 style={{ margin: '20px 0px' }}>{t('¡Reporte Generado!')}</h1>
                      <a rel="noopener noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/api/cargoAutomatico/cajas/download?access_token=${app.session.id}`}>
                        <Button color="primary" className="previous">{t('Descargar Reporte')}</Button>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  reporte: state.reporte,
});

const withSaga = injectSaga({ key: 'cajas', saga });

export default withSaga(connect(mapStateToProps)(withTranslation('common')(ReporteEstadisticas)));
