import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { startSessionSuccess } from '../../redux/actions/appActions';
import checkSession from '../../utils/checkSession';
import injectSaga from '../../utils/injectSaga';
import saga from '../../containers/LogIn/saga';

const PrivateRoute = ({
  component: Component, dispatch, app, ...rest
}) => {
  const session = checkSession();
  if (session && !app.session) {
    dispatch(startSessionSuccess(session));
  }
  return (
    <Route
      {...rest}
      render={props => (
        !session || session.expired
          ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
          : (
            <Component {...props} />
          )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  dispatch: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});

const withSaga = injectSaga({ key: 'privateRoute', saga });

export default withSaga(connect(mapStateToProps)(PrivateRoute));
