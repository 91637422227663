import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, reduxForm, change, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';
import renderSimpleInput from '../../form/Simple';
import renderRadioButtonField from '../../form/RadioButton';
import { findLabelByLanguage } from '../../../../utils/stringUtils';

class MedicineForm extends PureComponent {
  componentDidMount() {
    /* eslint-disable no-shadow */
    const {
      change, itemId, currentValue, tipoMedicamento, language, t,
    } = this.props;

    if (itemId) {
      const item = currentValue.find(n => n.medicamento === itemId);
      change('medicamento', item.medicamento);
      const label = findLabelByLanguage(item.tipo, tipoMedicamento);
      change('tipo', {
        label: label ? label[language] : t('Desconocida'),
        value: item.tipo,
      });
      change('horario', item.horario);
      change('seAplicaEnHoraLectiva', item.seAplicaEnHoraLectiva ? '1' : '0');
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, onCancel, tipoMedicamento, language,
    } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Nombre del medicamento')}</span>
          <div className="form__form-group-field">
            <Field
              name="medicamento"
              id="medicamento"
              component={renderSimpleInput}
              type="text"
              validate={this.required}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Tipo de administración')}</span>
          <div className="form__form-group-field">
            <Field
              name="tipo"
              id="tipo"
              component={renderSelectField}
              options={tipoMedicamento.map(tipo => (
                {
                  label: tipo[language],
                  value: tipo.es,
                }
              ))
              }
              validate={this.required}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Horario del medicamento')}</span>
          <div className="form__form-group-field">
            <Field
              name="horario"
              id="horario"
              component={renderSimpleInput}
              type="text"
              validate={this.required}
            />
          </div>
        </div>
        <p
          style={{ marginBottom: '20px', width: '100%' }}
          className="wizard__contract-text"
        >
          {t('¿Se debe aplicar el medicamento en horario lectivo?')}
        </p>
        <div style={{ width: '100%' }}>
          <Field
            label={t('Si se debe aplicar en horario lectivo')}
            name="seAplicaEnHoraLectiva"
            id="seAplicaEnHoraLectiva"
            radioValue="1"
            component={renderRadioButtonField}
          />
          <Field
            label={t('No se debe aplicar en horario lectivo')}
            name="seAplicaEnHoraLectiva"
            id="seAplicaEnHoraLectiva"
            radioValue="0"
            component={renderRadioButtonField}
          />
        </div>
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('medicine'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

MedicineForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  tipoMedicamento: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

MedicineForm.defaultProps = {
  itemId: '',
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(null, mapDispatchToProps)(reduxForm({
  form: 'medicine',
})(withTranslation('common')(MedicineForm)));
