import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, formValueSelector, reduxForm, change, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';
import renderTextArea from '../../form/TextArea';
import ubicaciones from './ubicaciones.json';
import { findLabelByLanguage } from '../../../../utils/stringUtils';

class AddressForm extends PureComponent {
  componentDidMount() {
    /* eslint-disable-next-line no-shadow */
    const { change, itemId } = this.props;
    if (itemId) {
      this.handleDireccionChange(itemId);
    } else {
      change('direccion', null);
    }
  }

  handleDireccionChange = (id) => {
    /* eslint-disable no-shadow */
    const {
      change, nucleoDirecciones, t, language, tipoDireccion,
    } = this.props;
    if (id !== 'new') {
      const nucleoDireccion = nucleoDirecciones.find(n => n.id === id);
      change('direccion', {
        value: nucleoDireccion.id,
        label: `${nucleoDireccion.tipoDireccion} - ${nucleoDireccion.senias.substr(0, 40)}...`,
      });
      const label = findLabelByLanguage(nucleoDireccion.tipoDireccion, tipoDireccion);
      change('tipoDireccion', {
        value: nucleoDireccion.tipoDireccion,
        label: label ? label[language] : t('Desconocido'),
      });
      change('provincia', {
        value: nucleoDireccion.provincia,
        label: nucleoDireccion.provincia,
      });
      change('canton', {
        value: nucleoDireccion.canton,
        label: nucleoDireccion.canton,
      });
      change('distrito', {
        value: nucleoDireccion.distrito,
        label: nucleoDireccion.distrito,
      });
      change('senias', nucleoDireccion.senias);
    } else {
      change('tipoDireccion', null);
      change('provincia', null);
      change('canton', null);
      change('distrito', null);
      change('senias', null);
    }
  }

  handleSelectChange = (type) => {
    /* eslint-disable-next-line no-shadow */
    const { change } = this.props;
    if (type === 'provincia') {
      change('canton', '');
      change('distrito', '');
    }
    if (type === 'canton') {
      change('distrito', '');
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, formValues, onCancel, tipoDireccion, language, nucleoDirecciones, itemId,
    } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        {
          !itemId
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Seleccione la dirección a vincular')}</span>
              <div className="form__form-group-field">
                <Field
                  name="direccion"
                  id="direccion"
                  component={renderSelectField}
                  options={nucleoDirecciones.map(nucleoDireccion => (
                    {
                      label: `${nucleoDireccion.tipoDireccion} - ${nucleoDireccion.senias.substr(0, 40)}...`,
                      value: nucleoDireccion.id,
                    }
                  )).concat([{
                    label: t('+ Agregar Nueva'),
                    value: 'new',
                  }])
                  }
                  onChange={(value) => { this.handleDireccionChange(value.value); }}
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          formValues && formValues.direccion
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Tipo de Dirección')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="tipoDireccion"
                    id="tipoDireccion"
                    component={renderSelectField}
                    options={tipoDireccion.map(tipo => (
                      {
                        label: tipo[language],
                        value: tipo.es,
                      }
                    ))
                    }
                    validate={this.required}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Provincia')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="provincia"
                    id="provincia"
                    component={renderSelectField}
                    options={Object.keys(ubicaciones).sort().map(provincia => (
                      {
                        label: provincia,
                        value: provincia,
                      }
                    ))}
                    onChange={() => { this.handleSelectChange('provincia'); }}
                    validate={this.required}
                  />
                </div>
              </div>
              {
                formValues && formValues.provincia
                && (
                  <React.Fragment>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('Canton')}</span>
                      <div className="form__form-group-field">
                        <Field
                          name="canton"
                          id="canton"
                          component={renderSelectField}
                          options={Object.keys(ubicaciones[formValues.provincia.value]).sort().map(canton => (
                            {
                              label: canton,
                              value: canton,
                            }
                          ))}
                          onChange={() => { this.handleSelectChange('canton'); }}
                          validate={this.required}
                        />
                      </div>
                    </div>
                    {
                      formValues && formValues.canton
                      && (
                        <React.Fragment>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('Distrito')}</span>
                            <div className="form__form-group-field">
                              <Field
                                name="distrito"
                                id="distrito"
                                component={renderSelectField}
                                options={Object.keys(ubicaciones[formValues.provincia.value][formValues.canton.value]).sort().map(distrito => (
                                  {
                                    label: distrito,
                                    value: distrito,
                                  }
                                ))}
                                validate={this.required}
                              />
                            </div>
                          </div>
                          <div className="form__form-group">
                            <span className="form__form-group-label">{t('Dirección exacta')}</span>
                            <div className="form__form-group-field">
                              <Field
                                id="senias"
                                name="senias"
                                component={renderTextArea}
                                type="textarea"
                                validate={this.required}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    }
                  </React.Fragment>
                )
              }
            </React.Fragment>
          )
        }
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('address'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

AddressForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tipoDireccion: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  nucleoDirecciones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
};

AddressForm.defaultProps = {
  formValues: {
    direccion: '',
    provincia: '',
    canton: '',
  },
  itemId: '',
};

const selector = formValueSelector('address');

function mapStateToProps(state) {
  const formValues = selector(state,
    'direccion',
    'provincia',
    'canton');
  return { formValues };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'address',
})(withTranslation('common')(AddressForm)));
