import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  changePasswordError,
  changePasswordSuccess,
  CHANGE_PASSWORD,
} from '../../redux/actions/appActions';
import { ROUTE_USUARIO } from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* changePassword(payload) {
  const { api, data } = payload;
  try {
    yield call(api.post, toAPIPath([ROUTE_USUARIO, `reset-password?access_token=${data.token}`]), {
      newPassword: data.newPassword,
    });
    localStorage.removeItem('portal_weizman_session');
    yield put(changePasswordSuccess());
  } catch (err) {
    yield put(changePasswordError(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}
