export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const ROUTE_USUARIO = 'usuario';
export const ROUTE_ACCESO = 'acceso';
export const ROUTE_ALUMNO = 'alumno';
export const ROUTE_CATALOGO = 'catalogo';
export const ROUTE_NUCLEO = 'nucleo';
export const ROUTE_STORAGE = 'storage';
export const ROUTE_CARGO_AUTOMATICO = 'cargoAutomatico';
export const ROUTE_NUCLEO_TELEFONO = 'nucleoTelefono';
export const ROUTE_NUCLEO_DIRECCION = 'nucleoDireccion';
export const ROUTE_NUCLEO_MEDICO = 'nucleoMedico';
export const ROUTE_MATRICULA = 'matricula';
export const ROUTE_MATRICULA_DAY_CARE = 'matriculaDayCare';
export const ROUTE_NOTIFICACION = 'Notification';

export const CICLO_LECTIVO_A_MATRICULAR = '2024-2025';
export const CICLO_LECTIVO_A_MATRICULAR_SOLO_ANIO = 2024;
export const CICLO_LECTIVO_ACTUAL = 2023;
export const ANIO_ACTUAL = 2024;
export const ANIO_SIGUIENTE = 2025;
