import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderSimpleInput from '../../../shared/components/form/Simple';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderTextArea from '../../../shared/components/form/TextArea';
import Generic from '../../../shared/components/Generic';
import { concatenarNombreAlumno } from '../../../utils/stringUtils';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';

class Nutricion extends PureComponent {
  static propTypes = {
    token: PropTypes.string.isRequired,
    formValues: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    language: PropTypes.string.isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    enableDraftButton: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      openedModal: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openModal = (modalName) => {
    this.setState({
      openedModal: modalName,
    });
  }

  closeModal = (modalName) => {
    const { openedModal } = this.state;
    if (modalName === openedModal) {
      this.setState({
        openedModal: null,
      });
    }
  }

  render() {
    const {
      handleSubmit, t, previousPage, formValues, data, language, onSetStudentAttribute, token, enableDraftButton, onSaveDraft,
    } = this.props;

    const { openedModal } = this.state;
    const {
      alergiasAlimenticias, intoleranciasAlimenticias, problemasGastrointestinales,
    } = data;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">{t('Formulario de Nutrición')} {CICLO_LECTIVO_A_MATRICULAR}</h3>
        <p
          style={{ marginBottom: '20px' }}
          className="wizard__contract-text"
        >
          {t('A continuación le solicitamos que nos brinde una serie de información en caso de que aplique para el alumno')} <span className="bold-text">{concatenarNombreAlumno({ nombreAlumno: formValues.nombreAlumno, apellido1: formValues.apellido1, apellido2: formValues.apellido2 })}</span>.
        </p>
        <div className="wizard__cards-first-container">
          <div>
            <h4 className="wizard__section-title">{t('Alergias Alimenticias')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('foodAlergies'); }}
              open={openedModal === 'foodAlergies'}
            >
              <DialogTitle onClose={() => { this.closeModal('foodAlergies'); }}>{t('Alergias Alimenticias')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('foodAlergies');
                  }}
                  currentValue={alergiasAlimenticias}
                  attribute="alergiasAlimenticias"
                  catalogo={data.catalogo.alergias}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('foodAlergies'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              alergiasAlimenticias.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              alergiasAlimenticias.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a}</h4>
                    </div>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('alergiasAlimenticias', alergiasAlimenticias.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Desvincular')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Intolerancias Alimenticias')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('foodIntolerances'); }}
              open={openedModal === 'foodIntolerances'}
            >
              <DialogTitle onClose={() => { this.closeModal('foodIntolerances'); }}>{t('Intolerancias Alimenticias')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('foodIntolerances');
                  }}
                  currentValue={intoleranciasAlimenticias}
                  attribute="intoleranciasAlimenticias"
                  catalogo={data.catalogo.intolerancias}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('foodIntolerances'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              intoleranciasAlimenticias.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              intoleranciasAlimenticias.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a}</h4>
                    </div>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('intoleranciasAlimenticias', intoleranciasAlimenticias.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Desvincular')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>
        <div className="wizard__cards-container">
          <div>
            <h4 className="wizard__section-title">{t('Problemas Gastrointestinales')}</h4>
            <Dialog
              onBackdropClick={() => { this.closeModal('gastroIssues'); }}
              open={openedModal === 'gastroIssues'}
            >
              <DialogTitle onClose={() => { this.closeModal('gastroIssues'); }}>{t('Problemas Gastrointestinales')}</DialogTitle>
              <DialogContent dividers>
                <Generic
                  onCancel={() => {
                    this.closeModal('gastroIssues');
                  }}
                  currentValue={problemasGastrointestinales}
                  attribute="problemasGastrointestinales"
                  catalogo={data.catalogo.problemasGastrointestinales}
                  language={language}
                />
              </DialogContent>
            </Dialog>
            <Button
              type="button"
              onClick={() => { this.openModal('gastroIssues'); }}
              size="sm"
              style={{
                display: 'inline',
                marginLeft: '10px',
                position: 'relative',
                top: '7px',
              }}
              color="primary"
            >
              + {t('Agregar')}
            </Button>
            {
              problemasGastrointestinales.length === 0 && <h5 className="bold-text">{t('No se han agregado datos')}</h5>
            }
            {
              problemasGastrointestinales.map(a => (
                <Card key={`tel_${a}`} className="wizard__form-card">
                  <CardBody className="wizard__form-card-body">
                    <div className="card__title">
                      <h4 className="bold-text">{a}</h4>
                    </div>
                    <div className="wizard__card-buttons">
                      <Button
                        type="button"
                        onClick={() => { onSetStudentAttribute('problemasGastrointestinales', problemasGastrointestinales.filter(aTemp => aTemp !== a), 'id'); }}
                        size="sm"
                        style={{
                          display: 'inline',
                        }}
                        color="danger"
                      >
                        {t('Desvincular')}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))
            }
          </div>
        </div>

        <p className="wizard__contract-text">{t('¿Sufre su hijo (a) de diabetes o hiperinsulinemia?')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="diabatesOHiperinsulinemia"
              name="diabatesOHiperinsulinemia"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="diabatesOHiperinsulinemia"
              name="diabatesOHiperinsulinemia"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        {
          formValues.diabatesOHiperinsulinemia === '1'
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Tipo de Medicamento')}</span>
                <div className="form__form-group-field">
                  <Field
                    id="tipoMedicamentoDiabetesHiperinsulinemia"
                    name="tipoMedicamentoDiabetesHiperinsulinemia"
                    component={renderSimpleInput}
                    type="text"
                    validate={value => (value ? undefined : t('Obligatorio'))}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Horario')}</span>
                <div className="form__form-group-field">
                  <Field
                    id="horarioMedicamentoDiabetesHiperinsulinemia"
                    name="horarioMedicamentoDiabetesHiperinsulinemia"
                    component={renderSimpleInput}
                    type="text"
                    validate={value => (value ? undefined : t('Obligatorio'))}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <p className="wizard__contract-text">{t('¿Sufre su hijo (a) de algún problema hepático?')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="problemaHepatico"
              name="problemaHepatico"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="problemaHepatico"
              name="problemaHepatico"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        {
          formValues.problemaHepatico === '1'
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Especifique')}</span>
              <div className="form__form-group-field">
                <Field
                  id="detalleProblemaHepatico"
                  name="detalleProblemaHepatico"
                  component={renderSimpleInput}
                  type="text"
                  validate={value => (value ? undefined : t('Obligatorio'))}
                />
              </div>
            </div>
          )
        }
        <p className="wizard__contract-text">{t('Si su hijo(a) requiere una dieta especial, es requisito indispensable la presentación de un examen de laboratorio acompañado por la receta completa por parte de un médico, con las especificaciones del caso')}.</p>
        {
          data.dietaEspecial
          && (
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <div className="form__form-group-field">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/api/storage/dietaEspecial/download/${data.dietaEspecial}?access_token=${token}`}>Ver Dieta Especial del Alumno</a>
              </div>
            </div>
          )
        }
        <div style={{ marginTop: data.dietaEspecial ? '10px' : '20px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              label={<p><span className="lnr lnr-file-add" />&nbsp;{data.dietaEspecial ? t('Reemplazar Dieta Especial') : t('Adjuntar Dieta Especial')}</p>}
              id="dietaEspecial"
              name="dietaEspecial"
              component={renderFileInputField}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('Comentario')}</span>
          <div className="form__form-group-field">
            <Field
              id="comentarioDieta"
              name="comentarioDieta"
              component={renderTextArea}
              type="textarea"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>{t('Atrás')}</Button>
          {
            enableDraftButton
            && <Button onClick={onSaveDraft} color="primary" type="button" className="next">{t('Guardar Borrador')}</Button>
          }
          <Button color="primary" type="submit" className="next">{t('Guardar y Seguir')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

const selector = formValueSelector('matricula');

function mapStateToProps(state) {
  const formValues = selector(state,
    'nombreAlumno',
    'apellido1',
    'apellido2',
    'diabatesOHiperinsulinemia',
    'problemaHepatico');
  return { formValues };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(Nutricion)));
