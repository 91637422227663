import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { t, onLogout, app } = this.props;

    return (
      <div className="sidebar__content">
        {
          app.accesos && app.accesos.length > 0
          && (
            <React.Fragment>
              <ul className="sidebar__block">
                {
                  _.intersection(app.accesos.find(a => a.opcion === 'matricula').roles, app.session.user.roles).length > 0
                  && (
                    <SidebarLink
                      title={t('Matrícula')}
                      icon="users"
                      route="/matricula"
                      onClick={this.hideSidebar}
                    />
                  )
                }
                {
                  _.intersection(app.accesos.find(a => a.opcion === 'cargo').roles, app.session.user.roles).length > 0
                  && (
                    <SidebarLink
                      title={t('Cargos Automáticos')}
                      icon="calendar-full"
                      route="/cargo"
                      onClick={this.hideSidebar}
                    />
                  )
                }
              </ul>
              {
                _.intersection(app.accesos.find(a => a.opcion === 'reporte').roles, app.session.user.roles).length > 0
                && (
                  <ul className="sidebar__block">
                    <SidebarCategory title={t('Reportes')} icon="pie-chart">
                      {
                        _.intersection(app.accesos.find(a => a.opcion === 'estadisticas').roles, app.session.user.roles).length > 0
                        && (
                          <SidebarLink
                            title={t('Estadísticas')}
                            route="/reportes/estadisticas"
                            onClick={this.hideSidebar}
                          />
                        )
                      }
                      {
                        _.intersection(app.accesos.find(a => a.opcion === 'estadisticas').roles, app.session.user.roles).length > 0
                        && (
                          <SidebarLink
                            title={t('Estadísticas Nuevo Ingreso')}
                            route="/reportes/estadisticas-nuevos"
                            onClick={this.hideSidebar}
                          />
                        )
                      }
                      {
                        _.intersection(app.accesos.find(a => a.opcion === 'matriculaEstado').roles, app.session.user.roles).length > 0
                        && (
                          <SidebarLink
                            title={t('Matrícula por Estado')}
                            route="/reportes/matricula-por-estado"
                            onClick={this.hideSidebar}
                          />
                        )
                      }
                      {
                        _.intersection(app.accesos.find(a => a.opcion === 'listado').roles, app.session.user.roles).length > 0
                        && (
                          <SidebarLink
                            title={t('Listado por Grado')}
                            route="/reportes/listado-por-nivel"
                            onClick={this.hideSidebar}
                          />
                        )
                      }
                      {
                        _.intersection(app.accesos.find(a => a.opcion === 'cajas').roles, app.session.user.roles).length > 0
                        && (
                          <SidebarLink
                            title={t('Financiero')}
                            route="/reportes/cajas"
                            onClick={this.hideSidebar}
                          />
                        )
                      }
                    </SidebarCategory>
                  </ul>
                )
              }
              <ul className="sidebar__block">
                <div
                  tabIndex={0}
                  role="button"
                  onKeyDown={(evt) => { if (evt.keyCode === 13) { onLogout(); } }}
                  onClick={onLogout}
                >
                  <li className="sidebar__link">
                    <span className="sidebar__link-icon lnr lnr-exit" />
                    <p className="sidebar__link-title">
                      {t('Salir')}
                    </p>
                  </li>
                </div>
              </ul>
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(withTranslation('common')(SidebarContent));
