import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import EncargadoForm from './components/EncargadoForm';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import { saveEncargado } from '../../../redux/actions/matricularActions';

class Encargado extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, nucleoId, app, onCancel, nucleoTelefonos,
    } = this.props;

    const newEncargado = _.cloneDeep(values);
    newEncargado.nucleo = nucleoId;

    const atributosConListado = [
      'rolEncargado',
      'estadoCivil',
      'nacionalidad',
    ];

    Object.keys(values).forEach((nombre) => {
      if (atributosConListado.indexOf(nombre) > -1) {
        newEncargado[nombre] = values[nombre].value;
      } else {
        newEncargado[nombre] = values[nombre];
      }
    });

    const telefonoCelularID = nucleoTelefonos.find(nt => nt.telefono === values.telefonoCelular && nt.tipoTelefono === 'Celular');
    const telefonoCasaId = nucleoTelefonos.find(nt => nt.telefono === values.telefonoCasa && nt.tipoTelefono === 'Casa');
    const telefonoOficinaId = nucleoTelefonos.find(nt => nt.telefono === values.telefonoOficina && nt.tipoTelefono === 'Oficina');

    const telefono = [];
    const telefonosACrear = [];
    if (telefonoCelularID) {
      telefono.push(telefonoCelularID.id);
    } else if (values.telefonoCelular) {
      telefonosACrear.push({
        nucleo: nucleoId,
        tipoTelefono: 'Celular',
        telefono: values.telefonoCelular,
      });
    }
    if (telefonoCasaId) {
      telefono.push(telefonoCasaId.id);
    } else if (values.telefonoCasa) {
      telefonosACrear.push({
        nucleo: nucleoId,
        tipoTelefono: 'Casa',
        telefono: values.telefonoCasa,
      });
    }
    if (telefonoOficinaId) {
      telefono.push(telefonoOficinaId.id);
    } else if (values.telefonoOficina) {
      telefonosACrear.push({
        nucleo: nucleoId,
        tipoTelefono: 'Oficina',
        telefono: values.telefonoOficina,
      });
    }

    newEncargado.telefono = telefono;
    newEncargado.email = values.username;

    const isNew = newEncargado.encargadoDeLista.value === 'new';
    let encargadoId = null;
    if (!isNew) {
      encargadoId = newEncargado.encargadoDeLista.value;
    }
    delete newEncargado.id;
    delete newEncargado.encargadoDeLista;
    delete newEncargado.telefonoCelular;
    delete newEncargado.telefonoCasa;
    delete newEncargado.telefonoOficina;

    dispatch(saveEncargado({
      token: app.session.id,
      isNew,
      itemId: encargadoId,
      encargado: newEncargado,
      telefonosACrear,
    }));
    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      catalogo,
      language,
      nucleoEncargados,
      nucleoTelefonos,
      itemId,
      puedeActualizarDG,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <EncargadoForm
              itemId={itemId}
              nucleoEncargados={nucleoEncargados}
              nucleoTelefonos={nucleoTelefonos}
              language={language}
              catalogo={catalogo}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
              puedeActualizarDG={puedeActualizarDG}
            />
          </div>
        </div>
      </div>
    );
  }
}

Encargado.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  catalogo: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  nucleoId: PropTypes.string.isRequired,
  nucleoEncargados: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nucleoTelefonos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
  puedeActualizarDG: PropTypes.bool,
};

Encargado.defaultProps = {
  itemId: '',
  puedeActualizarDG: true,
};

const withSaga = injectSaga({ key: 'encargado', saga });

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(connect(mapStateToProps)(Encargado));
