export const GET_CARGOS_USUARIO = 'GET_CARGOS_USUARIO';
export const GET_CARGOS_USUARIO_SUCCESS = 'GET_CARGOS_USUARIO_SUCCESS';
export const GET_CARGOS_USUARIO_ERROR = 'GET_CARGOS_USUARIO_ERROR';

export const BORRAR_CARGO = 'BORRAR_CARGO';
export const BORRAR_CARGO_SUCCESS = 'BORRAR_CARGO_SUCCESS';
export const BORRAR_CARGO_ERROR = 'BORRAR_CARGO_ERROR';

export function getCargosUsuario(userData) {
  return {
    type: GET_CARGOS_USUARIO,
    apiCall: true,
    userData,
  };
}


export function getCargosUsuarioSuccess(cargos) {
  return {
    type: GET_CARGOS_USUARIO_SUCCESS,
    cargos,
  };
}


export function getCargosUsuarioError(error) {
  return {
    type: GET_CARGOS_USUARIO_ERROR,
    error,
  };
}

export function borrarCargo(userData, id) {
  return {
    type: BORRAR_CARGO,
    apiCall: true,
    userData,
    id,
  };
}


export function borrarCargoSuccess() {
  return {
    type: BORRAR_CARGO_SUCCESS,
  };
}


export function borrarCargoError(error) {
  return {
    type: BORRAR_CARGO_ERROR,
    error,
  };
}
