import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { getNucleoData } from '../../../containers/Matricular/saga';
import {
  saveDoctorSuccess, SAVE_DOCTOR, setStudentAttribute,
} from '../../../redux/actions/matricularActions';
import { ROUTE_NUCLEO_MEDICO } from '../../../utils/constants';
import toAPIPath from '../../../utils/locationResolver';

export function* postPutDoctor(payload) {
  const { api, data } = payload;
  let doctorId;
  try {
    if (data.isNew) {
      const newDoctor = yield call(api.post, toAPIPath([`${ROUTE_NUCLEO_MEDICO}?access_token=${data.token}`]), data.doctor);
      doctorId = newDoctor.id;
    } else {
      yield call(api.put, toAPIPath([ROUTE_NUCLEO_MEDICO, `${data.itemId}?access_token=${data.token}`]), data.doctor);
      doctorId = data.itemId;
    }
    yield call(getNucleoData, {
      api,
      data: {
        token: data.token,
        nucleo: data.doctor.nucleo,
      },
    });
    yield put(setStudentAttribute({
      attribute: 'medicoCabecera',
      value: doctorId,
    }));
    yield put(saveDoctorSuccess());
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(SAVE_DOCTOR, postPutDoctor);
}
