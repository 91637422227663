import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderFileInputField from '../../../shared/components/form/FileInput';
import { concatenarNombreAlumno } from '../../../utils/stringUtils';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';

class Registro extends PureComponent {
  static propTypes = {
    formValues: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    enableDraftButton: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      handleSubmit, t, formValues, data, enableDraftButton, onSaveDraft, token,
    } = this.props;

    const nivelesExcentosDeNotas = ['PM - Pre maternal', 'M - Maternal', 'PK - Pre Kínder', 'K - Kínder'];

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">{t('Registro de Nuevo Ingreso')} {CICLO_LECTIVO_A_MATRICULAR}</h3>
        <p
          style={{ marginBottom: '20px' }}
          className="wizard__contract-text"
        >
          {t('A continuación le solicitamos que nos brinde una serie de información para poder matricular al alumno')} <span className="bold-text">{concatenarNombreAlumno({ nombreAlumno: formValues.nombreAlumno, apellido1: formValues.apellido1, apellido2: formValues.apellido2 })}</span>.
        </p>
        <p className="wizard__contract-text">{t('¿Soy miembro del Centro Israelita Sionista de Costa Rica (CISCR)?')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="miembroCISCR"
              name="miembroCISCR"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="miembroCISCR"
              name="miembroCISCR"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <p className="wizard__contract-text">{t('¿Me encuentro al día con todos los pagos del Centro Israelita Sionista de Costa Rica (CISCR)?')}</p>
        <div style={{ marginTop: '10px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              id="alDiaCISCR"
              name="alDiaCISCR"
              component={renderRadioButtonField}
              label={t('Sí')}
              radioValue="1"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
            <Field
              id="alDiaCISCR"
              name="alDiaCISCR"
              component={renderRadioButtonField}
              label={t('No')}
              radioValue="0"
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <p className="wizard__contract-text">{t('Por favor adjunte las notas y expediente de la escuela o colegio anterior')}</p>
        {
          data.alumno.notasExpediente
          && (
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <div className="form__form-group-field">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/api/storage/notasExpediente/download/${data.alumno.notasExpediente}?access_token=${token}`}>Ver Notas</a>
              </div>
            </div>
          )
        }
        <div style={{ marginTop: data.alumno.notasExpediente ? '10px' : '20px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              label={<p><span className="lnr lnr-file-add" />&nbsp;{data.alumno.notasExpediente ? t('Reemplazar Notas') : t('Adjuntar Notas')}</p>}
              id="notasExpediente"
              name="notasExpediente"
              component={renderFileInputField}
              validate={value => (value || nivelesExcentosDeNotas.indexOf(data.alumno.nivel) > -1 ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <p className="wizard__contract-text">{t('Por favor adjunte el acta de nacimiento')}</p>
        {
          data.alumno.actaNacimiento
          && (
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <div className="form__form-group-field">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/api/storage/actaNacimiento/download/${data.alumno.actaNacimiento}?access_token=${token}`}>Ver Acta Nacimiento</a>
              </div>
            </div>
          )
        }
        <div style={{ marginTop: data.alumno.actaNacimiento ? '10px' : '20px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              label={<p><span className="lnr lnr-file-add" />&nbsp;{data.alumno.actaNacimiento ? t('Reemplazar Acta Nacimiento') : t('Adjuntar Acta Nacimiento')}</p>}
              id="actaNacimiento"
              name="actaNacimiento"
              component={renderFileInputField}
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <p className="wizard__contract-text">{t('Por favor adjunte una fotografía tamaño pasaporte')}</p>
        {
          data.alumno.fotografia
          && (
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <div className="form__form-group-field">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/api/storage/fotografia/download/${data.alumno.fotografia}?access_token=${token}`}>Ver Fotografía</a>
              </div>
            </div>
          )
        }
        <div style={{ marginTop: data.alumno.fotografia ? '10px' : '20px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              label={<p><span className="lnr lnr-file-add" />&nbsp;{data.alumno.fotografia ? t('Reemplazar Fotografía') : t('Adjuntar Fotografía')}</p>}
              id="fotografia"
              name="fotografia"
              component={renderFileInputField}
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <p className="wizard__contract-text">{t('Por favor adjunte el libro de vacunación')}</p>
        {
          data.alumno.libroVacunacion
          && (
            <div style={{ marginTop: '20px' }} className="form__form-group">
              <div className="form__form-group-field">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.PUBLIC_URL}/api/storage/libroVacunacion/download/${data.alumno.libroVacunacion}?access_token=${token}`}>Ver Libro de Vacunación</a>
              </div>
            </div>
          )
        }
        <div style={{ marginTop: data.alumno.libroVacunacion ? '10px' : '20px' }} className="form__form-group">
          <div className="form__form-group-field">
            <Field
              label={<p><span className="lnr lnr-file-add" />&nbsp;{data.alumno.libroVacunacion ? t('Reemplazar Libro de Vacunación') : t('Adjuntar Libro de Vacunación')}</p>}
              id="libroVacunacion"
              name="libroVacunacion"
              component={renderFileInputField}
              validate={value => (value ? undefined : t('Obligatorio'))}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Link to="/matricula"><Button color="primary" className="previous">{t('Volver')}</Button></Link>
          {
            enableDraftButton
            && <Button onClick={onSaveDraft} color="primary" type="button" className="next">{t('Guardar Borrador')}</Button>
          }
          <Button color="primary" type="submit" className="next">{t('Guardar y Seguir')}</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

const selector = formValueSelector('matricula');

function mapStateToProps(state) {
  const formValues = selector(state,
    'nombreAlumno',
    'apellido1',
    'apellido2',
    'diabatesOHiperinsulinemia',
    'problemaHepatico');
  return { formValues };
}

export default connect(mapStateToProps)(reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(Registro)));
