import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { loadCajasSuccess, LOAD_CAJAS } from '../../redux/actions/reporteActions';
import {
  ROUTE_CARGO_AUTOMATICO,
} from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* getCajas(payload) {
  const {
    api, token, page, pageSize,
  } = payload;
  try {
    const cajas = yield call(api.get, toAPIPath([ROUTE_CARGO_AUTOMATICO, `cajas?filter=${encodeURIComponent(`{"skip": ${page * pageSize}, "limit": ${pageSize}}`)}&access_token=${token}`]));
    const totalCargos = yield call(api.get, toAPIPath([ROUTE_CARGO_AUTOMATICO, `count?access_token=${token}`]));
    yield put(loadCajasSuccess(cajas, totalCargos.count));
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(LOAD_CAJAS, getCajas);
}
