/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class FileInputField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    value: null,
  };

  render() {
    const {
      onChange, name, value, label,
    } = this.props;

    return (
      <div className="form__form-group-file">
        <label htmlFor={name}>{label}</label>
        <span>{value.name}</span>
        <input
          type="file"
          name={name}
          id={name}
          onChange={
            (e) => {
              e.preventDefault();
              // convert files to an array
              const files = [...e.target.files];
              if (files[0]) {
                const filesize = ((files[0].size / 1024) / 1024).toFixed(4);
                if (filesize < 20) {
                  onChange({ file: files[0], name: files[0].name });
                } else {
                  /* eslint-disable-next-line no-alert */
                  alert('El archivo que intenta subir excede el peso máximo (20 Megabytes) permitido por la plataforma.');
                }
              }
            }
          }
        />
      </div>
    );
  }
}

const renderFileInputField = (props) => {
  const {
    input, meta, label,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <FileInputField label={label} {...input} />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderFileInputField.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
