import React, { PureComponent } from "react";
import { Col, Card, Row } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DatosGenerales from "./DatosGenerales";
import ComunidadWeizman from "./ComunidadWeizman";
import Nutricion from "./Nutricion";
import Registro from "./Registro";
import Salud from "./Salud";
import DayCare from "./DayCare";
import PasoBoton from "./PasoBoton";

class WizardForm extends PureComponent {
  static propTypes = {
    token: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    form: PropTypes.shape({}),
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    esNuevo: PropTypes.bool.isRequired,
    enableDraftButton: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    form: {},
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      page:
        data.alumno &&
        data.alumno.estadoMatricula ===
          "Pendiente Contrato Servicios Educativos"
          ? data.alumno.nuevoIngreso ? 6 : 5
          : 1,
    });
  }

  nextPage = (values) => {
    const { onSubmit } = this.props;
    this.setState((prevState) => ({ page: prevState.page + 1 }));
    onSubmit(values, {
      isDraft: true,
    });
  };

  saveDraft = () => {
    const { onSubmit, form } = this.props;
    const { values } = form;
    onSubmit(values, {
      isDraft: true,
    });
  };

  previousPage = () => {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  };

  setPage = (page) => {
    this.setState({ page });
  };

  render() {
    const {
      onSubmit,
      initialValues,
      t,
      data,
      i18n,
      onSetStudentAttribute,
      esNuevo,
      token,
      enableDraftButton,
    } = this.props;
    const { page } = this.state;

    let titulosPasos = [
      t("Alumno"),
      t("Nutrición"),
      t("Salud"),
      t("Day Care"),
      t("Matrícula"),
    ];
    let componentsPasos = [
      <DatosGenerales
        esNuevo={esNuevo}
        data={data}
        language={i18n.language}
        previousPage={this.previousPage}
        onSubmit={this.nextPage}
        onSaveDraft={this.saveDraft}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
        enableDraftButton={enableDraftButton}
      />,
      <Nutricion
        token={token}
        data={data}
        language={i18n.language}
        previousPage={this.previousPage}
        onSubmit={this.nextPage}
        onSaveDraft={this.saveDraft}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
        enableDraftButton={enableDraftButton}
      />,
      <Salud
        data={data}
        language={i18n.language}
        previousPage={this.previousPage}
        onSubmit={this.nextPage}
        onSaveDraft={this.saveDraft}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
        enableDraftButton={enableDraftButton}
      />,
      <DayCare
        data={data}
        previousPage={this.previousPage}
        onSubmit={this.nextPage}
        onSaveDraft={this.saveDraft}
        initialValues={initialValues}
        enableDraftButton={enableDraftButton}
      />,
      <ComunidadWeizman
        token={token}
        data={data}
        previousPage={this.previousPage}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />,
    ];

    if (data.alumno.nuevoIngreso) {
      titulosPasos = [t("Registro")].concat(titulosPasos);
      componentsPasos = [
        <Registro
          token={token}
          data={data}
          onSubmit={this.nextPage}
          onSaveDraft={this.saveDraft}
          initialValues={initialValues}
          enableDraftButton={enableDraftButton}
        />,
      ].concat(componentsPasos);
    }

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                {titulosPasos.map((paso, index) => (
                  <PasoBoton
                    disabled
                    key={`paso_boton_${paso}`}
                    page={index + 1}
                    label={`${paso}`}
                    onClick={() => {
                      /* this.setPage */
                    }}
                    active={page === index + 1}
                  />
                ))}
              </div>
              <div className="wizard__form-wrapper">
                {componentsPasos[page - 1]}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form.matricula,
});

export default connect(mapStateToProps)(withTranslation("common")(WizardForm));
