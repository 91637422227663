import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import { Prompt, Redirect } from 'react-router';
import DatosGenerales from './components/DatosGenerales';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import {
  destroyForm, loadCatalogo, procesarNuevaMatricula,
} from '../../redux/actions/nuevaMatriculaActions';


// eslint-disable-next-line
const Moment = require("moment");

class NuevaMatricula extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
    app: PropTypes.shape({}).isRequired,
    nuevaMatricula: PropTypes.shape({}).isRequired,
    dirty: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      calledLoad: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroyForm('nuevaMatricula'));
  }

  loadData = () => {
    const { app, dispatch } = this.props;
    const { calledLoad } = this.state;
    if (!calledLoad && app.session) {
      const { nucleo } = app.session.user;
      dispatch(loadCatalogo(app.session.id, nucleo));
      this.setState({
        calledLoad: true,
      });
      this.setState({
        calledLoad: true,
      });
    }
  }

  handleSumbit = (values) => {
    const { dispatch, app } = this.props;

    const nuevoAlumno = {
      nombre: values.nombreAlumno,
      padre: {
        nombre: values.nombrePadre,
        email: values.emailPadre,
      },
      madre: {
        nombre: values.nombreMadre,
        email: values.emailMadre,
      },
      nivel: values.nivel.value,
    };

    dispatch(procesarNuevaMatricula({
      token: app.session.id,
      nuevoAlumno,
    }));
  }

  render() {
    const {
      t, nuevaMatricula, dirty, i18n,
    } = this.props;

    if (nuevaMatricula.matriculaExitosa) {
      return <Redirect to="/nueva-matricula/pendiente-contrato" />;
    }

    return (
      nuevaMatricula.loading
        ? <CircularProgress className="circular-progress" />
        : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{t('Matrícula de')} {t('nuevo alumno')}</h3>
              </Col>
            </Row>
            {
              nuevaMatricula.loading && <CircularProgress className="circular-progress" />
            }
            <DatosGenerales
              data={nuevaMatricula}
              language={i18n.language}
              onSubmit={this.handleSumbit}
            />
            <Prompt
              when={dirty || nuevaMatricula.alumnoDirty}
              message="Aún no ha finalizado el proceso de registro de un nuevo alumno. Si abandona el proceso perderá cualquier cambio no guardado. ¿Desea abandonar de todas formas?"
            />
          </Container>
        )
    );
  }
}

const withSaga = injectSaga({ key: 'nuevaMatricula', saga });

const mapStateToProps = state => ({
  app: state.app,
  nuevaMatricula: state.nuevaMatricula,
  dirty: isDirty('nuevaMatricula')(state),
});

export default withSaga(connect(mapStateToProps)(withTranslation('common')(NuevaMatricula)));
