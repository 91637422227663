import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import AlumnoCargoForm from './components/AlumnoCargoForm';
import { setCargoAttribute } from '../../../redux/actions/cargoCrearActions';

class Alumno extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, currentValue, onCancel, nucleoAlumnos,
    } = this.props;

    const newValues = _.cloneDeep(currentValue);

    const selectedStudent = nucleoAlumnos.find(na => na.id === values.alumnoDeLista.value);

    const newValue = {
      alumno: selectedStudent.id,
      nombreAlumno: selectedStudent.nombreAlumno,
      apellido1: selectedStudent.apellido1,
      apellido2: selectedStudent.apellido2,
      parentesco: values.parentesco.value,
    };

    newValues.push(newValue);

    dispatch(setCargoAttribute({
      attribute: 'alumno',
      value: newValues,
    }));

    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      catalogo,
      language,
      nucleoAlumnos,
      itemId,
      currentValue,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <AlumnoCargoForm
              currentValue={currentValue}
              itemId={itemId}
              nucleoAlumnos={nucleoAlumnos}
              language={language}
              catalogo={catalogo}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

Alumno.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  catalogo: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  nucleoAlumnos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
};

Alumno.defaultProps = {
  itemId: '',
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(Alumno);
