import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'moment/locale/es';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';

// eslint-disable-next-line
const Moment = require("moment");
Moment.locale('es');

class ContratoDayCare extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      t, data,
    } = this.props;

    return (
      <form className="form form--horizontal wizard__form">
        <h3 className="wizard__title">
          {t('Matricula Day Care')} {CICLO_LECTIVO_A_MATRICULAR}
        </h3>
        <h4 className="wizard__date-title">
          {t('Matrícula realizada el ')} {new Moment(data.fecha).format('LLLL')}
        </h4>
        <div style={{ marginTop: '20px' }}>
          <p style={{ marginBottom: '20px' }} className="wizard__contract-text">
            {t('Por este medio confirmo mi interés en el servicio Day care para mi hijo(a)')} <span className="bold-text">{data.nombreAlumno}</span> {t('con la siguiente frecuencia')}:
          </p>
          <Field
            id="frecuenciaDayCare"
            name="frecuenciaDayCare"
            component={renderRadioButtonField}
            label={`${t('MENSUAL')} ₡100.000'`}
            radioValue="mensual"
            validate={value => (value ? undefined : t('Obligatorio'))}
            defaultChecked={data.frecuencia === 'mensual'}
            disabled={data.frecuencia !== 'mensual'}
          />
          <Field
            id="frecuenciaDayCare"
            name="frecuenciaDayCare"
            component={renderRadioButtonField}
            label={`${t('DOS-TRES DIAS POR SEMANA')} ₡50.000'`}
            radioValue="dosTresDias"
            validate={value => (value ? undefined : t('Obligatorio'))}
            defaultChecked={data.frecuencia === 'dosTresDias'}
            disabled={data.frecuencia !== 'dosTresDias'}
          />
          <Field
            id="frecuenciaDayCare"
            name="frecuenciaDayCare"
            component={renderRadioButtonField}
            label={`${t('UN DIA POR SEMANA')} ₡25.000'`}
            radioValue="unDia"
            validate={value => (value ? undefined : t('Obligatorio'))}
            defaultChecked={data.frecuencia === 'unDia'}
            disabled={data.frecuencia !== 'unDia'}
          />
          <p className="wizard__contract-text">{t('Este servicio cuenta con almuerzo, una merienda en la tarde y variadas propuestas pedagógicas acompañados de la supervisión de una docente de Preescolar')}.</p>
        </div>
        {
          data.encargado.map((e, index) => (
            <div style={{ marginTop: '20px', minWidth: '100%' }} key={`firmaPadre_${e.nombre}`}>
              <div style={{ marginBottom: '10px' }}>
                <p className="bold-text">{e.nombre} - {t('Identificación')} {e.identificacion}</p>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Iniciales')}:</span>
                <div className="form__form-group-field">
                  <p className="wizard__contract-signature">{data.inicialesEncargado[index]}</p>
                </div>
              </div>
            </div>
          ))
        }
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Link to="/matricula"><Button color="primary" type="button" className="previous">{t('Volver')}</Button></Link>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(ContratoDayCare));
