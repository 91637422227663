import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import { Prompt, Redirect } from 'react-router';
import WizardForm from './components/WizardForm';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import {
  destroyMatricularForm, loadCargo, loadCatalogo, procesarCargo, setCargoAttribute,
} from '../../redux/actions/cargoCrearActions';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../utils/constants';

const transformarCargo = (esNuevo, cargo) => {
  if (esNuevo) {
    return {};
  }
  const newCargo = _.cloneDeep(cargo);
  return newCargo;
};

// eslint-disable-next-line
const Moment = require("moment");

class Matricular extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    cargoCrear: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({}).isRequired,
    dirty: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      calledLoad: false,
      esNuevo: match.params.id === 'nuevo',
      noHayAlumnos: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroyMatricularForm());
  }

  loadData = () => {
    const { app, dispatch, match } = this.props;
    const { esNuevo, calledLoad } = this.state;
    if (!calledLoad && app.session) {
      const { nucleo } = app.session.user;
      dispatch(loadCatalogo(app.session.id, nucleo));
      if (!esNuevo) {
        dispatch(loadCargo({
          token: app.session.id,
          id: match.params.id,
        }));
      }
      this.setState({
        calledLoad: true,
      });
    }
  }

  handleSumbit = (values) => {
    const { esNuevo } = this.state;
    const { app, cargoCrear, dispatch } = this.props;

    const {
      alumno,
    } = cargoCrear;

    if (alumno.length > 0) {
      const newCargo = _.cloneDeep(cargoCrear.cargo);

      Object.keys(values).forEach((nombre) => {
        newCargo[nombre] = values[nombre];
      });

      const { nucleo } = app.session.user;
      newCargo.nucleo = nucleo;
      newCargo.fecha = new Date();
      newCargo.usuario = app.session.user.id;

      newCargo.habilitado = true;
      newCargo.formulario = `Formulario de Cargo Automático ${CICLO_LECTIVO_A_MATRICULAR} Web 1.0`;

      const payload = {
        isNew: esNuevo,
        token: app.session.id,
        cargo: {
          ...newCargo,
          alumno,
        },
      };

      dispatch(procesarCargo(payload));
    } else {
      this.setState({
        noHayAlumnos: true,
      });
    }
  }

  onSetStudentAttribute = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setCargoAttribute({
      attribute,
      value,
    }));
  }

  render() {
    const {
      esNuevo,
      noHayAlumnos,
    } = this.state;

    const { t, cargoCrear, dirty } = this.props;

    if (cargoCrear.matriculaExitosa) {
      return <Redirect to="/cargo/exito" />;
    }

    return (
      (!esNuevo && !cargoCrear.cargo.id)
        ? <CircularProgress className="circular-progress" />
        : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{esNuevo ? t('Crear Cargo Automático') : t('Modificar Cargo Automático')}</h3>
              </Col>
            </Row>
            {
              cargoCrear.loading && <CircularProgress className="circular-progress" />
            }
            <WizardForm
              noHayAlumnos={noHayAlumnos}
              esNuevo={esNuevo}
              onSetStudentAttribute={this.onSetStudentAttribute}
              initialValues={transformarCargo(esNuevo, cargoCrear.cargo)}
              data={cargoCrear}
              onSubmit={this.handleSumbit}
            />
            <Prompt
              when={dirty || cargoCrear.cargoDirty}
              message="Ha realizado cambios en el formulario. Si abandona el formulario los cambios no se guardarán. ¿Desea abandonar de todas formas?"
            />
          </Container>
        )
    );
  }
}

const withSaga = injectSaga({ key: 'cargoCrear', saga });

const mapStateToProps = state => ({
  app: state.app,
  cargoCrear: state.cargoCrear,
  dirty: isDirty('cargo')(state),
});

export default withSaga(connect(mapStateToProps)(withTranslation('common')(Matricular)));
