import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  finishSessionSuccess,
  FINISH_SESSION,
} from '../../redux/actions/appActions';
import { ROUTE_USUARIO } from '../../utils/constants';
import toAPIPath from '../../utils/locationResolver';

export function* logout(payload) {
  const { api, session } = payload;
  try {
    yield call(api.post, toAPIPath([ROUTE_USUARIO, `logout?access_token=${session.id}`]), session.id);
    localStorage.removeItem('portal_weizman_session');
    yield put(finishSessionSuccess());
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(FINISH_SESSION, logout);
}
