import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import logoPortal from '../../shared/img/logo/logo_light.svg';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import { forgotPassword } from '../../redux/actions/appActions';
import Alert from '../../shared/components/Alert';

class ForgotPassword extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  handleSumbit = (values) => {
    const { dispatch } = this.props;
    dispatch(forgotPassword(values.email));
  }

  render() {
    const { app, t } = this.props;
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <img style={{ marginBottom: '20px' }} alt="Logo Portal Weizman" src={logoPortal} />
            {
              app.error
              && (
                <Alert visible color="danger">
                  <span>{t('Ocurrió un error')}. {t('Intente nuevamente')}. {t('Si el problema persiste, contacte al administrador del sistema')}.</span>
                </Alert>
              )
            }
            {
              app.resetEmailSent
                ? (
                  <Alert visible color="success">
                    <span>{t('Si el correo indicado se encuentra registrado en la plataforma recibirá un mensaje con las instrucciones para cambiar su contraseña')}.</span>
                  </Alert>
                )
                : <ForgotPasswordForm onSubmit={this.handleSumbit} />
            }
            <div className="account__or" />
            <Link
              style={{ marginTop: '20px' }}
              className="btn btn-primary account__btn account__btn--small"
              to="/"
            >
              Volver
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const withSaga = injectSaga({ key: 'forgotPassword', saga });

ForgotPassword.propTypes = {
  t: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});


export default withTranslation('common')(withSaga(connect(mapStateToProps)(ForgotPassword)));
