import React, { PureComponent } from 'react';
import { Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import DatosGenerales from './DatosGenerales';
import Nutricion from './Nutricion';
import Salud from './Salud';
import PasoBoton from './PasoBoton';
import Registro from './Registro';

class WizardForm extends PureComponent {
  static propTypes = {
    token: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({ language: PropTypes.string }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    onSetStudentAttribute: PropTypes.func.isRequired,
    esNuevo: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    puedeActualizar: PropTypes.bool.isRequired,
    app: PropTypes.shape({}).isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  nextPage = (values) => {
    const { onSubmit, puedeActualizar } = this.props;
    this.setState(prevState => ({ page: prevState.page + 1 }));
    if (puedeActualizar) {
      onSubmit(values, {
        isDraft: true,
      });
    }
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  setPage = (page) => {
    this.setState({ page });
  };

  render() {
    const {
      onSubmit, initialValues, t, data, i18n, onSetStudentAttribute, esNuevo, token, isAdmin, puedeActualizar, app,
    } = this.props;
    const { page } = this.state;

    const puedeActualizarDR = (!isAdmin && puedeActualizar) || (isAdmin && puedeActualizar && _.intersection(app.accesos.find(a => a.opcion === 'actualizarDatosRegistro').roles, app.session.user.roles).length > 0);
    const puedeActualizarDG = (!isAdmin && puedeActualizar) || (isAdmin && puedeActualizar && _.intersection(app.accesos.find(a => a.opcion === 'actualizarDatosGenerales').roles, app.session.user.roles).length > 0);
    const puedeActualizarDN = (!isAdmin && puedeActualizar) || (isAdmin && puedeActualizar && _.intersection(app.accesos.find(a => a.opcion === 'actualizarDatosNutricion').roles, app.session.user.roles).length > 0);
    const puedeActualizarDS = (!isAdmin && puedeActualizar) || (isAdmin && puedeActualizar && _.intersection(app.accesos.find(a => a.opcion === 'actualizarDatosSalud').roles, app.session.user.roles).length > 0);
    const puedeVerDR = (!isAdmin && puedeActualizar) || (isAdmin && _.intersection(app.accesos.find(a => a.opcion === 'verDatosRegistro').roles, app.session.user.roles).length > 0);
    const puedeVerDG = (!isAdmin && puedeActualizar) || (isAdmin && _.intersection(app.accesos.find(a => a.opcion === 'verDatosGenerales').roles, app.session.user.roles).length > 0);
    const puedeVerDN = (!isAdmin && puedeActualizar) || (isAdmin && _.intersection(app.accesos.find(a => a.opcion === 'verDatosNutricion').roles, app.session.user.roles).length > 0);
    const puedeVerDS = (!isAdmin && puedeActualizar) || (isAdmin && _.intersection(app.accesos.find(a => a.opcion === 'verDatosSalud').roles, app.session.user.roles).length > 0);

    const titulosPasos = [];
    const componentsPasos = [];

    if (puedeVerDR && data.alumno && data.alumno.nuevoIngreso) {
      titulosPasos.push(t('Registro'));
      componentsPasos.push(<Registro
        puedeActualizarDR={puedeActualizarDR}
        tieneSiguiente={puedeVerDG || puedeVerDN || puedeVerDS}
        esNuevo={esNuevo}
        token={token}
        data={data}
        language={i18n.language}
        onSubmit={this.nextPage}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
      />);
    }

    if (puedeVerDG) {
      titulosPasos.push(t('Alumno'));
      componentsPasos.push(<DatosGenerales
        puedeActualizarDG={puedeActualizarDG}
        tieneAnterior={puedeVerDR}
        tieneSiguiente={puedeVerDN || puedeVerDS}
        esNuevo={esNuevo}
        data={data}
        language={i18n.language}
        previousPage={this.previousPage}
        onSubmit={this.nextPage}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
      />);
    }

    if (puedeVerDN) {
      titulosPasos.push(t('Nutrición'));
      componentsPasos.push(<Nutricion
        puedeActualizarDN={puedeActualizarDN}
        tieneAnterior={puedeVerDR || puedeVerDG}
        tieneSiguiente={puedeVerDS}
        token={token}
        data={data}
        language={i18n.language}
        previousPage={this.previousPage}
        onSubmit={this.nextPage}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
      />);
    }

    if (puedeVerDS) {
      titulosPasos.push(t('Salud'));
      componentsPasos.push(<Salud
        puedeActualizarDS={puedeActualizarDS}
        tieneAnterior={puedeVerDR || puedeVerDG || puedeVerDN}
        data={data}
        language={i18n.language}
        previousPage={this.previousPage}
        onSubmit={onSubmit}
        initialValues={initialValues}
        onSetStudentAttribute={onSetStudentAttribute}
      />);
    }

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                {
                  titulosPasos.map((paso, index) => (
                    <PasoBoton
                      key={`paso_boton_${paso}`}
                      page={index + 1}
                      label={`${paso}`}
                      onClick={this.setPage}
                      active={page === index + 1}
                    />
                  ))
                }
              </div>
              <div className="wizard__form-wrapper">
                {componentsPasos[page - 1]}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withTranslation('common')(WizardForm);
