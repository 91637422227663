import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
// import { Link } from 'react-router-dom';
import { concatenarNombreAlumno } from '../../../utils/stringUtils';

const CargoCard = ({ borrarCargo, t, cargo }) => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{t('Cargo a nombre de')} {cargo.suscrito}</h4>
        </div>
        {
          cargo.alumno.map(a => <p key={a.alumno}><span className="bold-text">{t('Alumno')}</span>: {concatenarNombreAlumno(a)}</p>)
        }
        <p><span className="bold-text">{t('Fecha de Registro')}</span>: {new Date(cargo.fecha).toLocaleDateString('es')}</p>
        <p><span className="bold-text">{t('Estado')}</span>: {cargo.habilitado ? t('Habilitado') : t('Inactivo')}</p>
        <Button
          onClick={() => { borrarCargo(cargo.id); }}
          size="sm"
          style={{
            marginTop: '10px',
          }}
          color="success"
        >
          Eliminar cargo
        </Button>
      </CardBody>
    </Card>
  </Col>
);

CargoCard.propTypes = {
  t: PropTypes.func.isRequired,
  cargo: PropTypes.shape({}).isRequired,
  borrarCargo: PropTypes.func.isRequired,
};

export default withTranslation('common')(CargoCard);
