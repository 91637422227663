import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import { getNucleoData } from '../../../containers/Matricular/saga';
import {
  savePhoneSuccess, SAVE_PHONE, setStudentAttribute,
} from '../../../redux/actions/matricularActions';
import { ROUTE_NUCLEO_TELEFONO } from '../../../utils/constants';
import toAPIPath from '../../../utils/locationResolver';

export function* postPutPhone(payload) {
  const { api, data } = payload;
  let phoneId;
  try {
    if (data.isNew) {
      const newPhone = yield call(api.post, toAPIPath([`${ROUTE_NUCLEO_TELEFONO}?access_token=${data.token}`]), data.phone);
      phoneId = newPhone.id;
    } else {
      yield call(api.put, toAPIPath([ROUTE_NUCLEO_TELEFONO, `${data.itemId}?access_token=${data.token}`]), data.phone);
      phoneId = data.itemId;
    }
    yield call(getNucleoData, {
      api,
      data: {
        token: data.token,
        nucleo: data.phone.nucleo,
      },
    });
    const phones = yield select(state => state.matricular.telefono);
    const newPhones = _.cloneDeep(phones);
    if (newPhones.indexOf(phoneId) === -1) {
      newPhones.push(phoneId);
    }
    yield put(setStudentAttribute({
      attribute: 'telefono',
      value: newPhones,
    }));
    yield put(savePhoneSuccess());
  } catch (err) {
    throw err;
  }
}

export default function* rootSaga() {
  yield takeLatest(SAVE_PHONE, postPutPhone);
}
