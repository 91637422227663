import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import logoPolpoFull from '../../../shared/img/logo/polpo.svg';
import logoPolpoMin from '../../../shared/img/logo/polpo-min.svg';

const Sidebar = ({
  changeMobileSidebarVisibility, sidebarShow, sidebarCollapse, onLogout,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebarShow,
    'sidebar--collapse': sidebarCollapse,
  });

  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            onLogout={onLogout}
            onClick={() => {}}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onLogout={onLogout}
            onClick={changeMobileSidebarVisibility}
          />
        </div>
        <div
          style={{
            width: '100%',
            position: 'fixed',
            bottom: '0px',
            padding: '12px',
            textAlign: 'center',
            transition: 'transform 0.3s, width 0.3s',
          }}
        >
          {
            sidebarCollapse
              ? (
                <a href="https://polpocr.com" rel="noopener noreferrer" target="_blank">
                  <img style={{ height: '20px' }} alt="Logo Polpo" src={logoPolpoMin} />
                </a>
              )
              : (
                <p style={{ fontSize: '12px', color: '#131457' }}>
                  Desarrollado por&nbsp;
                  <a href="https://polpocr.com" rel="noopener noreferrer" target="_blank">
                    <img style={{ height: '15px' }} alt="Logo Polpo" src={logoPolpoFull} />
                  </a>
                </p>
              )
          }
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  sidebarCollapse: PropTypes.bool.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default Sidebar;
