import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';

const Tarjeta = ({ titulo, valor, color }) => (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <div className="dashboard__booking-total-container">
          <h5 className={`dashboard__booking-total-title dashboard__booking-total-title--${color}`}>{valor}</h5>
        </div>
        <h5 className="dashboard__booking-total-description">{titulo}</h5>
      </CardBody>
    </Card>
  </Col>
);

Tarjeta.propTypes = {
  titulo: PropTypes.string.isRequired,
  valor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Tarjeta;
