import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import { finishSession } from '../../redux/actions/appActions';

class Layout extends Component {
  state = {
    sidebarShow: false,
    sidebarCollapse: false,
  };

  changeSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarCollapse: !prevState.sidebarCollapse }));
  };

  changeMobileSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarShow: !prevState.sidebarShow }));
  };

  logout = () => {
    const { dispatch, app } = this.props;
    dispatch(finishSession(app.session));
  }

  render() {
    const { sidebarShow, sidebarCollapse } = this.state;
    const { app } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebarCollapse,
    });

    return (
      <div className={layoutClass}>
        <Topbar
          username={app.session && app.session.user.nombre}
          onLogout={this.logout}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        <Sidebar
          onLogout={this.logout}
          sidebarShow={sidebarShow}
          sidebarCollapse={sidebarCollapse}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

const withSaga = injectSaga({ key: 'layout', saga });

Layout.propTypes = {
  app: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(withRouter(connect(mapStateToProps)(Layout)));
