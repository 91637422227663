export const START_SESSION = 'START_SESSION';
export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS';
export const START_SESSION_ERROR = 'START_SESSION_ERROR';

export const FINISH_SESSION = 'FINISH_SESSION';
export const FINISH_SESSION_SUCCESS = 'FINISH_SESSION_SUCCESS';
export const FINISH_SESSION_ERROR = 'FINISH_SESSION_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const EXPIRE_SESSION = 'EXPIRE_SESSION';

export const LOAD_ACCESOS = 'LOAD_ACCESOS';
export const LOAD_ACCESOS_SUCCESS = 'LOAD_ACCESOS_SUCCESS';
export const LOAD_ACCESOS_ERROR = 'LOAD_ACCESOS_ERROR';

export function startSession(credentials) {
  return {
    type: START_SESSION,
    apiCall: true,
    credentials,
  };
}

export function startSessionSuccess(session) {
  return {
    type: START_SESSION_SUCCESS,
    session,
  };
}

export function startSessionError(error) {
  return {
    type: START_SESSION_ERROR,
    error,
  };
}

export function loadAccesos(token) {
  return {
    type: LOAD_ACCESOS,
    token,
    apiCall: true,
  };
}

export function loadAccesosSuccess(accesos) {
  return {
    type: LOAD_ACCESOS_SUCCESS,
    accesos,
  };
}

export function loadAccesosError(error) {
  return {
    type: LOAD_ACCESOS_ERROR,
    error,
  };
}

export function finishSession(session) {
  return {
    type: FINISH_SESSION,
    session,
    apiCall: true,
  };
}

export function finishSessionSuccess() {
  return {
    type: FINISH_SESSION_SUCCESS,
  };
}

export function finishSessionError(error) {
  return {
    type: FINISH_SESSION_ERROR,
    error,
  };
}

export function changePassword(data) {
  return {
    type: CHANGE_PASSWORD,
    data,
    apiCall: true,
  };
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}

export function forgotPassword(email) {
  return {
    type: FORGOT_PASSWORD,
    email,
    apiCall: true,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
}

export function forgotPasswordError(error) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error,
  };
}

export function expireSession() {
  return {
    type: EXPIRE_SESSION,
  };
}
