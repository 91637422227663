import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, formValueSelector, reduxForm, change, submit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';
import renderSimpleInput from '../../form/Simple';

class DoctorForm extends PureComponent {
  componentDidMount() {
    /* eslint-disable-next-line no-shadow */
    const { change, itemId } = this.props;
    if (itemId) {
      this.handleMedicoChange(itemId);
    } else {
      change('medicoCabecera', null);
    }
  }

  handleMedicoChange = (id) => {
    /* eslint-disable-next-line no-shadow */
    const { change, nucleoMedicos } = this.props;
    if (id !== 'new') {
      const nucleoMedico = nucleoMedicos.find(n => n.id === id);
      change('medicoCabecera', {
        value: nucleoMedico.id,
        label: nucleoMedico.medico,
      });
      change('medico', nucleoMedico.medico);
      change('telefonoOficina', nucleoMedico.telefonoOficina);
      change('telefonoCelular', nucleoMedico.telefonoCelular);
      change('telefonoOtro', nucleoMedico.telefonoOtro);
    } else {
      change('medico', null);
      change('telefonoOficina', null);
      change('telefonoCelular', null);
      change('telefonoOtro', null);
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, formValues, onCancel, nucleoMedicos, itemId,
    } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        {
          !itemId
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Seleccione el médico a vincular')}</span>
              <div className="form__form-group-field">
                <Field
                  name="medicoCabecera"
                  id="medicoCabecera"
                  component={renderSelectField}
                  options={nucleoMedicos.map(nucleoMedico => (
                    {
                      label: nucleoMedico.medico,
                      value: nucleoMedico.id,
                    }
                  )).concat([{
                    label: t('+ Agregar Nuevo'),
                    value: 'new',
                  }])
                  }
                  onChange={(value) => { this.handleMedicoChange(value.value); }}
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          formValues && formValues.medicoCabecera
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Nombre del Médico')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="medico"
                    id="medico"
                    component={renderSimpleInput}
                    type="text"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono Oficina')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefonoOficina"
                    id="telefonoOficina"
                    component={renderSimpleInput}
                    type="phone"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono Celular')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefonoCelular"
                    id="telefonoCelular"
                    component={renderSimpleInput}
                    type="phone"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Teléfono Otro')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="telefonoOtro"
                    id="telefonoOtro"
                    component={renderSimpleInput}
                    type="phone"
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('doctor'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

DoctorForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  nucleoMedicos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
};

DoctorForm.defaultProps = {
  formValues: {
    medicoCabecera: '',
    telefonoOficina: '',
  },
  itemId: '',
};

const selector = formValueSelector('doctor');

function mapStateToProps(state) {
  const formValues = selector(state,
    'medicoCabecera',
    'telefonoOficina');
  return { formValues };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'doctor',
})(withTranslation('common')(DoctorForm)));
