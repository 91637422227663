import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import EmergencyContactForm from './components/EmergencyContactForm';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import { setStudentAttribute } from '../../../redux/actions/matricularActions';

class EmergencyContact extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, onCancel,
    } = this.props;

    dispatch(setStudentAttribute({
      attribute: 'contactoEmergencia',
      value: values.contactoEmergencia.value,
    }));
    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      encargados,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <EmergencyContactForm
              encargados={encargados}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

EmergencyContact.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  encargados: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const withSaga = injectSaga({ key: 'emergencyContact', saga });

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(connect(mapStateToProps)(EmergencyContact));
