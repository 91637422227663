/* eslint-disable no-param-reassign, no-return-assign, consistent-return */

import produce from 'immer';

import {
  LOAD_MATRICULA,
  LOAD_MATRICULA_SUCCESS,
  LOAD_MATRICULA_ERROR,
} from '../actions/matriculaVerActions';

const initialState = {
  dataLoaded: false,
  matricula: {
    usuario: '',
    alumno: '',
    nucleo: '',
    cicloLectivo: '',
    nivel: '',
    nombreAlumno: '',
    encargado: [],
    inicialesEncargado: [],
    fecha: '',
    direccionContractual: '',
    formulario: '',
    id: '',
  },
  error: false,
  loading: false,
};

export default (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_MATRICULA:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_MATRICULA_SUCCESS:
      draft.matricula = action.matricula;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_MATRICULA_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return state;
  }
});
