import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  CircularProgress,
  InputAdornment,
  TablePagination,
  TextField,
} from "@material-ui/core";
import numeral from "numeral";
import _ from "lodash";
import StudentCard from "./components/TarjetaAlumno";
import injectSaga from "../../utils/injectSaga";
import saga from "./saga";
import {
  getAlumnosUsuario,
  switchPaymentStatus,
  switchContractStatus,
  switchStudentStatus,
} from "../../redux/actions/matriculaActions";
import Alert from "../../shared/components/Alert";

function iff(condition, then, otherwise) {
  return condition ? then : otherwise;
}

class Matricula extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      calledLoad: false,
      isAdmin: false,
      page: 0,
      pageSize: 5,
      nameFilter: "",
      nameLetter: "",
      puedeActualizar: false,
      puedeCambiarEstadoPago: false,
      puedeCambiarEstadoContrato: false,
      puedeCambiarEstadoMatricula: false,
      loading: false,
      loadingSuccessContractChange: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  loadData = () => {
    const { app, dispatch } = this.props;
    const { calledLoad, page, pageSize, nameFilter, nameLetter } = this.state;
    if (!calledLoad && app.session) {
      const isAdmin =
        app.session.user.roles.length > 1 ||
        app.session.user.roles.indexOf("Encargado") === -1;
      let puedeActualizar, puedeCambiarEstadoPago, puedeCambiarEstadoContrato, puedeCambiarEstadoMatricula;
      if (isAdmin) {
        puedeActualizar =
          _.intersection(
            app.accesos.find((a) => a.opcion === "actualizarDatos").roles,
            app.session.user.roles
          ).length > 0;
        puedeCambiarEstadoPago =
          _.intersection(
            app.accesos.find((a) => a.opcion === "cambiar-estado-pago").roles,
            app.session.user.roles
          ).length > 0;
        puedeCambiarEstadoContrato =
          _.intersection(
            app.accesos.find((a) => a.opcion === "cambiar-estado-contrato")
              .roles,
            app.session.user.roles
          ).length > 0;
        puedeCambiarEstadoMatricula =
          _.intersection(
            app.accesos.find((a) => a.opcion === "cambiar-estado-matricula")
              .roles,
            app.session.user.roles
          ).length > 0;
        dispatch(
          getAlumnosUsuario({
            id: app.session.user.id,
            token: app.session.id,
            page,
            pageSize,
            isAdmin,
            nameFilter,
            nameLetter,
          })
        );
      } else {
        puedeActualizar = true;
        dispatch(
          getAlumnosUsuario({
            id: app.session.user.id,
            nucleo: app.session.user.nucleo,
            token: app.session.id,
            page,
            pageSize,
            isAdmin,
            nameFilter,
            nameLetter,
          })
        );
      }
      this.setState({
        calledLoad: true,
        isAdmin,
        puedeActualizar,
        puedeCambiarEstadoPago,
        puedeCambiarEstadoContrato,
        puedeCambiarEstadoMatricula,
      });
    }
  };

  onChangePage = (page) => {
    const { app, dispatch } = this.props;
    const { pageSize, isAdmin, nameFilter, nameLetter } = this.state;
    dispatch(
      getAlumnosUsuario({
        id: app.session.user.id,
        token: app.session.id,
        page,
        pageSize,
        isAdmin,
        nameFilter,
        nameLetter,
      })
    );
    this.setState({
      page,
    });
  };

  onChangeRowsPerPage = (pageSize) => {
    const { app, dispatch } = this.props;
    const { page, isAdmin, nameFilter, nameLetter } = this.state;
    dispatch(
      getAlumnosUsuario({
        id: app.session.user.id,
        token: app.session.id,
        page,
        pageSize,
        isAdmin,
        nameFilter,
        nameLetter,
      })
    );
    this.setState({
      pageSize,
    });
  };

  onChangeName = (nameFilter) => {
    this.setState({});
    const { app, dispatch } = this.props;
    const { isAdmin, pageSize, nameLetter } = this.state;
    dispatch(
      getAlumnosUsuario({
        id: app.session.user.id,
        token: app.session.id,
        page: 0,
        pageSize,
        isAdmin,
        nameFilter,
        nameLetter,
      })
    );
    this.setState({
      page: 0,
      nameFilter,
      nameLetter: "",
    });
  };

  onChangeNameLetter = (nameLetterValue) => {
    this.setState({});
    const { app, dispatch } = this.props;
    const { isAdmin, pageSize, nameFilter, nameLetter } = this.state;
    const newNameLetter = nameLetterValue !== nameLetter ? nameLetterValue : "";
    dispatch(
      getAlumnosUsuario({
        id: app.session.user.id,
        token: app.session.id,
        page: 0,
        pageSize,
        isAdmin,
        nameFilter,
        nameLetter: newNameLetter,
      })
    );
    this.setState({
      page: 0,
      nameLetter: newNameLetter,
      nameFilter: "",
    });
  };

  onChangePaymentStatus = (id, pendientePago) => {
    const { app, dispatch } = this.props;
    dispatch(switchPaymentStatus(id, pendientePago, app.session.id));
  };

  onChangeContractStatus = (alumno) => {
    const { app, dispatch } = this.props;
    this.setState({
      loadingSuccessContractChange: true,
    });
    dispatch(switchContractStatus(alumno, app.session.id));
  };

  onToggleStudentStatus = (id, activo) => {
    const { app, dispatch } = this.props;
    dispatch(switchStudentStatus(id, activo, app.session.id));
  }

  render() {
    const { t, matricula } = this.props;
    const {
      page,
      pageSize,
      isAdmin,
      nameFilter,
      puedeActualizar,
      nameLetter,
      puedeCambiarEstadoPago,
      puedeCambiarEstadoContrato,
      puedeCambiarEstadoMatricula,
      loading,
      loadingSuccessContractChange,
    } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={6}>
            <h3 style={{ display: "inline" }} className="page-title">
              {t("Alumnos Registrados")}
            </h3>
          </Col>
          {isAdmin && (
            <Col md={6}>
              <Link to="/nueva-matricula">
                <div
                  style={{
                    backgroundColor: "#df6c35",
                    color: "#fff",
                    borderRadius: "5px",
                    textAlign: "center",
                    padding: "10px",
                    marginBottom: "20px",
                  }}
                >
                  {t("+ Agregar Alumno")}
                </div>
              </Link>
            </Col>
          )}
        </Row>
        {isAdmin && (
          <Row>
            <Col xs={12}>
              <TextField
                style={{
                  marginBottom: "20px",
                }}
                id="filtro"
                name="filtro"
                variant="filled"
                fullWidth
                placeholder={`${t("Filtrar por nombre o apellidos")}...`}
                InputProps={{
                  style: {
                    backgroundColor: "#fff",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="lnr lnr-magnifier" />
                    </InputAdornment>
                  ),
                }}
                value={nameFilter}
                onChange={(evt) => {
                  this.onChangeName(evt.target.value);
                }}
              />
            </Col>
            <Col style={{ marginBottom: "20px" }} xs={12}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, 40px)",
                  rowGap: "10px",
                }}
              >
                {"abcdefghijklmnopqrstuvwxyz".split("").map((letter) => (
                  <div key={`letter_${letter}`}>
                    <button
                      style={{
                        backgroundColor:
                          letter === nameLetter ? "#df6c35" : "#131457",
                        border: "#131457",
                        borderRadius: "5px",
                        height: "30px",
                        width: "30px",
                        textAlign: "center",
                        color: "#fff",
                      }}
                      type="button"
                      onClick={() => {
                        this.onChangeNameLetter(letter);
                      }}
                    >
                      {letter.toUpperCase()}
                    </button>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        )}
        {matricula.loading || loading ? (
          <CircularProgress className="circular-progress" />
        ) : (
          iff(
            matricula.alumnos.length > 0,
            <>
              {loadingSuccessContractChange && (
                <CircularProgress className="circular-progress" />
              )}
              {matricula.alumnos.map((alumno) => (
                <StudentCard
                  puedeActualizar={puedeActualizar}
                  isAdmin={isAdmin}
                  key={`student_${alumno.id}`}
                  alumno={alumno}
                  onChangePaymentStatus={this.onChangePaymentStatus}
                  onChangeContractStatus={this.onChangeContractStatus}
                  onToggleStudentStatus={this.onToggleStudentStatus}
                  puedeCambiarEstadoMatricula={puedeCambiarEstadoMatricula}
                  puedeCambiarEstadoPago={puedeCambiarEstadoPago}
                  puedeCambiarEstadoContrato={puedeCambiarEstadoContrato}
                  loadingSuccessContractChange={loadingSuccessContractChange}
                />
              ))}
            </>,
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Alert visible color="info">
                      <span>
                        {t("No se encontraron alumnos registrados")}.{" "}
                        {t(
                          "Si usted tiene alumnos matriculados en el Instituto Dr"
                        )}
                        .{" "}
                        {t(
                          "Jaim Weizman y no los visualiza por favor comuníquese con la administración para solucionar el problema"
                        )}
                        .
                      </span>
                    </Alert>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )
        )}
        <Row>
          <Col xs={12}>
            <TablePagination
              style={{ marginBottom: "90px" }}
              component="div"
              className="material-table__pagination"
              count={matricula.total}
              rowsPerPage={pageSize}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("de")} ${numeral(count).format("0,0")}`
              }
              backIconButtonProps={{ "aria-label": "Previous Page" }}
              nextIconButtonProps={{ "aria-label": "Next Page" }}
              labelRowsPerPage={`${t("Estudiantes por página")}:`}
              onChangePage={(evt, newPage) => {
                this.onChangePage(newPage);
              }}
              onChangeRowsPerPage={(evt) => {
                this.onChangeRowsPerPage(evt.target.value);
              }}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Matricula.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  matricula: PropTypes.shape({}).isRequired,
  app: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  matricula: state.matricula,
  app: state.app,
});

const withSaga = injectSaga({ key: "matricula", saga });

export default withSaga(
  withTranslation("common")(connect(mapStateToProps)(Matricula))
);
