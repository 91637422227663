import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Button, Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import CargoCard from './components/CargoCard';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import Alert from '../../shared/components/Alert';
import { getCargosUsuario, borrarCargo } from '../../redux/actions/cargoActions';

function iff(condition, then, otherwise) {
  return condition ? then : otherwise;
}

class Matricula extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      calledLoad: false,
    };
  }

  componentDidMount() {
    const { app, dispatch } = this.props;
    const { calledLoad } = this.state;
    if (!calledLoad && app.session) {
      dispatch(getCargosUsuario({
        id: app.session.user.id,
        nucleo: app.session.user.nucleo,
        token: app.session.id,
      }));
      this.setState({
        calledLoad: true,
      });
    }
  }

  componentDidUpdate() {
    const { app, dispatch } = this.props;
    const { calledLoad } = this.state;
    /* eslint-disable react/no-did-update-set-state */
    if (!calledLoad && app.session) {
      this.setState({
        calledLoad: true,
      });
      dispatch(getCargosUsuario({
        id: app.session.user.id,
        nucleo: app.session.user.nucleo,
        token: app.session.id,
      }));
    }
  }

  render() {
    const {
      app, t, cargo, dispatch,
    } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 style={{ display: 'inline' }} className="page-title">{t('Cargos Registrados')}</h3>
            <Link to="/cargo/nuevo/modificar">
              <Button
                size="sm"
                style={{
                  display: 'inline',
                  marginLeft: '10px',
                  position: 'relative',
                  top: '5px',
                }}
                color="primary"
              >
                + {t('Nuevo')}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          {
            cargo.loading
              ? <CircularProgress className="circular-progress" />
              : iff(
                cargo.cargos.length > 0,
                cargo.cargos.map(c => <CargoCard borrarCargo={(id) => { dispatch(borrarCargo({ id: app.session.user.id, nucleo: app.session.user.nucleo, token: app.session.id }, id)); }} key={`student_${c.id}`} cargo={c} />),
                (
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <Alert visible color="info">
                          <span>
                            {t('No se encontraron cargos registrados')}. {t('Usted puede crear un nuevo cargo automático presionando el botón "+ Nuevo"')}.
                          </span>
                        </Alert>
                      </CardBody>
                    </Card>
                  </Col>
                ),
              )
          }
        </Row>
      </Container>
    );
  }
}

Matricula.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  cargo: PropTypes.shape({}).isRequired,
  app: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  cargo: state.cargo,
  app: state.app,
});

const withSaga = injectSaga({ key: 'cargo', saga });

export default withSaga(withTranslation('common')(connect(mapStateToProps)(Matricula)));
