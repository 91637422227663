import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'moment/locale/es';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { CICLO_LECTIVO_A_MATRICULAR } from '../../../utils/constants';

// eslint-disable-next-line
const Moment = require("moment");
Moment.locale('es');

class ComunidadWeizman extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      t, data,
    } = this.props;

    return (
      <form className="form form--horizontal wizard__form">
        <h3 className="wizard__title">
          {t('Constancia de conocimiento y aceptación del Manual de la Comunidad Weizman')} {CICLO_LECTIVO_A_MATRICULAR}
        </h3>
        <h4 className="wizard__date-title">
          {t('Matrícula realizada el ')} {new Moment(data.fecha).format('LLLL')}
        </h4>
        <p className="wizard__contract-text">{t('Nosotros,')}&nbsp;<span className="bold-text">{_.map(data.encargado, 'nombre').join(t(' y '))}</span></p>
        {/* eslint-disable-next-line max-len */}
        <p className="wizard__contract-text">{t('Hacemos constar por medio de nuestra(s) inicial(es) al pie, que hemos leído, entendido y aceptado, la Filosofía Institucional, los Códigos, las Políticas y los Reglamentos del Instituto Dr')}. {t('Jaim Weizman para el curso lectivo')} {CICLO_LECTIVO_A_MATRICULAR} {t('como se publicó en el')} <a style={{ fontWeight: 'bold', color: '#007bff' }} rel="noopener noreferrer" target="_blank" href="https://portal-weizman.s3.amazonaws.com/manualWeizman2023-2024.pdf">&quot;{t('Manual de la comunidad Weizman')} {CICLO_LECTIVO_A_MATRICULAR}&quot;</a>. {t('Es responsabilidad del padre de familia estar enterado y mantenerse actualizado en dicho documento, información que empieza a regir en este momento')}.</p>
        <p className="wizard__contract-text">{t('Somos responsables del estudiante')} <span className="bold-text">{data.nombreAlumno}</span> {t('que estará realizando estudios en este Centro Educativo en el año')} {CICLO_LECTIVO_A_MATRICULAR}.</p>
        <p className="wizard__contract-text">{t('Según la normativa aplicable y los Reglamentos en cuestión, al momento de hacer efectivo el pago de la Matrícula, se opera entre los padres de familia y/o encargados legales de los estudiantes y la Institución Dr')}. {t('Jaim Weizman, un contrato de servicios educativos por todo el año')} {CICLO_LECTIVO_A_MATRICULAR} {t('desde julio 2023 hasta junio 2024')}.</p>
        <p className="wizard__contract-text">{t('Señalamos como domicilio contractual')}: <span className="bold-text">{data.direccionContractual}</span></p>
        {
          data.encargado.map((e, index) => (
            <div style={{ marginTop: '20px', minWidth: '100%' }} key={`firmaPadre_${e.nombre}`}>
              <div style={{ marginBottom: '10px' }}>
                <p className="bold-text">{e.nombre} - {t('Identificación')} {e.identificacion}</p>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Iniciales')}:</span>
                <div className="form__form-group-field">
                  <p className="wizard__contract-signature">{data.inicialesEncargado[index]}</p>
                </div>
              </div>
            </div>
          ))
        }
        <p className="wizard__contract-text">{t('Por parte de la Dirección General, hago constar que la Institución respetará los Códigos, Políticas y Reglamentos publicados en el "Manual de la Comunidad Weizman')} {CICLO_LECTIVO_A_MATRICULAR}&quot;.</p>
        <div style={{ marginTop: '40px', width: '100%' }}>
          <p className="bold-text" style={{ marginBottom: '0px', float: 'right' }}>Katia Alfaro</p>
        </div>
        <div style={{ width: '100%' }}>
          <p style={{ float: 'right' }}>{t('Directora General')}</p>
        </div>
        <ButtonToolbar className="form__button-toolbar--center wizard__toolbar">
          <Link to="/matricula"><Button color="primary" type="button" className="previous">{t('Volver')}</Button></Link>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'matricula', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(ComunidadWeizman));
