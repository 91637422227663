import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import PhoneForm from './components/PhoneForm';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import { savePhone } from '../../../redux/actions/matricularActions';

class Phone extends PureComponent {
  handleSumbit = (values) => {
    const {
      dispatch, nucleoId, app, onCancel,
    } = this.props;

    const newPhone = _.cloneDeep(values);
    newPhone.nucleo = nucleoId;

    const atributosConListado = [
      'tipoTelefono',
    ];

    Object.keys(values).forEach((nombre) => {
      if (atributosConListado.indexOf(nombre) > -1) {
        newPhone[nombre] = values[nombre].value;
      } else {
        newPhone[nombre] = values[nombre];
      }
    });

    const isNew = newPhone.telefonoDeLista.value === 'new';
    let phoneId = null;
    if (!isNew) {
      phoneId = newPhone.telefonoDeLista.value;
    }
    delete newPhone.id;
    delete newPhone.telefonoDeLista;
    dispatch(savePhone({
      token: app.session.id,
      isNew,
      itemId: phoneId,
      phone: newPhone,
    }));
    onCancel();
  }

  render() {
    const {
      app,
      onCancel,
      tipoTelefono,
      language,
      nucleoTelefonos,
      itemId,
    } = this.props;

    return (
      <div className="modal-form">
        { app.loading && <CircularProgress className="circular-progress" /> }
        <div className="modal-form__wrapper">
          <div className="modal-form__card">
            <PhoneForm
              itemId={itemId}
              nucleoTelefonos={nucleoTelefonos}
              language={language}
              tipoTelefono={tipoTelefono}
              onSubmit={this.handleSumbit}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

Phone.propTypes = {
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  tipoTelefono: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  nucleoId: PropTypes.string.isRequired,
  nucleoTelefonos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
};

Phone.defaultProps = {
  itemId: '',
};

const withSaga = injectSaga({ key: 'phone', saga });

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(connect(mapStateToProps)(Phone));
