import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import SetNewPasswordForm from './components/SetNewPasswordForm';
import logoPortal from '../../shared/img/logo/logo_light.svg';
import { changePassword } from '../../redux/actions/appActions';
import Alert from '../../shared/components/Alert';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';

class SetNewPassword extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      passwordsAreTheSame: true,
      token: qs.parse(location.search, { ignoreQueryPrefix: true }).access_token
        ? qs.parse(location.search, { ignoreQueryPrefix: true }).access_token
        : false,
    };
  }

  handleSumbit = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      this.setState({
        passwordsAreTheSame: false,
      });
    } else {
      const { dispatch } = this.props;
      const { token } = this.state;
      dispatch(changePassword({
        token,
        newPassword: values.newPassword,
      }));
      this.setState({
        passwordsAreTheSame: true,
      });
    }
  }

  render() {
    const { t, app } = this.props;
    const { token, passwordsAreTheSame } = this.state;
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            {
              !token
              && (
                <Alert visible color="danger">
                  <span>{t('Verifique que la dirección del navegador digitada corresponde a la dirección del link del correo')}. {t('Si no ha recibido un correo para el cambio de contraseña, puede presionar la opción de "Olvidó su contraseña" en la pantalla de inicio')}</span>
                </Alert>
              )
            }
            {
              !passwordsAreTheSame
              && (
                <Alert visible color="danger">
                  <span>{t('Las contraseñas no coinciden')}.</span>
                </Alert>
              )
            }
            <img style={{ marginBottom: '20px' }} alt="Logo Portal Weizman" src={logoPortal} />
            {
              app.passwordChangeSuccess
                ? (
                  <Alert visible color="success">
                    <span>{t('La nueva contraseña se ha registrado de manera exitosa')}.</span>
                  </Alert>
                )
                : (
                  <SetNewPasswordForm
                    onSubmit={this.handleSumbit}
                  />
                )
            }
            <div className="account__or" />
            <Link
              style={{ marginTop: '20px' }}
              className="btn btn-primary account__btn account__btn--small"
              to="/"
            >
              {t('Volver a Inicio')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const withSaga = injectSaga({ key: 'setPassword', saga });

const mapStateToProps = state => ({
  app: state.app,
});

export default withSaga(withRouter(connect(mapStateToProps)(withTranslation('common')(SetNewPassword))));
