import React, { PureComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TablaNiveles from './components/TablaNiveles';
import { loadEstadisticas } from '../../redux/actions/reporteActions';
import saga from './saga';
import injectSaga from '../../utils/injectSaga';
import TablaProgramas from './components/TablaProgramas';
import Tarjeta from './components/Tarjeta';
import { CICLO_LECTIVO_A_MATRICULAR_SOLO_ANIO } from '../../utils/constants';

class ReporteEstadisticas extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    app: PropTypes.shape({}).isRequired,
    reporte: PropTypes.shape({}).isRequired,
  };

  componentDidMount() {
    const { dispatch, app } = this.props;
    dispatch(loadEstadisticas(app.session.id, CICLO_LECTIVO_A_MATRICULAR_SOLO_ANIO));
  }

  render() {
    const {
      t, app, reporte,
    } = this.props;

    return (
      (!app.session || reporte.loading || Object.keys(reporte.stats).length === 0)
        ? <CircularProgress className="circular-progress" />
        : (
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <h3 style={{ display: 'inline' }} className="page-title">{t('Estadísticas Generales de Matrícula')}</h3>
              </Col>
            </Row>
            <Row>
              <Tarjeta valor={reporte.stats.instituto.cantidad} titulo={t('Total de matrículas')} />
              <Tarjeta valor={`${reporte.stats.instituto.cantidadHombres} (${reporte.stats.instituto.cantidadHombresPorcentaje}%)`} titulo={t('Total de hombres')} />
              <Tarjeta valor={`${reporte.stats.instituto.cantidadMujeres} (${reporte.stats.instituto.cantidadMujeresPorcentaje}%)`} titulo={t('Total de mujeres')} />
            </Row>
            <Row>
              <TablaProgramas estadisticas={reporte.stats} />
            </Row>
            <Row>
              <TablaNiveles estadisticas={reporte.stats} />
            </Row>
          </Container>
        )
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  reporte: state.reporte,
});

const withSaga = injectSaga({ key: 'estadisticas', saga });

export default withSaga(connect(mapStateToProps)(withTranslation('common')(ReporteEstadisticas)));
